import axios from 'axios';
import { apiBaseUrl } from '../../util/api';
// import { decryptData, encryptData } from '../../util/encryptHelper';
import { storableError } from '../../util/errors';
// import { stopExecutionUntillCurrentUserFound } from '../../util/user';

export const NEW_ARRIVAL_LISTING_REQUEST = 'app/landingpage/NEW_ARRIVAL_LISTING_REQUEST';
export const NEW_ARRIVAL_LISTING_SUCCESS = 'app/landingpage/NEW_ARRIVAL_LISTING_SUCCESS';
export const NEW_ARRIVAL_LISTING_ERROR = 'app/landingpage/NEW_ARRIVAL_LISTING_ERROR';

export const FEATURED_LISTING_REQUEST = 'app/landingpage/FEATURED_LISTING_REQUEST';
export const FEATURED_LISTING_SUCCESS = 'app/landingpage/FEATURED_LISTING_SUCCESS';
export const FEATURED_LISTING_ERROR = 'app/landingpage/FEATURED_LISTING_ERROR';

const RESULT_PAGE_SIZE = 12;
const FEATURED_LISTING_SIZE = 5;

const featuredListingsParams = {
  page: 1,
  include: ['images'],
  'fields.listing': ['title', 'id', 'publicData', 'price', 'metadata'],
  'fields.image': ['variants.default', 'variants.listing-card'],
  'limit.images': 2,
};

//========================reducer=========================//

const initialState = {
  newArrivalListingLoading: false,
  newArrivalListings: [],
  newArrivalListingLoadErr: null,
  featuredListingLoading: false,
  featuredListings: [],
  featuredListingLoadErr: null,
};

export default function reducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case NEW_ARRIVAL_LISTING_REQUEST:
      return {
        ...state,
        newArrivalListingLoading: true,
        newArrivalListings: [],
        newArrivalListingLoadErr: null,
      };
    case NEW_ARRIVAL_LISTING_SUCCESS:
      return {
        ...state,
        newArrivalListingLoading: false,
        newArrivalListings: payload,
      };
    case NEW_ARRIVAL_LISTING_ERROR:
      return {
        ...state,
        newArrivalListingLoading: false,
        newArrivalListingLoadErr: payload,
      };

    case FEATURED_LISTING_REQUEST:
      return {
        ...state,
        featuredListingLoading: true,
        featuredListings: [],
        featuredListingLoadErr: null,
      };
    case FEATURED_LISTING_SUCCESS:
      return {
        ...state,
        featuredListingLoading: false,
        featuredListings: payload,
      };
    case FEATURED_LISTING_ERROR:
      return {
        ...state,
        featuredListingLoading: false,
        featuredListingLoadErr: payload,
      };

    default:
      return state;
  }
}

//======================action creators=======================//

const newArrivalListingsRequest = () => ({ type: NEW_ARRIVAL_LISTING_REQUEST });
const newArrivalListingsSuccess = (payload) => ({ type: NEW_ARRIVAL_LISTING_SUCCESS, payload });
const newArrivalListingsError = (payload) => ({ type: NEW_ARRIVAL_LISTING_ERROR, payload });

const featuredListingsRequest = () => ({ type: FEATURED_LISTING_REQUEST });
const featuredListingsSuccess = (payload) => ({ type: FEATURED_LISTING_SUCCESS, payload });
const featuredListingsError = (payload) => ({ type: FEATURED_LISTING_ERROR, payload });

export const loadNewArrivalListings = () => async (dispatch, getState, sdk) => {
  try {
    dispatch(newArrivalListingsRequest());
    // await stopExecutionUntillCurrentUserFound(getState);
    const { isAuthenticated, authInfoLoaded } = getState().Auth;
    const { currentUser } = getState().user;
    let wishlists = [];

    const params = {
      page: 1,
      perPage: 10,
      minStock: 1,
      pub_isChatWithAdminListing: false,

      pub_isQuoteType: false,
      meta_isActive: true,
      include: ['author', 'images'],
      'fields.listing': ['title', 'geolocation', 'price', 'state', 'publicData', 'metadata'],
      'fields.user': ['profile.displayName', 'profile.abbreviatedName'],
      'fields.image': ['variants.default', 'variants.listing-card'],
      'limit.images': 2,
    };

    const response = await sdk.listings.query(params);
    const resData = response.data.data;
    const resIncluded = response.data.included;
    const url = `${apiBaseUrl()}/api/wishlist/collection/itemstatus`;

    // if (isAuthenticated && authInfoLoaded && currentUser !== null && !!currentUser.id.uuid) {
    //   const listingIds = response.data.data.map((listing) => listing.id.uuid);
    //   const { key: id, encryptedData } = encryptData(
    //     currentUser.id.uuid,
    //     JSON.stringify({ userId: currentUser.id.uuid, listingIds })
    //   );
    //   const axiosResp = await axios.post(url, { id, data: encryptedData });
    //   wishlists = JSON.parse(decryptData(currentUser.id.uuid, axiosResp.data.data));
    // }
    const newData = resData.map((resp) => {
      const author = resIncluded.find((m) => m.id.uuid === resp.relationships.author.data.id.uuid);
      const imageUUIDs = new Set(resp.relationships.images.data.map((d) => d.id.uuid));
      const images = resIncluded.filter((m) => imageUUIDs.has(m.id.uuid));

      // const images = resIncluded.filter((m) =>
      //   resp.relationships.images.data.map((d) => d.id.uuid).includes(m.id.uuid)
      // );
      // (m) => m.id.uuid === resp.relationships.images.data[0].id.uuid
      // console.log(1333, images);

      const { relationships, ...rest } = resp;
      return {
        ...rest,
        attributes: {
          ...rest.attributes,
          publicData: {
            ...rest.attributes.publicData,
            // isAddedToWishlist: wishlists.includes(rest.id.uuid),
          },
        },
        author,
        images,
      };
    });
    dispatch(newArrivalListingsSuccess(newData));
  } catch (err) {
    console.error(err);
    dispatch(newArrivalListingsError(storableError(err)));
  }
};

export const loadFeaturedListings = () => async (dispatch, getState, sdk) => {
  try {
    dispatch(featuredListingsRequest());
    const listingQueryParams = {
      ...featuredListingsParams,
      perPage: 10,
      pub_isQuoteType: false,
      pub_isChatWithAdminListing: false,

      meta_isVerified: true,
      meta_isActive: true,
      include: ['author', 'images'],
      'fields.listing': ['title', 'geolocation', 'price', 'state', 'publicData', 'metadata'],
      'fields.user': ['profile.displayName', 'profile.abbreviatedName'],
      'fields.image': ['variants.default', 'variants.listing-card'],
      'limit.images': 2,
      // minStock: 1,
      //   sort: 'meta_featureOrder',
    };
    const resp = await sdk.listings.query(listingQueryParams);
    const { data, included = [] } = resp.data;
    const resIncluded = resp.data.included;

    const newData = data.map((resp) => {
      // console.log(787, resp, resIncluded);
      const author = resIncluded.find((m) => m.id.uuid === resp.relationships.author.data.id.uuid);

      const imageUUIDs = new Set(resp.relationships.images.data.map((d) => d.id.uuid));
      const images = included.filter((m) => imageUUIDs.has(m.id.uuid));
      // const images = included.filter(
      //   (m) => m.id.uuid === resp.relationships.images.data[0].id.uuid
      // );

      const { relationships, ...rest } = resp;

      return {
        ...rest,
        author,
        images,
      };
    });
    dispatch(featuredListingsSuccess(newData));
  } catch (err) {
    console.error(err);
    dispatch(featuredListingsError(storableError(err)));
  }
};

export const loadData = (params, search) => (dispatch) => {
  return Promise.all([
    dispatch(loadNewArrivalListings(params, search)),
    dispatch(loadFeaturedListings(params, search)),
  ]);
};
