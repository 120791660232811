import axios from 'axios';
import { fetchCurrentUser } from '../../ducks/user.duck';
import { apiBaseUrl } from '../../util/api';
import { parse } from '../../util/urlHelpers';
import { createImageVariantConfig, types as sdkTypes } from '../../util/sdkLoader';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import config from '../../config';
const { UUID } = sdkTypes;

// ============Action Type =================
export const FETCH_FAVOURITE_LISTINGS_REQUEST =
  'app/FavouritesPage/FETCH_FAVOURITE_LISTINGS_REQUEST';
export const FETCH_FAVOURITE_LISTINGS_SUCCESS =
  'app/FavouritesPage/FETCH_FAVOURITE_LISTINGS_SUCCESS';
export const FETCH_FAVOURITE_LISTINGS_ERROR = 'app/FavouritesPage/FETCH_FAVOURITE_LISTINGS_ERROR';

//=============Reducer=================
const initialState = {
  pagination: null,
  queryParams: null,
  queryInProgress: null,
  queryListingsError: null,
  currentPageResultIds: [],
};
const resultIds = (data) => data.data.map((l) => l.id);

const favouritesPageReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_FAVOURITE_LISTINGS_REQUEST:
      return {
        ...state,
        queryParams: payload.queryParams,
        queryInProgress: true,
        queryListingsError: null,
        currentPageResultIds: [],
      };
    case FETCH_FAVOURITE_LISTINGS_SUCCESS:
      return {
        ...state,
        currentPageResultIds: resultIds(payload.data.data),
        pagination: payload.data.data.meta,
        queryInProgress: false,
      };
    case FETCH_FAVOURITE_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      return { ...state, queryInProgress: false, queryListingsError: payload };
    default:
      return { ...state };
  }
};
export default favouritesPageReducer;
//=======ActionCreators==============
export const queryListingsRequest = (currentUser) => ({
  type: FETCH_FAVOURITE_LISTINGS_REQUEST,
  payload: { currentUser },
});

export const queryListingsSuccess = (response) => {
  return {
    type: FETCH_FAVOURITE_LISTINGS_SUCCESS,
    payload: { data: response },
  };
};

export const queryListingsError = (e) => ({
  type: FETCH_FAVOURITE_LISTINGS_ERROR,
  error: true,
  payload: e,
});

//==========fetch from database ===========
export const getFavouriteListings = (page) => async (dispatch, getState, sdk) => {
  const { currentUser } = getState().user;
  dispatch(queryListingsRequest(currentUser));
  const listingIds = currentUser.attributes.profile.privateData.favourites;
  const { aspectWidth = 1, aspectHeight = 1, variantPrefix = 'listing-card' } = config.listing;
  const aspectRatio = aspectHeight / aspectWidth;
  console.log(111123, listingIds);
  if (listingIds?.length > 0) {
    try {
      const resp = await sdk.listings.query({
        ids: listingIds,
        per_page: 20,
        pub_isChatWithAdminListing: false,

        page,
        include: ['author', 'author.profileImage', 'images'],
        'fields.image': [
          // Listing page
          'variants.landscape-crop',
          'variants.landscape-crop2x',
          `variants.listing-card`,
          `variants.${variantPrefix}-2x`,
        ],
        ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
        ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
      });
      dispatch(addMarketplaceEntities(resp));
      dispatch(queryListingsSuccess(resp));
      return resp;
    } catch (error) {
      dispatch(queryListingsError(error));
      return;
    }
  } else {
    dispatch(queryListingsSuccess({ data: { data: [] } }));
    return { data: { data: [] } };
  }
};

export const loadData = (param, search) => async (dispatch, getState) => {
  const queryParams = parse(search);
  const page = queryParams.page;
  // const page = queryParams.page || 1;
  const queryPage = !!page ? page : 1;
  // const queryPage = page && Object.keys(page).length === 0 ? 0 : page;
  await dispatch(fetchCurrentUser());
  return dispatch(getFavouriteListings(queryPage));
};
