import config from '../../config';
import { types as sdkTypes, util as sdkUtil, createImageVariantConfig } from '../../util/sdkLoader';
import { storableError } from '../../util/errors';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import {
  LISTING_PAGE_DRAFT_VARIANT,
  LISTING_PAGE_PENDING_APPROVAL_VARIANT,
} from '../../util/urlHelpers';
import { fetchCurrentUser, fetchCurrentUserHasOrdersSuccess } from '../../ducks/user.duck';
import axios from 'axios';
import { apiBaseUrl } from '../../util/api';

const { UUID } = sdkTypes;

// ================ Action types ================ //

export const SHOW_LISTING_REQUEST = 'app/ViewListingPage/SHOW_LISTING_REQUEST';
export const SHOW_LISTING_SUCCESS = 'app/ViewListingPage/SHOW_LISTING_SUCCESS';
export const SHOW_LISTING_ERROR = 'app/ViewListingPage/SHOW_LISTING_ERROR';
// ================ Reducer ================ //

const initialState = {
  id: null,
  showListingError: null,
  listingLoading: false,
};

const viewListingPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case SHOW_LISTING_REQUEST:
      return { ...state, id: payload.id, showListingError: null, listingLoading: true };
    case SHOW_LISTING_SUCCESS:
      return { ...state, id: payload.id, showListingError: null, listingLoading: false };
    case SHOW_LISTING_ERROR:
      return { ...state, showListingError: payload, listingLoading: false };

    default:
      return state;
  }
};

export default viewListingPageReducer;

// ================ Action creators ================ //

export const showListingRequest = (id) => ({
  type: SHOW_LISTING_REQUEST,
  payload: { id },
});
export const showListingSuccess = (id) => ({
  type: SHOW_LISTING_SUCCESS,
  payload: { id },
});

export const showListingError = (e) => ({
  type: SHOW_LISTING_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

export const showListing = (listingId) => (dispatch, getState, sdk) => {
  const { aspectWidth = 1, aspectHeight = 1, variantPrefix = 'listing-card' } = config.listing;
  const aspectRatio = aspectHeight / aspectWidth;
  dispatch(showListingRequest(listingId));
  dispatch(fetchCurrentUser());
  const params = {
    id: listingId,
    include: ['author', 'author.profileImage', 'images', 'currentStock'],
    'fields.image': [
      // Scaled variants for large images
      //   'variants.scaled-small',
      //   'variants.scaled-medium',
      //   'variants.scaled-large',
      //   'variants.scaled-xlarge',

      // Cropped variants for listing thumbnail images
      `variants.${variantPrefix}`,
      `variants.${variantPrefix}-2x`,
      `variants.${variantPrefix}-4x`,
      `variants.${variantPrefix}-6x`,
      'variants.default',

      // Social media
      //   'variants.facebook',
      //   'variants.twitter',

      // Avatars
      //   'variants.square-small',
      //   'variants.square-small2x',
    ],
    ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
    ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
    ...createImageVariantConfig(`${variantPrefix}-4x`, 1600, aspectRatio),
    ...createImageVariantConfig(`${variantPrefix}-6x`, 2400, aspectRatio),
  };
  const show = sdk.listings.show(params);
  return show
    .then(async (data) => {
      const privateData = await axios.post(`${apiBaseUrl()}/api/getListingPvtData`, {
        listingId: data.data.data.id.uuid,
      });
      data.data.data.attributes['privateData'] = privateData.data.privateData;
      dispatch(showListingSuccess(listingId));
      dispatch(addMarketplaceEntities(data));
      return data;
    })
    .catch((e) => {
      console.log(323, e, 323);
      dispatch(showListingError(storableError(e)));
    });
};
export const loadData = (params, search) => (dispatch) => {
  const listingId = new UUID(params.id);

  return Promise.all([dispatch(showListing(listingId))]);
};
