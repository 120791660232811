import pick from 'lodash/pick';
import config from '../../config';
import { apiBaseUrl, initiatePrivileged, transitionPrivileged } from '../../util/api';
import { denormalisedResponseEntities } from '../../util/data';
import { storableError } from '../../util/errors';
import {
  TRANSITION_REQUEST_PAYMENT,
  TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY,
  TRANSITION_CONFIRM_PAYMENT,
  isPrivileged,
  TRANSITION_ZERO_REQUEST_PAYMENT,
  TRANSITION_ZERO_CONFIRM_PAYMENT,
  TRANSITION_PAYPAL_REQUEST_PAYMENT,
  TRANSITION_PAYPAL_CONFIRM_PAYMENT,
} from '../../util/transaction';
import * as log from '../../util/log';
import { fetchCurrentUserHasOrdersSuccess, fetchCurrentUser } from '../../ducks/user.duck';
import axios from 'axios';

// ================ Action types ================ //

export const SET_INITIAL_VALUES = 'app/CheckoutPage/SET_INITIAL_VALUES';

export const INIITATE_PAYPAL_ORDER_REQUEST = 'app/CheckoutPage/INITIATE_PAYPAL_ORDER_REQUEST';
export const INITIATE_PAYPAL_ORDER_SUCCESS = 'app/CheckoutPage/INITIATE_PAYPAL_ORDER_SUCCESS';
export const INITIATE_PAYPAL_ORDER_ERROR = 'app/CheckoutPage/INITIATE_PAYPAL_ORDER_ERROR';

export const INITIATE_ORDER_REQUEST = 'app/CheckoutPage/INITIATE_ORDER_REQUEST';
export const INITIATE_ORDER_SUCCESS = 'app/CheckoutPage/INITIATE_ORDER_SUCCESS';
export const INITIATE_ORDER_ERROR = 'app/CheckoutPage/INITIATE_ORDER_ERROR';

export const CONFIRM_PAYMENT_REQUEST = 'app/CheckoutPage/CONFIRM_PAYMENT_REQUEST';
export const CONFIRM_PAYMENT_SUCCESS = 'app/CheckoutPage/CONFIRM_PAYMENT_SUCCESS';
export const CONFIRM_PAYMENT_ERROR = 'app/CheckoutPage/CONFIRM_PAYMENT_ERROR';

export const SPECULATE_TRANSACTION_REQUEST = 'app/ListingPage/SPECULATE_TRANSACTION_REQUEST';
export const SPECULATE_TRANSACTION_SUCCESS = 'app/ListingPage/SPECULATE_TRANSACTION_SUCCESS';
export const SPECULATE_TRANSACTION_ERROR = 'app/ListingPage/SPECULATE_TRANSACTION_ERROR';

export const STRIPE_CUSTOMER_REQUEST = 'app/CheckoutPage/STRIPE_CUSTOMER_REQUEST';
export const STRIPE_CUSTOMER_SUCCESS = 'app/CheckoutPage/STRIPE_CUSTOMER_SUCCESS';
export const STRIPE_CUSTOMER_ERROR = 'app/CheckoutPage/STRIPE_CUSTOMER_ERROR';

export const ZERO_TRANSACTION_REQUEST = 'app/ListingPage/ZERO_TRANSACTION_REQUEST';
export const ZERO_TRANSACTION_SUCCESS = 'app/ListingPage/ZERO_TRANSACTION_SUCCESS';
export const ZERO_TRANSACTION_ERROR = 'app/ListingPage/ZERO_TRANSACTION_ERROR';

// ================ Reducer ================ //

const initialState = {
  listing: null,
  orderData: null,
  speculateTransactionInProgress: false,
  speculateTransactionError: null,
  speculatedTransaction: null,
  transaction: null,
  initiateOrderError: null,
  confirmPaymentError: null,
  stripeCustomerFetched: false,
  zeroOrderRequest: false,
  zeroOrderSuccess: false,
  zeroOrderError: false,
  initiatePaypalOrderInProgress: false,
  initiatePaypalOrderError: null,
};

export default function checkoutPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case ZERO_TRANSACTION_REQUEST:
      return { ...state, zeroOrderRequest: true, zeroOrderError: false, zeroOrderSuccess: false };
    case ZERO_TRANSACTION_SUCCESS:
      return { ...state, zeroOrderRequest: false, zeroOrderError: false, zeroOrderSuccess: true };
    case ZERO_TRANSACTION_ERROR:
      return { ...state, zeroOrderRequest: false, zeroOrderError: true, zeroOrderSuccess: false };
    case SET_INITIAL_VALUES:
      return { ...initialState, ...payload };

    case SPECULATE_TRANSACTION_REQUEST:
      return {
        ...state,
        speculateTransactionInProgress: true,
        speculateTransactionError: null,
        speculatedTransaction: null,
      };
    case SPECULATE_TRANSACTION_SUCCESS:
      return {
        ...state,
        speculateTransactionInProgress: false,
        speculatedTransaction: payload.transaction,
      };
    case SPECULATE_TRANSACTION_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return {
        ...state,
        speculateTransactionInProgress: false,
        speculateTransactionError: payload,
      };

    case INITIATE_ORDER_REQUEST:
      return { ...state, initiateOrderError: null };
    case INITIATE_ORDER_SUCCESS:
      return { ...state, transaction: payload };
    case INITIATE_ORDER_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return { ...state, initiateOrderError: payload };

    case CONFIRM_PAYMENT_REQUEST:
      return { ...state, confirmPaymentError: null };
    case CONFIRM_PAYMENT_SUCCESS:
      return state;
    case CONFIRM_PAYMENT_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return { ...state, confirmPaymentError: payload };

    case STRIPE_CUSTOMER_REQUEST:
      return { ...state, stripeCustomerFetched: false };
    case STRIPE_CUSTOMER_SUCCESS:
      return { ...state, stripeCustomerFetched: true };
    case STRIPE_CUSTOMER_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return { ...state, stripeCustomerFetchError: payload };
    case INIITATE_PAYPAL_ORDER_REQUEST:
      return {
        ...state,
        initiatePaypalOrderError: null,
        initiatePaypalOrderInProgress: true,
      };

    case INITIATE_PAYPAL_ORDER_SUCCESS:
      return {
        ...state,
        initiatePaypalOrderInProgress: false,
        transaction: payload,
      };

    case INITIATE_PAYPAL_ORDER_ERROR:
      return {
        ...state,
        initiatePaypalOrderInProgress: false,
        initiatePaypalOrderError: payload,
      };
    default:
      return state;
  }
}

// ================ Selectors ================ //

// ================ Action creators ================ //

export const setInitialValues = (initialValues) => ({
  type: SET_INITIAL_VALUES,
  payload: pick(initialValues, Object.keys(initialState)),
});

const initiateOrderRequest = () => ({ type: INITIATE_ORDER_REQUEST });

const initiateOrderSuccess = (order) => ({
  type: INITIATE_ORDER_SUCCESS,
  payload: order,
});

const initiateOrderError = (e) => ({
  type: INITIATE_ORDER_ERROR,
  error: true,
  payload: e,
});

const confirmPaymentRequest = () => ({ type: CONFIRM_PAYMENT_REQUEST });

const confirmPaymentSuccess = (orderId) => ({
  type: CONFIRM_PAYMENT_SUCCESS,
  payload: orderId,
});

const confirmPaymentError = (e) => ({
  type: CONFIRM_PAYMENT_ERROR,
  error: true,
  payload: e,
});

export const speculateTransactionRequest = () => ({ type: SPECULATE_TRANSACTION_REQUEST });

export const speculateTransactionSuccess = (transaction) => ({
  type: SPECULATE_TRANSACTION_SUCCESS,
  payload: { transaction },
});

export const speculateTransactionError = (e) => ({
  type: SPECULATE_TRANSACTION_ERROR,
  error: true,
  payload: e,
});
export const zeroTransactionRequest = () => ({ type: ZERO_TRANSACTION_REQUEST });

export const zeroTransactionSuccess = (transaction) => ({
  type: ZERO_TRANSACTION_SUCCESS,
  payload: { transaction },
});

export const zeroTransactionError = (e) => ({
  type: ZERO_TRANSACTION_ERROR,
  error: true,
  payload: e,
});
export const stripeCustomerRequest = () => ({ type: STRIPE_CUSTOMER_REQUEST });
export const stripeCustomerSuccess = () => ({ type: STRIPE_CUSTOMER_SUCCESS });
export const stripeCustomerError = (e) => ({
  type: STRIPE_CUSTOMER_ERROR,
  error: true,
  payload: e,
});
const initiatePaypalOrderRequest = () => ({
  type: INIITATE_PAYPAL_ORDER_REQUEST,
});

const initiatePaypalOrderSuccess = (order) => ({
  type: INITIATE_PAYPAL_ORDER_SUCCESS,
  payload: order,
});

const initiatePaypalOrderError = (e) => ({
  type: INITIATE_PAYPAL_ORDER_ERROR,
  error: true,
  payload: e,
});
/* ================ Thunks ================ */

export const initiatePaypalOrder = (orderParams, transactionId) => (dispatch, getState, sdk) => {
  dispatch(initiateOrderRequest());

  // If we already have a transaction ID, we should transition, not
  // initiate.
  const isTransition = !!transactionId;
  const transition = TRANSITION_PAYPAL_REQUEST_PAYMENT;

  const isPrivilegedTransition = isPrivileged(transition);
  const bookingData = {
    quantity: 1,
    stockReservationQuantity: 1,
  };

  const bodyParams = isTransition
    ? {
        id: transactionId,
        transition,
        params: orderParams,
      }
    : {
        processAlias: config.transactionProcessAlias,
        transition,
        params: orderParams,
      };
  const queryParams = {
    include: ['booking', 'provider'],
    expand: true,
  };

  const handleSucces = (response) => {
    const entities = denormalisedResponseEntities(response);
    const order = entities[0];
    dispatch(initiateOrderSuccess(order));
    // dispatch(fetchCurrentUserHasOrdersSuccess(true));
    return order;
  };

  const handleError = (e) => {
    dispatch(initiateOrderError(storableError(e)));
    const transactionIdMaybe = transactionId ? { transactionId: transactionId.uuid } : {};
    log.error(e, 'initiate-order-failed', {
      ...transactionIdMaybe,
      listingId: orderParams.listingId.uuid,
      bookingStart: orderParams.bookingStart,
      bookingEnd: orderParams.bookingEnd,
    });
    throw e;
  };
  if (isTransition && isPrivilegedTransition) {
    // transition privileged
    return transitionPrivileged({
      isSpeculative: false,
      bookingData,
      bodyParams,
      queryParams,
    })
      .then(handleSucces)
      .catch(handleError);
  } else if (isTransition) {
    // transition non-privileged
    return sdk.transactions
      .transition(bodyParams, queryParams)
      .then(handleSucces)
      .catch(handleError);
  } else if (isPrivilegedTransition) {
    return initiatePrivileged({
      isSpeculative: false,
      bookingData,
      bodyParams,
      queryParams,
    })
      .then(handleSucces)
      .catch(handleError);
  } else {
    return sdk.transactions.initiate(bodyParams, queryParams).then(handleSucces).catch(handleError);
  }
};

export const confirmPaypalPayment = (orderParams) => (dispatch, getState, sdk) => {
  dispatch(confirmPaymentRequest());
  const { transactionId, ...rest } = orderParams;
  const bodyParams = {
    id: orderParams.transactionId,
    transition: TRANSITION_PAYPAL_CONFIRM_PAYMENT,
    params: { ...rest },
  };
  return (
    sdk.transactions
      .transition(bodyParams)
      // .then((response) => {
      //   return sdk.transactions.transition({
      //     ...bodyParams,
      //     transition: TRANSITION_RENTAL_PRODUCT_PAYPAL_PURCHASED,
      //   });
      // })
      .then((response) => {
        const order = response.data.data;
        dispatch(confirmPaymentSuccess(order.id));
        return order;
      })
      .catch((e) => {
        dispatch(confirmPaymentError(storableError(e)));
        const transactionIdMaybe = orderParams.transactionId
          ? { transactionId: orderParams.transactionId.uuid }
          : {};
        log.error(e, 'initiate-order-failed', {
          ...transactionIdMaybe,
        });
        throw e;
      })
  );
};

export const initiateZeroOrder = (orderParams) => (dispatch, getState, sdk) => {
  dispatch(zeroTransactionRequest());
  // console.log(369, orderParams, 369);
  let transaction = null;
  let bodyParams = {
    processAlias: config.transactionProcessAlias,
    transition: TRANSITION_ZERO_REQUEST_PAYMENT,
    params: {
      ...orderParams,
    },
  };
  const bookingData = {
    ...orderParams,
  };

  const queryParams = {
    include: ['booking', 'provider'],
    expand: true,
  };

  return initiatePrivileged({ isSpeculative: false, bookingData, bodyParams, queryParams })
    .then((response) => {
      const entities = denormalisedResponseEntities(response);
      const order = entities[0];
      transaction = order;
      bodyParams = {
        id: order.id,
        transition: TRANSITION_ZERO_CONFIRM_PAYMENT,
        params: {},
      };
      // const email = protectedData.email;
      // bodyParams = {
      //   ...bodyParams,
      //   protectedData: {
      //     email,
      //   },
      // };
      return sdk.transactions.transition(bodyParams);
    })
    .then((resp) => {
      dispatch(zeroTransactionSuccess(transaction));
      axios.post(`${apiBaseUrl()}/api/addSold`, {
        listingId: bookingData.listingId,
      });
      return resp.data.data;
    })
    .catch((err) => {
      dispatch(zeroTransactionError(storableError(err)));
      const transactionIdMaybe = transaction ? { transactionId: transaction.id.uuid } : {};
      log.error(err, 'initiate-order-failed', {
        ...transactionIdMaybe,
        listingId: orderParams.listing.id.uuid,
      });
      throw err;
    });
};
export const initiateOrder = (orderParams, transactionId) => (dispatch, getState, sdk) => {
  dispatch(initiateOrderRequest());

  // If we already have a transaction ID, we should transition, not
  // initiate.
  const isTransition = !!transactionId;
  const transition = isTransition
    ? TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY
    : TRANSITION_PAYPAL_REQUEST_PAYMENT;
  const isPrivilegedTransition = isPrivileged(transition);

  const { deliveryMethod, quantity, bookingDates, ...otherOrderParams } = orderParams;
  const quantityMaybe = quantity ? { stockReservationQuantity: quantity } : {};
  const bookingParamsMaybe = bookingDates || {};

  // Parameters only for client app's server
  const orderData = {
    deliveryMethod,
  };

  // Parameters for Flex API
  const transitionParams = {
    ...quantityMaybe,
    ...bookingParamsMaybe,
    ...otherOrderParams,
  };

  const bodyParams = isTransition
    ? {
        id: transactionId,
        transition,
        params: transitionParams,
      }
    : {
        processAlias: config.transactionProcessAlias,
        transition,
        params: transitionParams,
      };
  const queryParams = {
    include: ['booking', 'provider'],
    expand: true,
  };

  const handleSucces = (response) => {
    const entities = denormalisedResponseEntities(response);
    const order = entities[0];
    dispatch(initiateOrderSuccess(order));
    dispatch(fetchCurrentUserHasOrdersSuccess(true));
    return order;
  };

  const handleError = (e) => {
    dispatch(initiateOrderError(storableError(e)));
    const transactionIdMaybe = transactionId ? { transactionId: transactionId.uuid } : {};
    log.error(e, 'initiate-order-failed', {
      ...transactionIdMaybe,
      listingId: orderParams.listingId.uuid,
      ...quantityMaybe,
      ...bookingParamsMaybe,
      ...orderData,
    });
    throw e;
  };

  if (isTransition && isPrivilegedTransition) {
    // transition privileged
    return transitionPrivileged({ isSpeculative: false, orderData, bodyParams, queryParams })
      .then(handleSucces)
      .catch(handleError);
  } else if (isTransition) {
    // transition non-privileged
    return sdk.transactions
      .transition(bodyParams, queryParams)
      .then(handleSucces)
      .catch(handleError);
  } else if (isPrivilegedTransition) {
    // initiate privileged
    return initiatePrivileged({ isSpeculative: false, orderData, bodyParams, queryParams })
      .then(handleSucces)
      .catch(handleError);
  } else {
    // initiate non-privileged
    return sdk.transactions.initiate(bodyParams, queryParams).then(handleSucces).catch(handleError);
  }
};

export const confirmPayment = (orderParams) => (dispatch, getState, sdk) => {
  dispatch(confirmPaymentRequest());

  const bodyParams = {
    id: orderParams.transactionId,
    transition: TRANSITION_PAYPAL_CONFIRM_PAYMENT,
    params: {},
  };

  return sdk.transactions
    .transition(bodyParams)
    .then((response) => {
      const order = response.data.data;
      dispatch(confirmPaymentSuccess(order.id));
      return order;
    })
    .catch((e) => {
      dispatch(confirmPaymentError(storableError(e)));
      const transactionIdMaybe = orderParams.transactionId
        ? { transactionId: orderParams.transactionId.uuid }
        : {};
      log.error(e, 'initiate-order-failed', {
        ...transactionIdMaybe,
      });
      throw e;
    });
};

export const sendMessage = (params) => (dispatch, getState, sdk) => {
  const message = params.message;
  const orderId = params.id;

  if (message) {
    return sdk.messages
      .send({ transactionId: orderId, content: message })
      .then(() => {
        return { orderId, messageSuccess: true };
      })
      .catch((e) => {
        log.error(e, 'initial-message-send-failed', { txId: orderId });
        return { orderId, messageSuccess: false };
      });
  } else {
    return Promise.resolve({ orderId, messageSuccess: true });
  }
};

/**
 * Initiate or transition the speculative transaction with the given
 * booking details
 *
 * The API allows us to do speculative transaction initiation and
 * transitions. This way we can create a test transaction and get the
 * actual pricing information as if the transaction had been started,
 * without affecting the actual data.
 *
 * We store this speculative transaction in the page store and use the
 * pricing info for the booking breakdown to get a proper estimate for
 * the price with the chosen information.
 */
export const speculateTransaction = (orderParams, transactionId) => (dispatch, getState, sdk) => {
  dispatch(speculateTransactionRequest());

  // If we already have a transaction ID, we should transition, not
  // initiate.
  const isTransition = !!transactionId;
  const transition = isTransition
    ? TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY
    : TRANSITION_PAYPAL_REQUEST_PAYMENT;
  const isPrivilegedTransition = isPrivileged(transition);

  const { deliveryMethod, quantity, bookingDates, ...otherOrderParams } = orderParams;
  const quantityMaybe = quantity ? { stockReservationQuantity: quantity } : {};
  const bookingParamsMaybe = bookingDates || {};

  // Parameters only for client app's server
  const orderData = {
    deliveryMethod,
  };

  // Parameters for Flex API
  const transitionParams = {
    ...quantityMaybe,
    ...bookingParamsMaybe,
    ...otherOrderParams,
    cardToken: 'CheckoutPage_speculative_card_token',
  };

  const bodyParams = isTransition
    ? {
        id: transactionId,
        transition,
        params: transitionParams,
      }
    : {
        processAlias: config.transactionProcessAlias,
        transition,
        params: transitionParams,
      };

  const queryParams = {
    include: ['booking', 'provider'],
    expand: true,
  };

  const handleSuccess = (response) => {
    const entities = denormalisedResponseEntities(response);
    if (entities.length !== 1) {
      throw new Error('Expected a resource in the speculate response');
    }
    const tx = entities[0];
    dispatch(speculateTransactionSuccess(tx));
  };

  const handleError = (e) => {
    log.error(e, 'speculate-transaction-failed', {
      listingId: transitionParams.listingId.uuid,
      ...quantityMaybe,
      ...bookingParamsMaybe,
      ...orderData,
    });
    return dispatch(speculateTransactionError(storableError(e)));
  };

  if (isTransition && isPrivilegedTransition) {
    // transition privileged
    return transitionPrivileged({ isSpeculative: true, orderData, bodyParams, queryParams })
      .then(handleSuccess)
      .catch(handleError);
  } else if (isTransition) {
    // transition non-privileged
    return sdk.transactions
      .transitionSpeculative(bodyParams, queryParams)
      .then(handleSuccess)
      .catch(handleError);
  } else if (isPrivilegedTransition) {
    // initiate privileged
    return initiatePrivileged({ isSpeculative: true, orderData, bodyParams, queryParams })
      .then(handleSuccess)
      .catch(handleError);
  } else {
    // initiate non-privileged
    return sdk.transactions
      .initiateSpeculative(bodyParams, queryParams)
      .then(handleSuccess)
      .catch(handleError);
  }
};

// StripeCustomer is a relantionship to currentUser
// We need to fetch currentUser with correct params to include relationship
export const stripeCustomer = () => (dispatch, getState, sdk) => {
  dispatch(stripeCustomerRequest());

  return dispatch(fetchCurrentUser({ include: ['stripeCustomer.defaultPaymentMethod'] }))
    .then((response) => {
      dispatch(stripeCustomerSuccess());
    })
    .catch((e) => {
      dispatch(stripeCustomerError(storableError(e)));
    });
};
