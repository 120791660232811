import React, { useEffect, useRef, useState } from 'react';
import { arrayOf, bool, number, oneOf, shape, string } from 'prop-types';
import { compose } from 'redux';
import { connect, useDispatch } from 'react-redux';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';
import { withRouter } from 'react-router-dom';

import config from '../../config';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import {
  txIsCanceled,
  txIsEnquired,
  txIsPurchased,
  txIsDelivered,
  txIsDisputed,
  txIsPaymentExpired,
  txIsPaymentPending,
  txIsReceived,
  txIsCompleted,
  txIsReviewedByCustomer,
  txIsReviewedByProvider,
  txIsReviewed,
  TRANSITION_AUTO_COMPLETE,
} from '../../util/transaction';
import { propTypes, DATE_TYPE_DATE } from '../../util/types';
import { ensureCurrentUser } from '../../util/data';
import { formatDateIntoPartials } from '../../util/dates';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { types as sdkTypes, createImageVariantConfig } from '../../util/sdkLoader';
import {
  Avatar,
  NamedLink,
  NotificationBadge,
  Page,
  PaginationLinks,
  TabNav,
  LayoutSideNavigation,
  LayoutWrapperMain,
  LayoutWrapperSideNav,
  LayoutWrapperTopbar,
  LayoutWrapperFooter,
  Footer,
  IconSpinner,
  UserDisplayName,
  LayoutSingleColumn,
} from '../../components';

import TopbarContainer from '../../containers/TopbarContainer/TopbarContainer';
import NotFoundPage from '../../containers/NotFoundPage/NotFoundPage';

import css from './InboxPage.module.css';
import { BiArrowBack } from 'react-icons/bi';
import { formatMoney } from '../../util/currency';
import {
  createListingDraftRequest,
  createListingDraftSuccess,
  requestCreateListingDraft,
  requestShowListing,
  showListingsRequest,
  showListingsSuccess,
  updateStockOfListingMaybe,
} from '../EditListingPage/EditListingPage.duck';
import EditListingPage from '../EditListingPage/EditListingPage';
import { createInstance } from '../../util/sdkLoader';
import {
  LISTING_PAGE_PARAM_TYPE_DRAFT,
  LISTING_PAGE_PARAM_TYPE_EDIT,
  createSlug,
} from '../../util/urlHelpers';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routing/routeConfiguration';
import { apiBaseUrl } from '../../util/api';
import axios from 'axios';
import { removeProcessedTxs } from '../AdminDashboard/AdminDashboard.duck';

// Translated name of the state of the given transaction
export const txState = (intl, tx, type) => {
  const isOrder = type === 'order';

  if (txIsEnquired(tx)) {
    return {
      lastTransitionedAtClassName: css.lastTransitionedAtEmphasized,
      stateClassName: css.stateActionNeeded,
      state: intl.formatMessage({
        id: 'InboxPage.stateEnquiry',
      }),
    };
  } else if (txIsPaymentPending(tx)) {
    return {
      stateClassName: isOrder ? css.stateActionNeeded : css.stateNoActionNeeded,
      state: intl.formatMessage({
        id: 'InboxPage.statePendingPayment',
      }),
    };
  } else if (txIsPaymentExpired(tx)) {
    return {
      stateClassName: css.stateNoActionNeeded,
      state: intl.formatMessage({
        id: 'InboxPage.statePaymentExpired',
      }),
    };
  } else if (txIsCanceled(tx)) {
    return {
      stateClassName: css.stateConcluded,
      state: intl.formatMessage({
        id: 'InboxPage.stateCanceled',
      }),
    };
  } else if (txIsPurchased(tx)) {
    return {
      stateClassName: isOrder ? css.stateNoActionNeeded : css.stateActionNeeded,
      state: intl.formatMessage({
        id: 'InboxPage.statePurchased',
      }),
    };
  } else if (txIsDelivered(tx)) {
    return isOrder
      ? {
          stateClassName: css.stateActionNeeded,
          state: intl.formatMessage({ id: 'InboxPage.stateDeliveredCustomer' }),
        }
      : {
          stateClassName: css.stateNoActionNeeded,
          state: intl.formatMessage({ id: 'InboxPage.stateDeliveredProvider' }),
        };
  } else if (txIsDisputed(tx)) {
    return {
      stateClassName: css.stateActionNeeded,
      state: intl.formatMessage({
        id: 'InboxPage.stateDisputed',
      }),
    };
  } else if (txIsReceived(tx) || txIsCompleted(tx)) {
    return {
      stateClassName: css.stateActionNeeded,
      state: intl.formatMessage({
        id: 'InboxPage.stateReceived',
      }),
    };
  } else if (txIsReviewedByCustomer(tx)) {
    const translationKey = isOrder ? 'InboxPage.stateReviewGiven' : 'InboxPage.stateReviewNeeded';
    return {
      stateClassName: isOrder ? css.stateNoActionNeeded : css.stateActionNeeded,
      state: intl.formatMessage({
        id: translationKey,
      }),
    };
  } else if (txIsReviewedByProvider(tx)) {
    const translationKey = isOrder ? 'InboxPage.stateReviewNeeded' : 'InboxPage.stateReviewGiven';
    return {
      stateClassName: isOrder ? css.stateActionNeeded : css.stateNoActionNeeded,
      state: intl.formatMessage({
        id: translationKey,
      }),
    };
  } else if (txIsReviewed(tx)) {
    return {
      stateClassName: css.stateConcluded,
      state: intl.formatMessage({
        id: 'InboxPage.stateReviewed',
      }),
    };
  } else {
    console.warn('This transition is unknown:', tx.attributes.lastTransition);
    return null;
  }
};

export const urlToFile = async (url) => {
  return fetch(url)
    .then((response) => response.arrayBuffer())
    .then((res) => {
      const filename = 'temp.jpg';
      const file = new File([res], filename, { type: 'image/jpg' });
      return file;
    });
};

export const InboxItem = (props) => {
  const {
    unitType,
    type,
    tx,
    intl,
    stateData,
    history,
    dispatch,
    currentUser,
    showPayoutButton = false,
  } = props;
  const [payoutProcessing, setPayoutProcessing] = useState(false);
  const [payoutSuccess, setPayoutSuccess] = useState(false);
  const [creatingQuote, setCreatingQuote] = useState(false);
  const { customer, provider, listing } = tx;
  const isOrder = type === 'order';
  const { UUID } = sdkTypes;
  const isSuperAdmin = currentUser?.attributes?.profile?.privateData?.isSuperAdmin;
  // console.log(258, isSuperAdmin);
  let unitPurchase = tx.attributes?.lineItems?.find(
    (item) => item.code === unitType && !item.reversal
  );
  const quantity = unitPurchase ? unitPurchase.quantity.toString() : null;
  const unitPrice = formatMoney(intl, tx.attributes?.payinTotal);
  const listingLocation = tx.listing?.attributes?.publicData?.address;
  const isChatWithAdminListing = tx.listing?.attributes?.publicData?.isChatWithAdminListing;
  const isQuoteType = tx.listing?.attributes?.publicData?.isQuoteType;

  const otherUser = isOrder ? provider : customer;
  const otherUserDisplayName = <UserDisplayName user={otherUser} intl={intl} />;
  const isOtherUserBanned = otherUser.attributes.banned;
  const otherUserEmail = tx?.attributes?.protectedData?.customerEmail;
  // console.log(55555, tx);
  const isSaleNotification = !isOrder && txIsPurchased(tx);
  const rowNotificationDot = isSaleNotification ? <div className={css.notificationDot} /> : null;
  const lastTransitionedAt = formatDateIntoPartials(tx.attributes.lastTransitionedAt, intl);
  const [disableButton, setDisableButton] = useState(false);

  const sdkInstance = useRef(createInstance({ clientId: config.sdk.clientId }));
  const isMountedRef = useRef(true);
  useEffect(() => {
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  const handleCreateQuoteSubmit = async () => {
    setDisableButton(true);
    setCreatingQuote(true);
    try {
      const listingId = tx.listing?.id?.uuid;

      const listingResponse = await dispatch(requestShowListing({ id: listingId }));

      const images = listingResponse.data.data.relationships.images.data
        .filter((i) => i.type === 'image')
        .map((i) => i.id.uuid);

      const imagesUrl = listingResponse.data.included
        .filter((i) => images.includes(i.id.uuid))
        .map((i) => i.attributes.variants.default.url);

      const imageFiles = await Promise.all(
        imagesUrl.map(async (url) => {
          const file = await urlToFile(url);
          return file;
        })
      );

      const newImgIds = await Promise.all(
        imageFiles.map(async (f) => {
          const data = await sdkInstance.current.images.upload({
            image: f,
          });
          return data.data.data.id.uuid;
        })
      );

      const sourceListingData = listingResponse?.data.data?.attributes;

      const { ...publicData } = sourceListingData.publicData;
      const clientId = otherUserEmail;
      const isQuoteType = true;
      const investmentSize = '';
      const sold = true;
      const newPublicDataWithClientId = {
        ...publicData,
        investmentSize,
        isQuoteType,
        clientId,
        sold,
      };
      const newDraftData = {
        title: sourceListingData.title,
        geolocation: sourceListingData.geolocation,
        description: sourceListingData.description,
        privateData: { ...sourceListingData.privateData },
        publicData: newPublicDataWithClientId,
        images: newImgIds,
      };
      let draftResponse;
      sdkInstance?.current.ownListings
        .createDraft(newDraftData, {
          expand: true,
          include: ['images'],
        })
        .then((res) => {
          draftResponse = res;
          return updateStockOfListingMaybe(
            res.data.data.id,
            { oldTotal: null, newTotal: 100 },
            dispatch
          );
        })
        .then((res) => {
          if (!isMountedRef.current) {
            return;
          }
          const newDraftId = draftResponse.data.data.id.uuid;

          const slug = createSlug(newDraftData.title);
          const draftType = LISTING_PAGE_PARAM_TYPE_DRAFT;
          const tab = 'listingdetails';
          const editRoute = createResourceLocatorString(
            'EditListingQuotePage',
            routeConfiguration(),
            { slug, id: newDraftId, type: draftType, tab },
            {}
          );
          setDisableButton(false);
          history.push(editRoute);
        })
        .catch((error) => {
          setDisableButton(false);
          console.error('Error creating new draft:', error);
        });
    } catch (error) {
      setDisableButton(false);
      console.error('Error creating draft and redirecting:', error);
    }
  };
  const transitionPayoutTx = async () => {
    try {
      if (payoutProcessing || payoutSuccess || !showPayoutButton) return;
      setPayoutProcessing(true);
      const url = `${apiBaseUrl()}/api/transitionMarkForPayout`;
      const params = { txId: tx.id.uuid };
      await axios.post(url, params);
      setPayoutProcessing(false);
      setPayoutSuccess(true);
      dispatch(removeProcessedTxs({ txId: tx.id.uuid }));
    } catch (error) {
      setPayoutProcessing(false);
      setPayoutSuccess(false);
    }
  };
  const linkClasses = classNames(css.itemLink, {
    [css.bannedUserLink]: isOtherUserBanned,
  });
  return (
    <div className={css.item}>
      <div className={css.avatarRow}>
        <div className={css.itemAvatar}>
          <Avatar user={otherUser} />
        </div>
        {/* <div className={css.rowNotificationDot}>{rowNotificationDot}</div> */}
        <NamedLink
          className={css.itemInfo}
          name={isOrder ? 'OrderDetailsPage' : 'SaleDetailsPage'}
          params={{ id: tx.id.uuid }}
        >
          {/* <div className={css.itemInfo}> */}
          <div className={css.itemUsername}>{otherUserDisplayName}</div>
          {otherUserEmail && !isOrder ? (
            <div className={css.itemEmail}>{otherUserEmail}</div>
          ) : null}
          {/* </div> */}
        </NamedLink>
        {showPayoutButton ? (
          <button
            disabled={payoutProcessing}
            className={css.createQuoteButton}
            onClick={transitionPayoutTx}
          >
            {payoutProcessing ? <IconSpinner /> : payoutSuccess ? 'Processed' : 'Process Payout'}
          </button>
        ) : isOrder || isChatWithAdminListing || isQuoteType ? null : (
          <button
            disabled={disableButton}
            className={css.createQuoteButton}
            onClick={handleCreateQuoteSubmit}
          >
            {creatingQuote ? <IconSpinner /> : ' Create quote '}
          </button>
        )}
      </div>
      <NamedLink
        className={linkClasses}
        name={isOrder ? 'OrderDetailsPage' : 'SaleDetailsPage'}
        params={{ id: tx.id.uuid }}
      >
        <div>
          <hr className={css.horizontalLine} />
          <div className={css.itemOrderInfo}>
            <div className={css.priceText}>{unitPrice}</div>
            <span className={css.listingTitle}>
              {listing?.attributes?.title?.replace('-chat with admin', '')}
            </span>
            <br />
            <div className={css.listingAddress}>{listingLocation}</div>
            {/* <br />
            <FormattedMessage id="InboxPage.quantity" values={{ quantity }} /> */}
          </div>
          {/* <div className={css.itemState}>
          <div className={classNames(css.stateName, stateData.stateClassName)}>
            {stateData.state}
          </div>
          <div
            className={classNames(css.lastTransitionedAt, stateData.lastTransitionedAtClassName)}
            title={lastTransitionedAt.dateAndTime}
          >
            {lastTransitionedAt.date}
          </div>
        </div> */}
        </div>
      </NamedLink>
    </div>
  );
};

InboxItem.propTypes = {
  unitType: propTypes.lineItemUnitType.isRequired,
  type: oneOf(['order', 'sale']).isRequired,
  tx: propTypes.transaction.isRequired,
  intl: intlShape.isRequired,
};

export const InboxPageComponent = (props) => {
  const {
    unitType,
    currentUser,
    fetchInProgress,
    fetchOrdersOrSalesError,
    intl,
    pagination,
    params,
    providerNotificationCount,
    scrollingDisabled,
    transactions,
    history,
  } = props;

  // console.log(666666, transactions);

  const { tab } = params;
  const ensuredCurrentUser = ensureCurrentUser(currentUser);
  // const history = useHistory();

  const validTab = tab === 'orders' || tab === 'sales';
  if (!validTab) {
    return <NotFoundPage />;
  }

  const isOrders = tab === 'orders';
  const dispatch = useDispatch();

  const userType = ensuredCurrentUser?.attributes?.profile?.protectedData?.entrepreneur
    ? 'entrepreneur'
    : 'investor';

  // console.log('user type.......', userType);

  const ordersTitle = intl.formatMessage({ id: 'InboxPage.ordersTitle' });
  const salesTitle = intl.formatMessage({ id: 'InboxPage.salesTitle' });
  const title = isOrders ? ordersTitle : salesTitle;

  const toTxItem = (tx) => {
    const type = isOrders ? 'order' : 'sale';
    // const stateData = txState(intl, tx, type);
    // console.log(2301, stateData, tab);
    // Render InboxItem only if the latest transition of the transaction is handled in the `txState` function.
    return true ? (
      <li key={tx.id.uuid} className={css.listItem}>
        <InboxItem
          unitType={unitType}
          type={type}
          tx={tx}
          intl={intl}
          // stateData={stateData}
          history={history}
          dispatch={dispatch}
          currentUser={currentUser}
        />
      </li>
    ) : null;
  };

  const error = fetchOrdersOrSalesError ? (
    <p className={css.error}>
      <FormattedMessage id="InboxPage.fetchFailed" />
    </p>
  ) : null;

  const noResults =
    !fetchInProgress && transactions.length === 0 && !fetchOrdersOrSalesError ? (
      <li key="noResults" className={css.noResults}>
        <FormattedMessage
          id={
            isOrders && userType && userType === 'investor'
              ? 'InboxPage.noOrdersFound'
              : 'InboxPage.noSalesFound'
          }
        />
      </li>
    ) : null;

  const hasOrderOrSaleTransactions = (tx, isOrdersTab, user) => {
    return isOrdersTab
      ? user.id && tx && tx.length > 0 && tx[0].customer.id.uuid === user.id.uuid
      : user.id && tx && tx.length > 0 && tx[0].provider.id.uuid === user.id.uuid;
  };
  let hasTransactions =
    !fetchInProgress && hasOrderOrSaleTransactions(transactions, isOrders, ensuredCurrentUser);
  const pagingLinks =
    hasTransactions && pagination && pagination.totalPages > 1 ? (
      <PaginationLinks
        className={css.pagination}
        pageName="InboxPage"
        pagePathParams={params}
        pagination={pagination}
      />
    ) : null;

  const providerNotificationBadge =
    providerNotificationCount > 0 ? <NotificationBadge count={providerNotificationCount} /> : null;
  const tabs = [
    !currentUser?.attributes?.profile?.protectedData?.entrepreneur
      ? {
          text: (
            <span>
              <FormattedMessage id="InboxPage.ordersTabTitle" />
            </span>
          ),
          selected: isOrders,
          linkProps: {
            name: 'InboxPage',
            params: { tab: 'orders' },
          },
        }
      : {
          text: (
            <span>
              <FormattedMessage id="InboxPage.salesTabTitle" />
              {providerNotificationBadge}
            </span>
          ),
          selected: isOrders,
          linkProps: {
            name: 'InboxPage',
            params: { tab: 'sales' },
          },
        },
  ];
  const nav = <TabNav rootClassName={css.tabs} tabRootClassName={css.tab} tabs={tabs} />;

  useEffect(() => {}, []);

  return (
    //Default Sharetribe Code
    // <Page title={title} scrollingDisabled={scrollingDisabled}>
    //   <LayoutSideNavigation>
    //     <LayoutWrapperTopbar>
    //       <TopbarContainer
    //         className={css.topbar}
    //         mobileRootClassName={css.mobileTopbar}
    //         desktopClassName={css.desktopTopbar}
    //         currentPage="InboxPage"
    //       />
    //     </LayoutWrapperTopbar>
    //     <LayoutWrapperSideNav className={css.navigation}>
    //       <h1 className={css.title}>
    //         <FormattedMessage id="InboxPage.title" />
    //       </h1>
    //       {nav}
    //     </LayoutWrapperSideNav>
    //     <LayoutWrapperMain>
    //       {error}
    //       <ul className={css.itemList}>
    //         {!fetchInProgress ? (
    //           transactions.map(toTxItem)
    //         ) : (
    //           <li className={css.listItemsLoading}>
    //             <IconSpinner />
    //           </li>
    //         )}
    //         {noResults}
    //       </ul>
    //       {pagingLinks}
    //     </LayoutWrapperMain>
    //     <LayoutWrapperFooter>
    //       <Footer />
    //     </LayoutWrapperFooter>
    //   </LayoutSideNavigation>
    // </Page>
    <Page title={title} scrollingDisabled={scrollingDisabled}>
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer
            className={css.topbar}
            mobileRootClassName={css.mobileTopbar}
            desktopClassName={css.desktopTopbar}
            currentPage="InboxPage"
          />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain>
          <div className={css.inboxContainer}>
            <div className={css.topdv}>
              <button
                className={css.backbtn}
                type="button"
                onClick={() => {
                  history.goBack();
                }}
              >
                <span>
                  <BiArrowBack />{' '}
                </span>
                Back
              </button>
            </div>
            <div className={css.titleContainer}>
              <h1 className={css.title}>
                Inbox
                {/* {userType && userType === 'entrepreneur' ? 'My Sales' : 'My Orders'} */}
              </h1>
              <hr className={css.horizontalLine} />
            </div>

            {error}
            <ul className={css.itemList}>
              {!fetchInProgress ? (
                transactions?.map(toTxItem)
              ) : (
                <li className={css.listItemsLoading}>
                  <IconSpinner />
                </li>
              )}
              {noResults}
            </ul>
            {pagingLinks}
          </div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

InboxPageComponent.defaultProps = {
  unitType: config.lineItemUnitType,
  currentUser: null,
  currentUserHasOrders: null,
  fetchOrdersOrSalesError: null,
  pagination: null,
  providerNotificationCount: 0,
  sendVerificationEmailError: null,
};

InboxPageComponent.propTypes = {
  params: shape({
    tab: string.isRequired,
  }).isRequired,

  unitType: propTypes.lineItemUnitType,
  currentUser: propTypes.currentUser,
  fetchInProgress: bool.isRequired,
  fetchOrdersOrSalesError: propTypes.error,
  pagination: propTypes.pagination,
  providerNotificationCount: number,
  scrollingDisabled: bool.isRequired,
  transactions: arrayOf(propTypes.transaction).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = (state) => {
  const { fetchInProgress, fetchOrdersOrSalesError, pagination, transactionRefs } = state.InboxPage;
  const { currentUser, currentUserNotificationCount: providerNotificationCount } = state.user;
  return {
    currentUser,
    fetchInProgress,
    fetchOrdersOrSalesError,
    pagination,
    providerNotificationCount,
    scrollingDisabled: isScrollingDisabled(state),
    transactions: getMarketplaceEntities(state, transactionRefs),
  };
};

const InboxPage = compose(withRouter, connect(mapStateToProps), injectIntl)(InboxPageComponent);

export default InboxPage;
