import React, { ReactElement, ReactNode, useEffect, useState } from 'react';
import { bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import Select, { components } from 'react-select';
import { listingStat } from '../../../../config/marketplace-custom-config';
// Import configs and util modules
import config from '../../../../config';
import { intlShape, injectIntl, FormattedMessage } from '../../../../util/reactIntl';
import { propTypes } from '../../../../util/types';
import { composeValidators, isOutOfRange, required } from '../../../../util/validators';

// Import shared components
import {
  Form,
  LocationAutocompleteInputField,
  Button,
  FieldCurrencyInput,
  FieldTextInput,
  FieldCheckbox,
} from '../../../../components';
import { ReactComponent as LockIcon } from '../../../../assets/lock.svg';
import { ReactComponent as CheckIcon } from '../../../../assets/check.svg';

// Import modules from this directory
import css from './EditListingListingQuoteDetailsForm.module.css';
import {
  Control,
  DropdownIndicator,
  Menu,
  Option,
  SingleOption,
  ValueContainer,
} from '../../../../components/ReactSelectCustomComponent/ReactSelectCustomComponent';

function identity<Type>(v: Type): Type {
  return v;
}

function EditListingListingQuoteDetailsFormComponent(props: any): ReactElement {
  return (
    <FinalForm
      {...props}
      render={(formRenderProps: any): ReactElement => {
        const {
          form,
          autoFocus,
          className,
          disabled,
          ready,
          handleSubmit,
          intl,
          pristine,
          invalid,
          saveActionMsg,
          updated,
          updateInProgress,
          fetchErrors,
          values,
          touched,
          errors,
          currentUser,
          isQuoteType,
          currentListing,
        } = formRenderProps;
        // console.log(
        //   656,
        //   values,
        //   isQuoteType,
        //   currentListing,
        //   currentListing?.attributes?.publicData?.clientId
        // );
        const [showInfo, setShowInfo] = useState(true);
        // This is a bug fix for Final Form.
        // Without this, React will return a warning:
        //   "Cannot update a component (`ForwardRef(Field)`)
        //   while rendering a different component (`ForwardRef(Field)`)"
        // This seems to happen because validation calls listeneres and
        // that causes state to change inside final-form.
        // https://github.com/final-form/react-final-form/issues/751
        //
        // TODO: it might not be worth the trouble to show these fields as disabled,
        // if this fix causes trouble in future dependency updates.
        const { pauseValidation, resumeValidation } = form;
        pauseValidation(false);
        useEffect(() => resumeValidation(), [values]);

        const isFieldInvalid = (fieldName: string): boolean =>
          touched[fieldName] && !!errors[fieldName];

        const lookingForPlaceholderMessage = intl.formatMessage({
          id: 'EditListingListingDetailsForm.lookingForPlaceholder',
        });
        const lookingForLabel = intl.formatMessage({
          id: 'EditListingListingDetailsForm.lookingForLabel',
        });

        const investmentSizePlaceholderMessage = intl.formatMessage({
          id: 'EditListingListingDetailsForm.investmentSizePlaceholder',
        });
        const investmentSizeLabel = intl.formatMessage({
          id: 'EditListingListingDetailsForm.investmentSizeLabel',
        });

        const investmentOfferPlaceholderMessage = intl.formatMessage({
          id: 'EditListingListingDetailsForm.investmentOfferPlaceholder',
        });
        const investmentOfferLabel = intl.formatMessage({
          id: 'EditListingListingDetailsForm.investmentOfferLabel',
        });

        const emailLabel = intl.formatMessage({ id: 'EditListingListingDetailsForm.emailLabel' });
        const emailPlaceholder = intl.formatMessage({
          id: 'EditListingListingDetailsForm.emailPlaceholder',
        });
        const phoneNumberLabel = intl.formatMessage({
          id: 'EditListingListingDetailsForm.phoneNumberLabel',
        });
        const phoneNumberPlaceholder = intl.formatMessage({
          id: 'EditListingListingDetailsForm.phoneNumberPlaceholder',
        });

        const investmentSizeRequired = required(
          intl.formatMessage({
            id: 'EditListingListingDetailsForm.investmentSizeRequiredMessage',
          })
        );
        const investmentSizeOutOfRange = isOutOfRange(
          intl.formatMessage({
            id: 'EditListingListingDetailsForm.investmentSizeOutOfRange',
          }),
          values?.investmentSizePerDeal?.key || ''
        );
        const investmentOfferRequired = required(
          intl.formatMessage({ id: 'EditListingListingDetailsForm.investmentOfferRequiredMessage' })
        );

        const classes = classNames(css.root, className);
        const submitReady = (updated && pristine) || ready;
        const submitInProgress = updateInProgress;
        const submitDisabled = invalid || disabled || submitInProgress || !values.lookingFor;

        const investmentRangeOpt = [
          { key: '0to100', value: '0to100', label: 'USD0 to USD100K' },
          // { key: '100to500', value: '100to500', label: 'USD100K to USD500K' },
          { key: '100to1000', value: '100to1000', label: 'USD100K to USD1000K' },

          { key: '1mil', value: '1mil', label: 'Above USD 1mil' },
        ];
        const customControl =
          (className?: string | null | undefined): ((props: any) => ReactElement) =>
          ({ children, ...rest }) => {
            return (
              <components.Control
                {...rest}
                // className={classNames(css.optionControl, className, {
                //   [css.emptyOptionControl]: !rest.hasValue,
                //   [css.focusedControl]: rest.isFocused,
                // })}
                className={classNames(css.rngeselect, {
                  [css.focusedControl]: rest.isFocused,
                })}
              >
                {children}
              </components.Control>
            );
          };
        return (
          <Form className={classes} onSubmit={handleSubmit}>
            <div className={css.spanDiv}>
              <span className={css.label}>{phoneNumberLabel}</span>
              <span>{values.phoneNumber}</span>
            </div>
            <div className={css.numberPlaceholder}>
              <LockIcon />
              {phoneNumberPlaceholder}
            </div>
            <div className={css.spanDiv}>
              <span className={css.label}>{emailLabel}</span>
              <span>{values.email}</span>
            </div>
            <div className={css.emailPlaceholder}>
              <span className={css.message}>
                <LockIcon />
                {emailPlaceholder}
              </span>
              <span
                className={classNames(css.status, {
                  [css.unverified]: !currentUser.attributes.emailVerified,
                })}
              >
                <CheckIcon />
                {currentUser.attributes.emailVerified ? 'verified' : 'unverified'}
              </span>
            </div>

            <div className={css.selectDiv}>
              <span className={css.selectLabel}>{lookingForLabel}</span>
              <Select
                name="lookingFor"
                id="lookingFor"
                options={listingStat}
                placeholder={lookingForPlaceholderMessage}
                components={{
                  Option: SingleOption(css.singleOption),
                  Control: Control(css.Control),
                  IndicatorSeparator: null,
                  DropdownIndicator: DropdownIndicator(),
                  Menu: Menu(),
                  ValueContainer: ValueContainer(css.ValueContainer),
                }}
                onChange={(e) => {
                  form.change('lookingFor', e ?? null);
                }}
                defaultValue={values.lookingFor}
              />
            </div>
            <div className={css.textareaDiv}>
              <label>Investment Details*</label>
              <Select
                id="investmentSizePerDeal"
                name="investmentSizePerDeal"
                options={investmentRangeOpt}
                onChange={(e) => {
                  if (e === null) {
                    form.change('investmentSizePerDeal', null);
                  } else {
                    form.change('investmentSizePerDeal', e);
                  }
                }}
                placeholder="Investment Range"
                value={values.investmentSizePerDeal}
                isSearchable={false}
                defaultMenuIsOpen={false}
                className={css.invrng}
                components={{
                  Control: customControl(),
                  Menu: Menu(),
                  ValueContainer: ValueContainer(css.invValCon),
                  IndicatorSeparator: undefined,
                  Option: SingleOption(css.singleOption),
                  DropdownIndicator: DropdownIndicator(),
                }}
              />
              <span>Select one from the above options</span>
              <FieldTextInput
                name="investmentSize"
                id="investmentSize"
                // label={investmentSizeLabel}
                type="text"
                onChange={(e: any) => {
                  const value = e.target.value;
                  // console.log(212, value);
                  const regex = /^[0-9]+$/;
                  if (value.match(regex)) {
                    form.change('investmentSize', value);
                  }

                  if (value === '') {
                    form.change('investmentSize', '');
                  }
                }}
                placeholder={'Investment Size'}
                // className={classNames(css.input, {
                //   [css.invalidInput]: isFieldInvalid('investmentSize'),
                // })}
                currencyConfig={config.currencyConfig}
                validate={composeValidators(investmentSizeRequired, investmentSizeOutOfRange)}
                // Whatever parameters are being used to calculate
                // the validation function need to be combined in such
                // a way that, when they change, this key prop
                // changes, thus reregistering this field (and its
                // validation function) with Final Form.
                // See example: https://codesandbox.io/s/changing-field-level-validators-zc8ei
                className={classNames({
                  [css.requiredInput]:
                    showInfo &&
                    currentListing?.attributes?.publicData?.clientId &&
                    !values?.investmentSize,
                  // isQuoteType && !values?.investmentSize && touched?.investmentSize === false,
                })}
                onFocus={() => {
                  setShowInfo(false);
                }}
              />
              {
                // isQuoteType && !values?.investmentSize && touched?.investmentSize === false &&
                showInfo &&
                currentListing?.attributes?.publicData?.clientId &&
                !values?.investmentSize ? (
                  <p className={css.ISinfo}>Investment size is required</p>
                ) : null
              }
              <span>Pricing in numbers</span>
              <FieldTextInput
                // className={classNames(css.input, {
                //   [css.invalidInput]: isFieldInvalid('investmentOffer'),
                // })}
                type="textarea"
                name="investmentOffer"
                id="investmentOffer"
                // label={investmentOfferLabel}
                placeholder={'Investment Offer Description'}
                validate={investmentOfferRequired}
              />
              <span>
                Details of your offer, such as percentage of the sale, level of investor
                involvement, etc.
              </span>
            </div>

            <Button
              className={css.submitButton}
              type="submit"
              inProgress={submitInProgress}
              disabled={submitDisabled}
              ready={submitReady}
            >
              {saveActionMsg}
            </Button>
          </Form>
        );
      }}
    />
  );
}

EditListingListingQuoteDetailsFormComponent.defaultProps = {
  selectedPlace: null,
  fetchErrors: null,
};

EditListingListingQuoteDetailsFormComponent.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  selectedPlace: propTypes.place,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
};

export default compose(injectIntl)(EditListingListingQuoteDetailsFormComponent);

{
  /* <FieldCheckbox
            id="pickup"
            className={css.deliveryCheckbox}
            name="deliveryOptions"
            label={pickupLabel}
            value="pickup"
          />
          <div className={pickupClasses}>
            {errorMessage}
            {errorMessageShowListing}

            <FieldTextInput
              className={css.input}
              type="textarea"
              name="building"
              id="building"
              label={buildingMessage}
              placeholder={buildingPlaceholderMessage}
              disabled={!pickupEnabled}
            />
          </div>
          <FieldCheckbox
            id="shipping"
            className={css.deliveryCheckbox}
            name="deliveryOptions"
            label={shippingLabel}
            value="shipping"
          />

          <div className={shippingClasses}>
            <FieldCurrencyInput
              id="shippingPriceInSubunitsOneItem"
              name="shippingPriceInSubunitsOneItem"
              className={css.input}
              label={intl.formatMessage({
                id: 'EditListingListingDetailsForm.shippingOneItemLabel',
              })}
              placeholder={intl.formatMessage({
                id: 'EditListingListingDetailsForm.shippingOneItemPlaceholder',
              })}
              currencyConfig={config.currencyConfig}
              disabled={!shippingEnabled}
              validate={
                shippingEnabled
                  ? required(
                      intl.formatMessage({
                        id: 'EditListingListingDetailsForm.shippingOneItemRequired',
                      })
                    )
                  : null
              }
              hideErrorMessage={!shippingEnabled}
              // Whatever parameters are being used to calculate
              // the validation function need to be combined in such
              // a way that, when they change, this key prop
              // changes, thus reregistering this field (and its
              // validation function) with Final Form.
              // See example: https://codesandbox.io/s/changing-field-level-validators-zc8ei
              key={shippingEnabled ? 'oneItemValidation' : 'noOneItemValidation'}
            />

            <FieldCurrencyInput
              id="shippingPriceInSubunitsAdditionalItems"
              name="shippingPriceInSubunitsAdditionalItems"
              className={css.input}
              label={intl.formatMessage({
                id: 'EditListingListingDetailsForm.shippingAdditionalItemsLabel',
              })}
              placeholder={intl.formatMessage({
                id: 'EditListingListingDetailsForm.shippingAdditionalItemsPlaceholder',
              })}
              currencyConfig={config.currencyConfig}
              disabled={!shippingEnabled}
              validate={
                shippingEnabled
                  ? required(
                      intl.formatMessage({
                        id: 'EditListingListingDetailsForm.shippingAdditionalItemsRequired',
                      })
                    )
                  : null
              }
              hideErrorMessage={!shippingEnabled}
              // Whatever parameters are being used to calculate
              // the validation function need to be combined in such
              // a way that, when they change, this key prop
              // changes, thus reregistering this field (and its
              // validation function) with Final Form.
              // See example: https://codesandbox.io/s/changing-field-level-validators-zc8ei
              key={shippingEnabled ? 'additionalItemsValidation' : 'noAdditionalItemsValidation'}
            />
          </div> */
}
