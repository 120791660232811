import React, { useState, useRef, useEffect } from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import arrayMutators from 'final-form-arrays';
import { types as sdkTypes } from '../../../../../src/util/sdkLoader';
import axios from 'axios';
import { Country } from 'country-state-city';
// Import configs and util modules
import config from '../../../../config';
import { intlShape, injectIntl, FormattedMessage } from '../../../../util/reactIntl';
import { propTypes } from '../../../../util/types';
import { required, composeValidators, emailFormatValid } from '../../../../util/validators';
import Select, { components } from 'react-select';
// Import shared components
import { Form, Button, FieldTextInput, InlineTextButton, Modal } from '../../../../components';
// Import modules from this directory
import css from './EditListingQuoteClientForm.module.css';
import { MdLocationOn } from 'react-icons/md';
import SearchMap from '../../../SearchPage/SearchMap/SearchMap';
import { locationBounds } from '../../../../components/LocationAutocompleteInput/GeocoderMapbox';

const TITLE_MAX_LENGTH = 60;
const { LatLng } = sdkTypes;
const key = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
const countries = Country.getAllCountries();
const defaultCountryOptions = countries.map((c) => ({ value: c.isoCode, label: c.name }));
const EditListingQuoteClientFormComponent = (props) => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    render={(formRenderProps) => {
      const {
        autoFocus,
        className,
        disabled,
        ready,
        handleSubmit,
        intl,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        currentUser,
        initialValues,
        values,
        form,
        touched,
        errors,
        location = {},
        listing,
        onManageDisableScrolling,
      } = formRenderProps;

      const { updateListingError, createListingDraftError, showListingsError } = fetchErrors || {};
      const isFieldInvalid = (fieldName) => touched[fieldName] && !!errors[fieldName];

      const errorMessageUpdateListing = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingQuoteDetailsForm.updateFailed" />
        </p>
      ) : null;

      // This error happens only on first tab (of EditListingWizard)
      const errorMessageCreateListingDraft = createListingDraftError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingQuoteDetailsForm.createListingDraftError" />
        </p>
      ) : null;

      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingQuoteDetailsForm.showListingFailed" />
        </p>
      ) : null;

      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;
      const emailRequiredMessage = intl.formatMessage({
        id: 'SignupForm.emailRequired',
      });
      const emailRequired = required(emailRequiredMessage);
      const emailInvalidMessage = intl.formatMessage({
        id: 'SignupForm.emailInvalid',
      });
      const emailValid = emailFormatValid(emailInvalidMessage);
      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {errorMessageCreateListingDraft}
          {errorMessageUpdateListing}
          {errorMessageShowListing}
          <FieldTextInput
            id="clientId"
            name="clientId"
            className={classNames(css.title, {
              [css.invalidInput]: isFieldInvalid('title'),
            })}
            type="text"
            label={'Client Email'}
            placeholder={'Enter client email id'}
            validate={composeValidators(emailRequired, emailValid)}
            autoFocus={autoFocus}
          />

          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>
        </Form>
      );
    }}
  />
);

EditListingQuoteClientFormComponent.defaultProps = {
  className: null,
  fetchErrors: null,
  filterConfig: config.custom.filters,
};

EditListingQuoteClientFormComponent.propTypes = {
  className: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    createListingDraftError: propTypes.error,
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  filterConfig: propTypes.filterConfig,
};

export default compose(injectIntl)(EditListingQuoteClientFormComponent);
