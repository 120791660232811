/*
 * Marketplace specific configuration.
 *
 * Every filter needs to have following keys:
 * - id:     Unique id of the filter.
 * - label:  The default label of the filter.
 * - type:   String that represents one of the existing filter components:
 *           BookingDateRangeFilter, KeywordFilter, PriceFilter,
 *           SelectSingleFilter, SelectMultipleFilter.
 * - group:  Is this 'primary' or 'secondary' filter?
 *           Primary filters are visible on desktop layout by default.
 *           Secondary filters are behind "More filters" button.
 *           Read more from src/containers/SearchPage/README.md
 * - queryParamNames: Describes parameters to be used with queries
 *                    (e.g. 'price' or 'pub_amenities'). Most of these are
 *                    the same between webapp URLs and API query params.
 *                    You can't change 'dates', 'price', or 'keywords'
 *                    since those filters are fixed to a specific attribute.
 * - config: Extra configuration that the filter component needs.
 *
 * Note 1: Labels could be tied to translation file
 *         by importing FormattedMessage:
 *         <FormattedMessage id="some.translation.key.here" />
 *
 * Note 2: If you need to add new custom filter components,
 *         you need to take those into use in:
 *         src/containers/SearchPage/FilterComponent.js
 *
 * Note 3: If you just want to create more enum filters
 *         (i.e. SelectSingleFilter, SelectMultipleFilter),
 *         you can just add more configurations with those filter types
 *         and tie them with correct extended data key
 *         (i.e. pub_<key> or meta_<key>).
 */

export const filters = [
  {
    id: 'category',
    label: 'Category',
    type: 'SelectSingleFilter',
    group: 'primary',
    queryParamNames: ['pub_category'],
    config: {
      // Schema type is enum for SelectSingleFilter
      schemaType: 'enum',

      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'men', label: 'Men' },
        { key: 'women', label: 'Women' },
        { key: 'kids', label: 'Kids' },
      ],
    },
  },
  {
    id: 'size',
    label: 'Size (US)',
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_size'],
    config: {
      // Schema type options: 'enum', 'multi-enum'
      // Both types can work so that user selects multiple values when filtering search results.
      // With "enum" the functionality will be OR-semantics (Nike OR Adidas OR Salomon)
      // With "multi-enum" it's possible to use both AND and OR semantics with searchMode config.
      schemaType: 'enum',

      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: '4', label: '4' },
        { key: '5', label: '5' },
        { key: '6', label: '6' },
        { key: '7', label: '7' },
        { key: '8', label: '8' },
        { key: '9', label: '9' },
        { key: '10', label: '10' },
        { key: '11', label: '11' },
        { key: '12', label: '12' },
      ],
    },
  },
  {
    id: 'brand',
    label: 'Brand',
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_brand'],
    config: {
      // Schema type options: 'enum', 'multi-enum'
      // Both types can work so that user selects multiple values when filtering search results.
      // With "enum" the functionality will be OR-semantics (Nike OR Adidas OR Salomon)
      // With "multi-enum" it's possible to use both AND and OR semantics with searchMode config.
      schemaType: 'enum',

      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'adidas', label: 'Adidas' },
        { key: 'air_jordan', label: 'Air Jordan' },
        { key: 'converse', label: 'Converse' },
        { key: 'new_balance', label: 'New Balance' },
        { key: 'nike', label: 'Nike' },
        { key: 'puma', label: 'Puma' },
        { key: 'ultraboost', label: 'Ultraboost' },
        { key: 'vans', label: 'Vans' },
        { key: 'yeezy', label: 'Yeezy' },
        { key: 'other', label: 'Other' },
      ],
    },
  },
  {
    id: 'price',
    label: 'Price',
    type: 'PriceFilter',
    group: 'primary',
    // Note: PriceFilter is fixed filter,
    // you can't change "queryParamNames: ['price'],"
    queryParamNames: ['price'],
    // Price filter configuration
    // Note: unlike most prices this is not handled in subunits
    config: {
      min: 0,
      max: 10000000,
      step: 10000,
    },
  },
  {
    id: 'businessEstDateSearch',
    label: 'Date of Establishment',
    type: 'DateFilter',
    group: 'primary',
    // Note: PriceFilter is fixed filter,
    // you can't change "queryParamNames: ['price'],"
    queryParamNames: ['pub_businessEstDateSearch'],
    // Price filter configuration
    // Note: unlike most prices this is not handled in subunits
    config: {
      min: 0,
      max: 9999999999,
      step: 10000,
    },
  },
  {
    id: 'keyword',
    label: 'Keyword',
    type: 'KeywordFilter',
    group: 'primary',
    // Note: KeywordFilter is fixed filter,
    // you can't change "queryParamNames: ['keywords'],"
    queryParamNames: ['keywords'],
    // NOTE: If you are ordering search results by distance
    // the keyword search can't be used at the same time.
    // You can turn on/off ordering by distance from config.js file.
    config: {},
  },
  {
    id: 'location',
    label: 'Location',
    type: 'LocationFilter',
    group: 'primary',
    // Note: KeywordFilter is fixed filter,
    // you can't change "queryParamNames: ['keywords'],"
    queryParamNames: ['origin', 'address', 'bounds'],
    // NOTE: If you are ordering search results by distance
    // the keyword search can't be used at the same time.
    // You can turn on/off ordering by distance from config.js file.
    config: {},
  },
  {
    id: 'profileIdentityDetail',
    label: 'profileIdentityDetail',
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_brand'],
    config: {
      // Schema type options: 'enum', 'multi-enum'
      // Both types can work so that user selects multiple values when filtering search results.
      // With "enum" the functionality will be OR-semantics (Nike OR Adidas OR Salomon)
      // With "multi-enum" it's possible to use both AND and OR semantics with searchMode config.
      schemaType: 'enum',

      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'individual', label: 'Individual' },
        { key: 'company', label: 'Company' },
        { key: 'lender', label: 'Lender' },
        { key: 'advisor', label: 'Advisor' },
        { key: 'funds', label: 'Funds Provider' },
        { key: 'interestedIn', label: 'Interested in' },
        { key: 'buying', label: 'Buying' },
        { key: 'investing', label: 'Investing' },
      ],
    },
  },

  {
    id: 'interestDetail',
    label: 'interestDetail',
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_brand'],
    config: {
      // Schema type options: 'enum', 'multi-enum'
      // Both types can work so that user selects multiple values when filtering search results.
      // With "enum" the functionality will be OR-semantics (Nike OR Adidas OR Salomon)
      // With "multi-enum" it's possible to use both AND and OR semantics with searchMode config.
      schemaType: 'enum',

      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'buying', label: 'Buying' },
        { key: 'investing', label: 'Investing' },
        { key: 'lending', label: 'Lending' },
        { key: 'others', label: 'Others' },
      ],
    },
  },
  {
    id: 'categoryDetail',
    label: 'categoryDetail',
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_category'],
    config: {
      // Schema type options: 'enum', 'multi-enum'
      // Both types can work so that user selects multiple values when filtering search results.
      // With "enum" the functionality will be OR-semantics (Nike OR Adidas OR Salomon)
      // With "multi-enum" it's possible to use both AND and OR semantics with searchMode config.
      schemaType: 'enum',

      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'a', label: 'A' },
        { key: 'b', label: 'B' },
        { key: 'c', label: 'C' },
        { key: 'd', label: 'D' },
      ],
    },
  },
  {
    id: 'verificableData',
    config: {
      options: [
        {
          label: 'Type of Business',
          value: 'businessType',
          key: 'businessType',
        },
        {
          label: 'Date of business Establishment',
          value: 'businessEstDate',
          key: 'businessEstDate',
        },
        {
          label: 'Location',
          value: 'location',
          key: 'location',
        },
        {
          label: 'No. Employees',
          value: 'businessEmpCount',
          key: 'businessEmpCount',
        },
        {
          label: 'Average Monthly Sales / Revenue',
          value: 'avgMonthlySale',
          key: 'avgMonthlySale',
        },
        {
          label: 'Average Monthly Costs',
          value: 'avgMonthlyCost',
          key: 'avgMonthlyCost',
        },
        {
          label: 'Monthly Operating Cashflow',
          value: 'monthlyOperatingCash',
          key: 'monthlyOperatingCash',
        },
        {
          label: 'Working Capital',
          value: 'workingCapital',
          key: 'workingCapital',
        },
        {
          label: 'Monthly EBITDA',
          value: 'monthlyEbitda',
          key: 'monthlyEbitda',
        },
        {
          label: 'Monthly Net Income',
          value: 'monthlyNetIncome',
          key: 'monthlyNetIncome',
        },
        {
          label: 'Valuable Asset Description',
          value: 'valuableAssetDesc',
          key: 'valuableAssetDesc',
        },
        {
          label: 'Total Liability Amount and discerption',
          value: 'liabilityAmnt',
          key: 'liabilityAmnt',
        },
        {
          label: 'Book value',
          value: 'bookValue',
          key: 'bookValue',
        },
      ],
    },
  },
  {
    id: 'businessSector',
    label: 'Business Sector',
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_businessSectorSearch'],
    config: {
      // Schema type options: 'enum', 'multi-enum'
      // Both types can work so that user selects multiple values when filtering search results.
      // With "enum" the functionality will be OR-semantics (Nike OR Adidas OR Salomon)
      // With "multi-enum" it's possible to use both AND and OR semantics with searchMode config.
      schemaType: 'enum',

      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        {
          label: 'Accommodation and Food Services',
          value: 'foodAccommodation',
          key: 'foodAccommodation',
        },
        {
          label: 'Administrative and Support Services',
          value: 'adminSupport',
          key: 'adminSupport',
        },
        {
          label: 'Agriculture, Forestry, Fishing and Hunting',
          value: 'agriculture',
          key: 'agriculture',
        },
        { label: 'Air Transportation', value: 'airTransport', key: 'airTransport' },
        { label: 'Health Care Services', value: 'healthCare', key: 'healthCare' },
        {
          label: 'Amusement and Recreation Industries',
          value: 'amusementRecreation',
          key: 'amusementRecreation',
        },
        { label: 'Animal Production', value: 'animalProduction', key: 'animalProduction' },
        { label: 'Apparel Manufacturing', value: 'apparelManufacture', key: 'apparelManufacture' },
        {
          label: 'Arts, Entertainment, and Recreation',
          value: 'artsEntertainmentRecreation',
          key: 'artsEntertainmentRecreation',
        },
        { label: 'Beverage and Tobacco Product', value: 'beverageTobacco', key: 'beverageTobacco' },
        { label: 'Broadcasting', value: 'broadcasting', key: 'broadcasting' },
        {
          label: 'Building Material and Garden Equipment',
          value: 'buildingGarden',
          key: 'buildingGarden',
        },
        {
          label: 'Chemical Manufacturing',
          value: 'chemicalManufacture',
          key: 'chemicalManufacture',
        },
        { label: 'Clothing and Clothing Accessories Stores', value: 'clothing', key: 'clothing' },
        {
          label: 'Computer and Electronic Product',
          value: 'electronicProduct',
          key: 'electronicProduct',
        },
        { label: 'Construction', value: 'construction', key: 'construction' },
        {
          label: 'Couriers and Messengers',
          value: 'couriersMessengers',
          key: 'couriersMessengers',
        },
        { label: 'Credit Intermediation', value: 'creditIntermediate', key: 'creditIntermediate' },
        { label: 'Crop Production', value: 'cropProduct', key: 'cropProduct' },
        { label: 'Data Processing, Hosting', value: 'dataProcessing', key: 'dataProcessing' },
        { label: 'Education', value: 'education', key: 'education' },
        { label: 'Health Services', value: 'healthService', key: 'healthService' },
        {
          label: 'Electrical Equipment, Appliance',
          value: 'electricalAppliance',
          key: 'electricalAppliance',
        },
        {
          label: 'Electronics and Appliance Stores',
          value: 'electronicsAppliance',
          key: 'electronicsAppliance',
        },
        {
          label: 'Fabricated Metal Product Manufacturing',
          value: 'fabricatedMetalProduct',
          key: 'fabricatedMetalProduct',
        },
        { label: 'Finance and Insurance', value: 'financeInsurance', key: 'financeInsurance' },
        { label: 'Food Manufacturing', value: 'foodManufacture', key: 'foodManufacture' },
        { label: 'Food Services and Drinking Places', value: 'foodDrinking', key: 'foodDrinking' },
        { label: 'Food and Beverage Stores', value: 'foodBeverage', key: 'foodBeverage' },
        {
          label: 'Furniture and Home Furnishings Stores',
          value: 'furnitureFurnishing',
          key: 'furnitureFurnishing',
        },
        { label: 'Manufacturing', value: 'manufacturing', key: 'manufacturing' },
        { label: 'Gasoline Stations', value: 'gasoline', key: 'gasoline' },
        {
          label: 'General Merchandise Stores',
          value: 'generalMerchandise',
          key: 'generalMerchandise',
        },
        { label: 'Goods-Producing Industries', value: 'goodsProducing', key: 'goodsProducing' },
        {
          label: 'Health and Personal Care Stores',
          value: 'healthPersonalCare',
          key: 'healthPersonalCare',
        },
        { label: 'Heavy and Civil Engineering', value: 'heavyCivil', key: 'heavyCivil' },
        { label: 'Hospitals', value: 'hospitals', key: 'hospitals' },
        {
          label: 'Internet Publishing and Broadcasting',
          value: 'internetPublishing',
          key: 'internetPublishing',
        },
        {
          label: 'Leisure and Hospitality',
          value: 'leisureHospitality',
          key: 'leisureHospitality',
        },
        {
          label: 'Lessors of Nonfinancial Intangible Machinery Merchant Wholesalers',
          value: 'machineryMerchant',
          key: 'machineryMerchant',
        },
        {
          label: 'Mining, Quarrying, and Oil and Gas',
          value: 'miningQuarryingOilGas',
          key: 'miningQuarryingOilGas',
        },
        { label: 'Store Retailers', value: 'storeRetailers', key: 'storeRetailers' },
        {
          label: 'Motion Picture and Sound Recording',
          value: 'motionSoundPicture',
          key: 'motionSoundPicture',
        },
        {
          label: 'Motor Vehicle and Parts Dealers',
          value: 'motorVehicleParts',
          key: 'motorVehicleParts',
        },
        {
          label: 'Performing Arts, Spectator Sports',
          value: 'performingArts',
          key: 'performingArts',
        },
        {
          label: 'Real Estate and Rental and Leasing',
          value: 'realEstateRentalLeasing',
          key: 'realEstateRentalLeasing',
        },
        { label: 'Repair and Maintenance', value: 'repairMaintenance', key: 'repairMaintenance' },
        { label: 'Retail', value: 'retail', key: 'retail' },
        {
          label: 'Sporting Goods, Hobby, Book, and Music Stores',
          value: 'sportingGoodsMusic',
          key: 'sportingGoodsMusic',
        },
        { label: 'Telecommunications', value: 'telecommunications', key: 'telecommunications' },
        { label: 'Textile Mills', value: 'textileMills', key: 'textileMills' },
        {
          label: 'Trade, Transportation, and Utilities',
          value: 'tradeTransportUtility',
          key: 'tradeTransportUtility',
        },
        {
          label: 'Warehousing and Storage',
          value: 'warehousingStorage',
          key: 'warehousingStorage',
        },
        {
          label: 'Waste Management and Remediation',
          value: 'wasteManagementRemediation',
          key: 'wasteManagementRemediation',
        },
        { label: 'Water Transportation', value: 'waterTransport', key: 'waterTransport' },
        { label: 'Wholesale Electronic', value: 'wholesaleElectronic', key: 'wholesaleElectronic' },
        { label: 'Others', value: 'others', key: 'others' },
      ],
    },
  },
  {
    id: 'businessTypes',
    label: 'Business Types',
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_businessTypeSearch'],
    config: {
      // Schema type options: 'enum', 'multi-enum'
      // Both types can work so that user selects multiple values when filtering search results.
      // With "enum" the functionality will be OR-semantics (Nike OR Adidas OR Salomon)
      // With "multi-enum" it's possible to use both AND and OR semantics with searchMode config.
      schemaType: 'enum',

      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { label: 'Sole proprietorship', value: 'soleProprietorship', key: 'soleProprietorship' },
        { label: 'Partnership', value: 'partnership', key: 'partnership' },
        { label: 'Limited liability company', value: 'limitedLiability', key: 'limitedLiability' },
        { label: 'Corporation', value: 'corporation', key: 'corporation' },
        { label: 'Cooperative', value: 'cooperative', key: 'cooperative' },
        { label: 'Other', value: 'other', key: 'other' },
      ],
    },
  },

  // Here is an example of multi-enum search filter.
  //
  // {
  //   id: 'amenities',
  //   label: 'Amenities',
  //   type: 'SelectMultipleFilter',
  //   group: 'secondary',
  //   queryParamNames: ['pub_amenities'],
  //   config: {
  //     // Schema type options: 'enum', 'multi-enum'
  //     // Both types can work so that user selects multiple values when filtering search results.
  //     // With "enum" the functionality will be OR-semantics (Nike OR Adidas OR Salomon)
  //     // With "multi-enum" it's possible to use both AND and OR semantics with searchMode config.
  //     schemaType: 'multi-enum',

  //     // Optional modes: 'has_all', 'has_any'
  //     // Note: this is relevant only for schema type 'multi-enum'
  //     // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
  //     searchMode: 'has_all',

  //     // "key" is the option you see in Flex Console.
  //     // "label" is set here for this web app's UI only.
  //     // Note: label is not added through the translation files
  //     // to make filter customizations a bit easier.
  //     options: [
  //       { key: 'towels', label: 'Towels' },
  //       { key: 'bathroom', label: 'Bathroom' },
  //       { key: 'swimming_pool', label: 'Swimming pool' },
  //       { key: 'barbeque', label: 'Barbeque' },
  //     ],
  //   },
  // },
];

export const sortConfig = {
  // Enable/disable the sorting control in the SearchPage
  active: true,

  // Note: queryParamName 'sort' is fixed,
  // you can't change it since Flex API expects it to be named as 'sort'
  queryParamName: 'sort',

  // Internal key for the relevance option, see notes below.
  relevanceKey: 'relevance',

  // Relevance key is used with keywords filter.
  // Keywords filter also sorts results according to relevance.
  relevanceFilter: 'keywords',

  // Keyword filter is sorting the results by relevance.
  // If keyword filter is active, one might want to disable other sorting options
  // by adding 'keyword' to this list.
  conflictingFilters: [],

  options: [
    { key: 'createdAt', label: 'Newest' },
    { key: '-createdAt', label: 'Oldest' },
    { key: '-price', label: 'Lowest price' },
    { key: 'price', label: 'Highest price' },

    // The relevance is only used for keyword search, but the
    // parameter isn't sent to the Marketplace API. The key is purely
    // for handling the internal state of the sorting dropdown.
    { key: 'relevance', label: 'Relevance', longLabel: 'Relevance (Keyword search)' },
  ],
};

export const listing = {
  // These should be listing details from public data with schema type: enum
  // SectionDetailsMaybe component shows these on listing page.
  enumFieldDetails: ['size', 'brand', 'category'],
};
export const countryOptions = [
  {
    name: 'Afghanistan',
    iso2: 'AF',
    iso3: 'AFG',
  },
  {
    name: 'Albania',
    iso2: 'AL',
    iso3: 'ALB',
  },
  {
    name: 'Algeria',
    iso2: 'DZ',
    iso3: 'DZA',
  },
  {
    name: 'Andorra',
    iso2: 'AD',
    iso3: 'AND',
  },
  {
    name: 'Angola',
    iso2: 'AO',
    iso3: 'AGO',
  },
  {
    name: 'Anguilla',
    iso2: 'AI',
    iso3: 'AIA',
  },
  {
    name: 'Antigua and Barbuda',
    iso2: 'AG',
    iso3: 'ATG',
  },
  {
    name: 'Argentina',
    iso2: 'AR',
    iso3: 'ARG',
  },
  {
    name: 'Armenia',
    iso2: 'AM',
    iso3: 'ARM',
  },
  {
    name: 'Aruba',
    iso2: 'AW',
    iso3: 'ABW',
  },
  {
    name: 'Australia',
    iso2: 'AU',
    iso3: 'AUS',
  },
  {
    name: 'Austria',
    iso2: 'AT',
    iso3: 'AUT',
  },
  {
    name: 'Azerbaijan',
    iso2: 'AZ',
    iso3: 'AZE',
  },
  {
    name: 'Bahamas',
    iso2: 'BS',
    iso3: 'BHS',
  },
  {
    name: 'Bahrain',
    iso2: 'BH',
    iso3: 'BHR',
  },
  {
    name: 'Bangladesh',
    iso2: 'BD',
    iso3: 'BGD',
  },
  {
    name: 'Barbados',
    iso2: 'BB',
    iso3: 'BRB',
  },
  {
    name: 'Belarus',
    iso2: 'BY',
    iso3: 'BLR',
  },
  {
    name: 'Belgium',
    iso2: 'BE',
    iso3: 'BEL',
  },
  {
    name: 'Belize',
    iso2: 'BZ',
    iso3: 'BLZ',
  },
  {
    name: 'Benin',
    iso2: 'BJ',
    iso3: 'BEN',
  },
  {
    name: 'Bermuda',
    iso2: 'BM',
    iso3: 'BMU',
  },
  {
    name: 'Bhutan',
    iso2: 'BT',
    iso3: 'BTN',
  },
  {
    name: 'Bosnia and Herzegovina',
    iso2: 'BA',
    iso3: 'BIH',
  },
  {
    name: 'Botswana',
    iso2: 'BW',
    iso3: 'BWA',
  },
  {
    name: 'Bouvet Island',
    iso2: 'BV',
    iso3: 'BVT',
  },
  {
    name: 'Brazil',
    iso2: 'BR',
    iso3: 'BRA',
  },
  {
    name: 'British Indian Ocean Territory',
    iso2: 'IO',
    iso3: 'IOT',
  },
  {
    name: 'Brunei',
    iso2: 'BN',
    iso3: 'BRN',
  },
  {
    name: 'Bulgaria',
    iso2: 'BG',
    iso3: 'BGR',
  },
  {
    name: 'Burkina Faso',
    iso2: 'BF',
    iso3: 'BFA',
  },
  {
    name: 'Burundi',
    iso2: 'BI',
    iso3: 'BDI',
  },
  {
    name: 'Cambodia',
    iso2: 'KH',
    iso3: 'KHM',
  },
  {
    name: 'Cameroon',
    iso2: 'CM',
    iso3: 'CMR',
  },
  {
    name: 'Canada',
    iso2: 'CA',
    iso3: 'CAN',
  },
  {
    name: 'Cape Verde',
    iso2: 'CV',
    iso3: 'CPV',
  },
  {
    name: 'Cayman Islands',
    iso2: 'KY',
    iso3: 'CYM',
  },
  {
    name: 'Central African Republic',
    iso2: 'CF',
    iso3: 'CAF',
  },
  {
    name: 'Chad',
    iso2: 'TD',
    iso3: 'TCD',
  },
  {
    name: 'Chile',
    iso2: 'CL',
    iso3: 'CHL',
  },
  {
    name: 'China',
    iso2: 'CN',
    iso3: 'CHN',
  },
  {
    name: 'Christmas Island',
    iso2: 'CX',
    iso3: 'CXR',
  },
  {
    name: 'Cocos (Keeling) Islands',
    iso2: 'CC',
    iso3: 'CCK',
  },
  {
    name: 'Colombia',
    iso2: 'CO',
    iso3: 'COL',
  },
  {
    name: 'Comoros',
    iso2: 'KM',
    iso3: 'COM',
  },
  {
    name: 'Congo',
    iso2: 'CG',
    iso3: 'COG',
  },
  {
    name: 'Cook Islands',
    iso2: 'CK',
    iso3: 'COK',
  },
  {
    name: 'Costa Rica',
    iso2: 'CR',
    iso3: 'CRI',
  },
  {
    name: 'Croatia',
    iso2: 'HR',
    iso3: 'HRV',
  },
  {
    name: 'Cuba',
    iso2: 'CU',
    iso3: 'CUB',
  },
  {
    name: 'Cyprus',
    iso2: 'CY',
    iso3: 'CYP',
  },
  {
    name: 'Czech Republic',
    iso2: 'CZ',
    iso3: 'CZE',
  },
  {
    name: 'Denmark',
    iso2: 'DK',
    iso3: 'DNK',
  },
  {
    name: 'Djibouti',
    iso2: 'DJ',
    iso3: 'DJI',
  },
  {
    name: 'Dominica',
    iso2: 'DM',
    iso3: 'DMA',
  },
  {
    name: 'Dominican Republic',
    iso2: 'DO',
    iso3: 'DOM',
  },
  {
    name: 'Ecuador',
    iso2: 'EC',
    iso3: 'ECU',
  },
  {
    name: 'Egypt',
    iso2: 'EG',
    iso3: 'EGY',
  },
  {
    name: 'El Salvador',
    iso2: 'SV',
    iso3: 'SLV',
  },
  {
    name: 'Equatorial Guinea',
    iso2: 'GQ',
    iso3: 'GNQ',
  },
  {
    name: 'Eritrea',
    iso2: 'ER',
    iso3: 'ERI',
  },
  {
    name: 'Estonia',
    iso2: 'EE',
    iso3: 'EST',
  },
  {
    name: 'Ethiopia',
    iso2: 'ET',
    iso3: 'ETH',
  },
  {
    name: 'Falkland Islands',
    iso2: 'FK',
    iso3: 'FLK',
  },
  {
    name: 'Faroe Islands',
    iso2: 'FO',
    iso3: 'FRO',
  },
  {
    name: 'Fiji',
    iso2: 'FJ',
    iso3: 'FJI',
  },
  {
    name: 'Finland',
    iso2: 'FI',
    iso3: 'FIN',
  },
  {
    name: 'France',
    iso2: 'FR',
    iso3: 'FRA',
  },
  {
    name: 'French Polynesia',
    iso2: 'PF',
    iso3: 'PYF',
  },
  {
    name: 'Gabon',
    iso2: 'GA',
    iso3: 'GAB',
  },
  {
    name: 'Gambia',
    iso2: 'GM',
    iso3: 'GMB',
  },
  {
    name: 'Georgia',
    iso2: 'GE',
    iso3: 'GEO',
  },
  {
    name: 'Germany',
    iso2: 'DE',
    iso3: 'DEU',
  },
  {
    name: 'Ghana',
    iso2: 'GH',
    iso3: 'GHA',
  },
  {
    name: 'Gibraltar',
    iso2: 'GI',
    iso3: 'GIB',
  },
  {
    name: 'Greece',
    iso2: 'GR',
    iso3: 'GRC',
  },
  {
    name: 'Greenland',
    iso2: 'GL',
    iso3: 'GRL',
  },
  {
    name: 'Grenada',
    iso2: 'GD',
    iso3: 'GRD',
  },
  {
    name: 'Guadeloupe',
    iso2: 'GP',
    iso3: 'GLP',
  },
  {
    name: 'Guam',
    iso2: 'GU',
    iso3: 'GUM',
  },
  {
    name: 'Guatemala',
    iso2: 'GT',
    iso3: 'GTM',
  },
  {
    name: 'Guernsey',
    iso2: 'GG',
    iso3: 'GGY',
  },
  {
    name: 'Guinea',
    iso2: 'GN',
    iso3: 'GIN',
  },
  {
    name: 'Guinea-Bissau',
    iso2: 'GW',
    iso3: 'GNB',
  },
  {
    name: 'Guyana',
    iso2: 'GY',
    iso3: 'GUY',
  },
  {
    name: 'Haiti',
    iso2: 'HT',
    iso3: 'HTI',
  },
  {
    name: 'Heard Island and McDonald Islands',
    iso2: 'HM',
    iso3: 'HMD',
  },
  {
    name: 'Vatican City State (Holy See)',
    iso2: 'VA',
    iso3: 'VAT',
  },
  {
    name: 'Honduras',
    iso2: 'HN',
    iso3: 'HND',
  },
  {
    name: 'Hong Kong',
    iso2: 'HK',
    iso3: 'HKG',
  },
  {
    name: 'Hungary',
    iso2: 'HU',
    iso3: 'HUN',
  },
  {
    name: 'Iceland',
    iso2: 'IS',
    iso3: 'ISL',
  },
  {
    name: 'India',
    iso2: 'IN',
    iso3: 'IND',
  },
  {
    name: 'Indonesia',
    iso2: 'ID',
    iso3: 'IDN',
  },
  {
    name: 'Iran',
    iso2: 'IR',
    iso3: 'IRN',
  },
  {
    name: 'Iraq',
    iso2: 'IQ',
    iso3: 'IRQ',
  },
  {
    name: 'Ireland',
    iso2: 'IE',
    iso3: 'IRL',
  },
  {
    name: 'Isle of Man',
    iso2: 'IM',
    iso3: 'IMN',
  },
  {
    name: 'Israel',
    iso2: 'IL',
    iso3: 'ISR',
  },
  {
    name: 'Italy',
    iso2: 'IT',
    iso3: 'ITA',
  },
  {
    name: 'Jamaica',
    iso2: 'JM',
    iso3: 'JAM',
  },
  {
    name: 'Japan',
    iso2: 'JP',
    iso3: 'JPN',
  },
  {
    name: 'Jersey',
    iso2: 'JE',
    iso3: 'JEY',
  },
  {
    name: 'Jordan',
    iso2: 'JO',
    iso3: 'JOR',
  },
  {
    name: 'Kazakhstan',
    iso2: 'KZ',
    iso3: 'KAZ',
  },
  {
    name: 'Kenya',
    iso2: 'KE',
    iso3: 'KEN',
  },
  {
    name: 'Kiribati',
    iso2: 'KI',
    iso3: 'KIR',
  },
  {
    name: 'Kuwait',
    iso2: 'KW',
    iso3: 'KWT',
  },
  {
    name: 'Kyrgyzstan',
    iso2: 'KG',
    iso3: 'KGZ',
  },
  {
    name: 'Laos',
    iso2: 'LA',
    iso3: 'LAO',
  },
  {
    name: 'Latvia',
    iso2: 'LV',
    iso3: 'LVA',
  },
  {
    name: 'Lebanon',
    iso2: 'LB',
    iso3: 'LBN',
  },
  {
    name: 'Lesotho',
    iso2: 'LS',
    iso3: 'LSO',
  },
  {
    name: 'Liberia',
    iso2: 'LR',
    iso3: 'LBR',
  },
  {
    name: 'Liechtenstein',
    iso2: 'LI',
    iso3: 'LIE',
  },
  {
    name: 'Lithuania',
    iso2: 'LT',
    iso3: 'LTU',
  },
  {
    name: 'Luxembourg',
    iso2: 'LU',
    iso3: 'LUX',
  },
  {
    name: 'Macau',
    iso2: 'MO',
    iso3: 'MAC',
  },
  {
    name: 'Madagascar',
    iso2: 'MG',
    iso3: 'MDG',
  },
  {
    name: 'Malawi',
    iso2: 'MW',
    iso3: 'MWI',
  },
  {
    name: 'Malaysia',
    iso2: 'MY',
    iso3: 'MYS',
  },
  {
    name: 'Maldives',
    iso2: 'MV',
    iso3: 'MDV',
  },
  {
    name: 'Mali',
    iso2: 'ML',
    iso3: 'MLI',
  },
  {
    name: 'Malta',
    iso2: 'MT',
    iso3: 'MLT',
  },
  {
    name: 'Marshall Islands',
    iso2: 'MH',
    iso3: 'MHL',
  },
  {
    name: 'Martinique',
    iso2: 'MQ',
    iso3: 'MTQ',
  },
  {
    name: 'Mauritania',
    iso2: 'MR',
    iso3: 'MRT',
  },
  {
    name: 'Mauritius',
    iso2: 'MU',
    iso3: 'MUS',
  },
  {
    name: 'Mayotte',
    iso2: 'YT',
    iso3: 'MYT',
  },
  {
    name: 'Mexico',
    iso2: 'MX',
    iso3: 'MEX',
  },
  {
    name: 'Monaco',
    iso2: 'MC',
    iso3: 'MCO',
  },
  {
    name: 'Mongolia',
    iso2: 'MN',
    iso3: 'MNG',
  },
  {
    name: 'Montenegro',
    iso2: 'ME',
    iso3: 'MNE',
  },
  {
    name: 'Montserrat',
    iso2: 'MS',
    iso3: 'MSR',
  },
  {
    name: 'Morocco',
    iso2: 'MA',
    iso3: 'MAR',
  },
  {
    name: 'Mozambique',
    iso2: 'MZ',
    iso3: 'MOZ',
  },
  {
    name: 'Myanmar',
    iso2: 'MM',
    iso3: 'MMR',
  },
  {
    name: 'Namibia',
    iso2: 'NA',
    iso3: 'NAM',
  },
  {
    name: 'Nauru',
    iso2: 'NR',
    iso3: 'NRU',
  },
  {
    name: 'Nepal',
    iso2: 'NP',
    iso3: 'NPL',
  },
  {
    name: 'Netherlands',
    iso2: 'NL',
    iso3: 'NLD',
  },
  {
    name: 'New Caledonia',
    iso2: 'NC',
    iso3: 'NCL',
  },
  {
    name: 'New Zealand',
    iso2: 'NZ',
    iso3: 'NZL',
  },
  {
    name: 'Nicaragua',
    iso2: 'NI',
    iso3: 'NIC',
  },
  {
    name: 'Niger',
    iso2: 'NE',
    iso3: 'NER',
  },
  {
    name: 'Nigeria',
    iso2: 'NG',
    iso3: 'NGA',
  },
  {
    name: 'Niue',
    iso2: 'NU',
    iso3: 'NIU',
  },
  {
    name: 'Norfolk Island',
    iso2: 'NF',
    iso3: 'NFK',
  },
  {
    name: 'Northern Mariana Islands',
    iso2: 'MP',
    iso3: 'MNP',
  },
  {
    name: 'Norway',
    iso2: 'NO',
    iso3: 'NOR',
  },
  {
    name: 'Oman',
    iso2: 'OM',
    iso3: 'OMN',
  },
  {
    name: 'Pakistan',
    iso2: 'PK',
    iso3: 'PAK',
  },
  {
    name: 'Palau',
    iso2: 'PW',
    iso3: 'PLW',
  },
  {
    name: 'Panama',
    iso2: 'PA',
    iso3: 'PAN',
  },
  {
    name: 'Papua New Guinea',
    iso2: 'PG',
    iso3: 'PNG',
  },
  {
    name: 'Paraguay',
    iso2: 'PY',
    iso3: 'PRY',
  },
  {
    name: 'Peru',
    iso2: 'PE',
    iso3: 'PER',
  },
  {
    name: 'Philippines',
    iso2: 'PH',
    iso3: 'PHL',
  },
  {
    name: 'Pitcairn',
    iso2: 'PN',
    iso3: 'PCN',
  },
  {
    name: 'Poland',
    iso2: 'PL',
    iso3: 'POL',
  },
  {
    name: 'Portugal',
    iso2: 'PT',
    iso3: 'PRT',
  },
  {
    name: 'Puerto Rico',
    iso2: 'PR',
    iso3: 'PRI',
  },
  {
    name: 'Qatar',
    iso2: 'QA',
    iso3: 'QAT',
  },
  {
    name: 'Réunion',
    iso2: 'RE',
    iso3: 'REU',
  },
  {
    name: 'Romania',
    iso2: 'RO',
    iso3: 'ROU',
  },
  {
    name: 'Rwanda',
    iso2: 'RW',
    iso3: 'RWA',
  },
  {
    name: 'Saint Kitts and Nevis',
    iso2: 'KN',
    iso3: 'KNA',
  },
  {
    name: 'Saint Lucia',
    iso2: 'LC',
    iso3: 'LCA',
  },
  {
    name: 'Saint Pierre and Miquelon',
    iso2: 'PM',
    iso3: 'SPM',
  },
  {
    name: 'Saint Vincent and the Grenadines',
    iso2: 'VC',
    iso3: 'VCT',
  },
  {
    name: 'Samoa',
    iso2: 'WS',
    iso3: 'WSM',
  },
  {
    name: 'San Marino',
    iso2: 'SM',
    iso3: 'SMR',
  },
  {
    name: 'Sao Tome and Principe',
    iso2: 'ST',
    iso3: 'STP',
  },
  {
    name: 'Saudi Arabia',
    iso2: 'SA',
    iso3: 'SAU',
  },
  {
    name: 'Senegal',
    iso2: 'SN',
    iso3: 'SEN',
  },
  {
    name: 'Serbia',
    iso2: 'RS',
    iso3: 'SRB',
  },
  {
    name: 'Seychelles',
    iso2: 'SC',
    iso3: 'SYC',
  },
  {
    name: 'Sierra Leone',
    iso2: 'SL',
    iso3: 'SLE',
  },
  {
    name: 'Singapore',
    iso2: 'SG',
    iso3: 'SGP',
  },
  {
    name: 'Slovakia',
    iso2: 'SK',
    iso3: 'SVK',
  },
  {
    name: 'Slovenia',
    iso2: 'SI',
    iso3: 'SVN',
  },
  {
    name: 'Solomon Islands',
    iso2: 'SB',
    iso3: 'SLB',
  },
  {
    name: 'Somalia',
    iso2: 'SO',
    iso3: 'SOM',
  },
  {
    name: 'South Africa',
    iso2: 'ZA',
    iso3: 'ZAF',
  },
  {
    name: 'South Georgia and the South Sandwich Islands',
    iso2: 'GS',
    iso3: 'SGS',
  },
  {
    name: 'Spain',
    iso2: 'ES',
    iso3: 'ESP',
  },
  {
    name: 'Sri Lanka',
    iso2: 'LK',
    iso3: 'LKA',
  },
  {
    name: 'Sudan',
    iso2: 'SD',
    iso3: 'SDN',
  },
  {
    name: 'Suriname',
    iso2: 'SR',
    iso3: 'SUR',
  },
  {
    name: 'Swaziland',
    iso2: 'SZ',
    iso3: 'SWZ',
  },
  {
    name: 'Sweden',
    iso2: 'SE',
    iso3: 'SWE',
  },
  {
    name: 'Switzerland',
    iso2: 'CH',
    iso3: 'CHE',
  },
  {
    name: 'Syria',
    iso2: 'SY',
    iso3: 'SYR',
  },
  {
    name: 'Taiwan',
    iso2: 'TW',
    iso3: 'TWN',
  },
  {
    name: 'Tajikistan',
    iso2: 'TJ',
    iso3: 'TJK',
  },
  {
    name: 'Thailand',
    iso2: 'TH',
    iso3: 'THA',
  },
  {
    name: 'Timor-Leste',
    iso2: 'TL',
    iso3: 'TLS',
  },
  {
    name: 'Togo',
    iso2: 'TG',
    iso3: 'TGO',
  },
  {
    name: 'Tokelau',
    iso2: 'TK',
    iso3: 'TKL',
  },
  {
    name: 'Tonga',
    iso2: 'TO',
    iso3: 'TON',
  },
  {
    name: 'Trinidad and Tobago',
    iso2: 'TT',
    iso3: 'TTO',
  },
  {
    name: 'Tunisia',
    iso2: 'TN',
    iso3: 'TUN',
  },
  {
    name: 'Turkey',
    iso2: 'TR',
    iso3: 'TUR',
  },
  {
    name: 'Turkmenistan',
    iso2: 'TM',
    iso3: 'TKM',
  },
  {
    name: 'Turks and Caicos Islands',
    iso2: 'TC',
    iso3: 'TCA',
  },
  {
    name: 'Tuvalu',
    iso2: 'TV',
    iso3: 'TUV',
  },
  {
    name: 'Uganda',
    iso2: 'UG',
    iso3: 'UGA',
  },
  {
    name: 'Ukraine',
    iso2: 'UA',
    iso3: 'UKR',
  },
  {
    name: 'United Arab Emirates',
    iso2: 'AE',
    iso3: 'ARE',
  },
  {
    name: 'United Kingdom',
    iso2: 'GB',
    iso3: 'GBR',
  },
  {
    name: 'United States',
    iso2: 'US',
    iso3: 'USA',
  },
  {
    name: 'United States Minor Outlying Islands',
    iso2: 'UM',
    iso3: 'UMI',
  },
  {
    name: 'Uruguay',
    iso2: 'UY',
    iso3: 'URY',
  },
  {
    name: 'Uzbekistan',
    iso2: 'UZ',
    iso3: 'UZB',
  },
  {
    name: 'Vanuatu',
    iso2: 'VU',
    iso3: 'VUT',
  },
  {
    name: 'Vietnam',
    iso2: 'VN',
    iso3: 'VNM',
  },
  {
    name: 'Wallis and Futuna',
    iso2: 'WF',
    iso3: 'WLF',
  },
  {
    name: 'Yemen',
    iso2: 'YE',
    iso3: 'YEM',
  },
  {
    name: 'Zambia',
    iso2: 'ZM',
    iso3: 'ZMB',
  },
  {
    name: 'Zimbabwe',
    iso2: 'ZW',
    iso3: 'ZWE',
  },
];

export const listingStat = [
  {
    label: 'Selling',
    value: 'selling',
  },
  { label: 'Partially Selling', value: 'partiallySelling' },
  { label: 'Investors', value: 'investors' },
  { label: 'Partners', value: 'partners' },
  {
    label: 'Financing',
    value: 'financing',
  },
  { label: 'Others', value: 'others' },
];

export const businessStat = [
  { label: 'Accommodation and Food Services', value: 'foodAccommodation' },
  { label: 'Administrative and Support Services', value: 'adminSupport' },
  { label: 'Agriculture, Forestry, Fishing and Hunting', value: 'agriculture' },
  { label: 'Air Transportation', value: 'airTransport' },
  { label: 'Health Care Services', value: 'healthCare' },
  { label: 'Amusement and Recreation Industries', value: 'amusementRecreation' },
  { label: 'Animal Production', value: 'animalProduction' },
  { label: 'Apparel Manufacturing', value: 'apparelManufacture' },
  { label: 'Arts, Entertainment, and Recreation', value: 'artsEntertainmentRecreation' },
  { label: 'Beverage and Tobacco Product', value: 'beverageTobacco' },
  { label: 'Broadcasting', value: 'broadcasting' },
  { label: 'Building Material and Garden Equipment', value: 'buildingGarden' },
  { label: 'Chemical Manufacturing', value: 'chemicalManufacture' },
  { label: 'Clothing and Clothing Accessories Stores', value: 'clothing' },
  { label: 'Computer and Electronic Product', value: 'electronicProduct' },
  { label: 'Construction', value: 'construction' },
  { label: 'Couriers and Messengers', value: 'couriersMessengers' },
  { label: 'Credit Intermediation', value: 'creditIntermediate' },
  { label: 'Crop Production', value: 'cropProduct' },
  { label: 'Data Processing, Hosting', value: 'dataProcessing' },
  { label: 'Education', value: 'education' },
  { label: 'Health Services', value: 'healthService' },
  { label: 'Electrical Equipment, Appliance', value: 'electricalAppliance' },
  { label: 'Electronics and Appliance Stores', value: 'electronicsAppliance' },
  { label: 'Fabricated Metal Product Manufacturing', value: 'fabricatedMetalProduct' },
  { label: 'Finance and Insurance', value: 'financeInsurance' },
  { label: 'Food Manufacturing', value: 'foodManufacture' },
  { label: 'Food Services and Drinking Places', value: 'foodDrinking' },
  { label: 'Food and Beverage Stores', value: 'foodBeverage' },
  { label: 'Furniture and Home Furnishings Stores', value: 'furnitureFurnishing' },
  { label: 'Manufacturing', value: 'manufacturing' },
  { label: 'Gasoline Stations', value: 'gasoline' },
  { label: 'General Merchandise Stores', value: 'generalMerchandise' },
  { label: 'Goods-Producing Industries', value: 'goodsProducing' },
  { label: 'Health and Personal Care Stores', value: 'healthPersonalCare' },
  { label: 'Heavy and Civil Engineering', value: 'heavyCivil' },
  { label: 'Hospitals', value: 'hospitals' },
  { label: 'Internet Publishing and Broadcasting', value: 'internetPublishing' },
  { label: 'Leisure and Hospitality', value: 'leisureHospitality' },
  {
    label: 'Lessors of Nonfinancial Intangible Machinery Merchant Wholesalers',
    value: 'machineryMerchant',
  },
  { label: 'Mining, Quarrying, and Oil and Gas', value: 'miningQuarryingOilGas' },
  { label: 'Store Retailers', value: 'storeRetailers' },
  { label: 'Motion Picture and Sound Recording', value: 'motionSoundPicture' },
  { label: 'Motor Vehicle and Parts Dealers', value: 'motorVehicleParts' },
  { label: 'Performing Arts, Spectator Sports', value: 'performingArts' },
  { label: 'Real Estate and Rental and Leasing', value: 'realEstateRentalLeasing' },
  { label: 'Repair and Maintenance', value: 'repairMaintenance' },
  { label: 'Retail', value: 'retail' },
  { label: 'Sporting Goods, Hobby, Book, and Music Stores', value: 'sportingGoodsMusic' },
  { label: 'Telecommunications', value: 'telecommunications' },
  { label: 'Textile Mills', value: 'textileMills' },
  { label: 'Trade, Transportation, and Utilities', value: 'tradeTransportUtility' },
  { label: 'Warehousing and Storage', value: 'warehousingStorage' },
  { label: 'Waste Management and Remediation', value: 'wasteManagementRemediation' },
  { label: 'Water Transportation', value: 'waterTransport' },
  { label: 'Wholesale Electronic', value: 'wholesaleElectronic' },
  { label: 'Others', value: 'others' },
];

export const businessTypes = [
  { label: 'Sole proprietorship', value: 'soleProprietorship' },
  { label: 'Partnership', value: 'partnership' },
  { label: 'Limited liability company', value: 'limitedLiability' },
  { label: 'Corporation', value: 'corporation' },
  { label: 'Cooperative', value: 'cooperative' },
  { label: 'Other', value: 'other' },
];

export const employes = [
  { label: '0 to 10', value: '0to10' },
  { label: '11 to 50', value: '11to50' },
  { label: '51 to 100', value: '51to100' },
  { label: '100+', value: '100+' },
];
