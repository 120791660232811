import React, { Component } from 'react';
import { array, bool, func, object, shape, string } from 'prop-types';
import { compose } from 'redux';
import { ARRAY_ERROR } from 'final-form';
import axios from 'axios';

import { Form as FinalForm, Field } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';
import isEqual from 'lodash/isEqual';
import classNames from 'classnames';
import { ReactComponent as LockIcon } from '../../../../assets/lock.svg';
// Import configs and util modules
import config from '../../../../config';
import { FormattedMessage, intlShape, injectIntl } from '../../../../util/reactIntl';
import { propTypes } from '../../../../util/types';
import { nonEmptyArray, composeValidators } from '../../../../util/validators';
import { isUploadImageOverLimitError } from '../../../../util/errors';

// Import shared components
import { Button, Form, AspectRatioWrapper, PrimaryButton } from '../../../../components';

// Import modules from this directory
import ListingImage from './ListingImage';
import css from './EditListingPhotosForm.module.css';
import FileUploader from './FileUploader';
import { apiBaseUrl } from '../../../../util/api';
import { requestUpdateListing } from '../../EditListingPage.duck';
import { connect } from 'react-redux';
import { createResourceLocatorString } from '../../../../util/routes';
import routeConfiguration from '../../../../routing/routeConfiguration';
import { LISTING_PAGE_PARAM_TYPE_DRAFT, createSlug } from '../../../../util/urlHelpers';

const ACCEPT_IMAGES = 'image/*';

// Field component that uses file-input to allow user to select images.
const FieldAddImage = (props) => {
  const { formApi, onImageUploadHandler, ...rest } = props;
  const { aspectWidth = 1, aspectHeight = 1 } = config.listing;
  return (
    <Field form={null} {...rest}>
      {(fieldprops) => {
        const { accept, input, label, disabled: fieldDisabled } = fieldprops;
        const { name, type } = input;
        const onChange = (e) => {
          const file = e.target.files[0];
          formApi.change(`addImage`, file);
          formApi.blur(`addImage`);
          onImageUploadHandler(file);
        };
        const inputProps = { accept, id: name, name, onChange, type };
        return (
          <div className={css.addImageWrapper}>
            <AspectRatioWrapper width={aspectWidth} height={aspectHeight}>
              {fieldDisabled ? null : <input {...inputProps} className={css.addImageInput} />}
              <label htmlFor={name} className={css.addImage}>
                {label}
              </label>
            </AspectRatioWrapper>
          </div>
        );
      }}
    </Field>
  );
};

// Component that shows listing images from "images" field array
const FieldListingImage = (props) => {
  const { name, intl, onRemoveImage } = props;
  return (
    <Field name={name}>
      {(fieldProps) => {
        const { input } = fieldProps;
        const image = input.value;
        return image ? (
          <ListingImage
            image={image}
            key={image?.id?.uuid || image?.id}
            className={css.thumbnail}
            savedImageAltText={intl.formatMessage({
              id: 'EditListingPhotosForm.savedImageAltText',
            })}
            onRemoveImage={() => onRemoveImage(image?.id)}
          />
        ) : null;
      }}
    </Field>
  );
};

export class EditListingPhotosFormComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      imageUploadRequested: false,
      disable: true,
      selectedFile: [],
      signedURL: [],
      imgState: true,
      saveDocument: false,
      fileUploadInProgress: false,
      fileUploadProgress: 0,
      url: [],
      s3docs: props.currentListing?.attributes?.privateData?.urlValues,
      fakeLoader: true,
      fileFormatError: false,
    };
    this.onImageUploadHandler = this.onImageUploadHandler.bind(this);
    this.submittedImages = [];
  }

  onImageUploadHandler(file) {
    if (file) {
      this.setState({ imageUploadRequested: true });
      this.props
        .onImageUpload({ id: `${file.name}_${Date.now()}`, file })
        .then(() => {
          this.setState({ imageUploadRequested: false });
        })
        .catch(() => {
          this.setState({ imageUploadRequested: false });
        });
    }
  }
  setFileUploadError = (msg) => {
    this.setState(
      {
        fileUploadError: msg,
      },
      () =>
        setTimeout(() => {
          this.setState({
            fileUploadError: null,
          });
        }, 3000)
    );
  };
  onFileRemove = (pathName) => {
    this.setState((prevState) => {
      const removedSelectedFileIndex = prevState.selectedFile.findIndex(
        (file) => file.path === pathName
      );

      return {
        selectedFile: prevState.selectedFile.filter((file) => file.path !== pathName),
        signedURL: prevState.signedURL.splice(removedSelectedFileIndex, 1),
        disable: true,
      };
    });
  };
  onFileUpload = (files) => {
    // const buttondisable=setTimeout(())
    this.setState({ fileFormatError: false, disable: false });

    let { name, size, type } = files;
    if (!name || !size || !type) {
      if (name) this.setFileUploadError('File format not supported');

      this.setState({ fileFormatError: true, disable: true });
      return;
    }
    let limit = 64;
    let maxSize = limit * 1024 * 1024; //64MB
    let fileName = name;
    // let fileName = name.split('.')[0] + '_' + new Date().getTime();
    const srcFile = files;

    if (size > maxSize) {
      this.setFileUploadError(`Max file size limit ${limit}mb`);
      return null;
    }

    axios
      .post(`${apiBaseUrl()}/fileshare/getSignUrl`, {
        fileName: fileName,
        fileType: type,
      })
      .then((res) => {
        this.setState((prevState) => ({
          selectedFile: [...prevState.selectedFile, srcFile],
          signedURL: [...prevState.signedURL, res.data],
        }));
      })
      .catch((e) => {
        this.setFileUploadError(`Failed to upload image`);
        console.log(e);
      });
    this.setState({ imgState: false });
  };
  //called in upload btn function
  sendFile = (signedURL, data) => {
    return new Promise((resolve) => {
      this.setState({
        fileUploadInProgress: true,
      });
      axios({
        method: 'put',
        url: signedURL,
        data,
        headers: { 'content-type': data.type },
        onUploadProgress: (progressEvent) => {
          let progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          this.setState({
            fileUploadProgress: progress,
          });
        },
      })
        .then(() => {
          this.setState({
            fileUploadInProgress: false,
            selectedFile: [],
            signedURL: [],
            fileUploadSuccess: false,
            disable: true,
            saveDocument: true,
            deleteDocument: [],
            // notDeletedValues: [],
          });
          // alert('Press Save Changes to watch your uploaded file');
          let url = [];
          url.push(signedURL.split('?')[0]);
          // onUpdateProfile({
          //   protectedData: {
          //     urlValues: [...uploadedUrl, ...url],
          //   },
          // });
          this.setState((prevState) => ({
            url: [...this.state.url, ...url],
          }));

          resolve(url);
        })
        .catch((e) => {
          this.setFileUploadError('Someting went wrong, please try again.');
          this.setState({
            fileUploadInProgress: false,
            selectedFile: [],
            signedURL: [],
          });
        });
    });
  };
  //function for upload btn
  uploadToS3 = (values, urlValues) => {
    const { selectedFile, signedURL } = this.state;
    this.setState({ fakeLoader: false });
    const action = selectedFile.map((file, index) => {
      const url = signedURL[index];
      const data = file;
      return this.sendFile(url, data);
    });
    const res = Promise.all(action);

    res.then((res) => {
      const data = {
        id: this.props.currentListing?.id?.uuid,
        privateData: {
          urlValues: urlValues?.length >= 1 ? [...urlValues, ...res.flat()] : res.flat(),
        },
      };
      this.props.onUpdateListing(data).this(() => {
        this.setState({ fakeLoader: true });
      });
    });
  };
  removeFromS3 = (file) => {
    this.setState({ fakeLoader: false });
    axios
      .delete(`${apiBaseUrl()}/fileshare/deleteS3Object`, { data: { url: file } })
      .then(() => {
        const data = {
          id: this.props.currentListing?.id?.uuid,
          privateData: {
            urlValues: this.props.currentListing?.attributes?.privateData?.urlValues.filter(
              (f) => f !== file
            ),
          },
        };
        this.props.onUpdateListing(data).then(() => {
          this.setState({ fakeLoader: true });
          this.setState((state) => {
            state.s3docs ===
              this.props.currentListing?.attributes?.privateData?.urlValues.filter(
                (f) => f !== file
              );
          });
        });
      })
      .catch((e) => console.log(e));
  };
  render() {
    return (
      <FinalForm
        {...this.props}
        mutators={{ ...arrayMutators }}
        onImageUploadHandler={this.onImageUploadHandler}
        imageUploadRequested={this.state.imageUploadRequested}
        initialValues={{ images: this.props.images }}
        render={(formRenderProps) => {
          const {
            form,
            className,
            fetchErrors,
            handleSubmit,
            images,
            imageUploadRequested,
            intl,
            invalid,
            onImageUploadHandler,
            onRemoveImage,
            disabled,
            ready,
            saveActionMsg,
            updated,
            updateInProgress,
            touched,
            errors,
            values,
            onUpdateListing,
            currentListing,
            history,
          } = formRenderProps;
          const listingId = currentListing?.id?.uuid;
          const urlValues = currentListing?.attributes?.privateData?.urlValues;
          const chooseImageText = (
            <span className={css.chooseImageText}>
              <span className={css.chooseImage}>+</span>
              <span className={css.imageTypes}>
                Upload your business/ investment opportunity picture
              </span>
            </span>
          );

          const imageRequiredMessage = intl.formatMessage({
            id: 'EditListingPhotosForm.imageRequired',
          });

          const { publishListingError, showListingsError, updateListingError, uploadImageError } =
            fetchErrors || {};
          const uploadOverLimit = isUploadImageOverLimitError(uploadImageError);

          let uploadImageFailed = null;

          if (uploadOverLimit) {
            uploadImageFailed = (
              <p className={css.error}>
                <FormattedMessage id="EditListingPhotosForm.imageUploadFailed.uploadOverLimit" />
              </p>
            );
          } else if (uploadImageError) {
            uploadImageFailed = (
              <p className={css.error}>
                <FormattedMessage id="EditListingPhotosForm.imageUploadFailed.uploadFailed" />
              </p>
            );
          }

          // NOTE: These error messages are here since Photos panel is the last visible panel
          // before creating a new listing. If that order is changed, these should be changed too.
          // Create and show listing errors are shown above submit button
          const publishListingFailed = publishListingError ? (
            <p className={css.error}>
              <FormattedMessage id="EditListingPhotosForm.publishListingFailed" />
            </p>
          ) : null;
          const showListingFailed = showListingsError ? (
            <p className={css.error}>
              <FormattedMessage id="EditListingPhotosForm.showListingFailed" />
            </p>
          ) : null;

          const submittedOnce = this.submittedImages.length > 0;
          // imgs can contain added images (with temp ids) and submitted images with uniq ids.
          const arrayOfImgIds = (imgs) =>
            imgs.map((i) => (typeof i.id === 'string' ? i.imageId : i.id));
          const imageIdsFromProps = arrayOfImgIds(images);
          const imageIdsFromPreviousSubmit = arrayOfImgIds(this.submittedImages);
          const imageArrayHasSameImages = isEqual(imageIdsFromProps, imageIdsFromPreviousSubmit);
          const pristineSinceLastSubmit = submittedOnce && imageArrayHasSameImages;

          const submitReady = (updated && pristineSinceLastSubmit) || ready;
          const submitInProgress = updateInProgress;
          // console.log(5856, currentListing, currentListing.attributes?.publicData?.investmentSize);
          const submitDisabled =
            invalid ||
            disabled ||
            submitInProgress ||
            imageUploadRequested ||
            ready ||
            (currentListing?.attributes?.publicData?.isQuoteType &&
              !currentListing?.attributes?.publicData?.investmentSize);
          const imagesError = touched.images && errors?.images && errors.images[ARRAY_ERROR];

          const classes = classNames(css.root, className);
          const uploadedFiles =
            !!urlValues &&
            urlValues?.length !== 0 &&
            urlValues.map((file, idx) => {
              const splitFile = file?.split('/');
              const filename = splitFile[splitFile?.length - 1].replaceAll('%20', ' ');
              return (
                <React.Fragment key={`${file.path}${idx}`}>
                  <li key={`${file.path}${idx}`}>
                    <div className={css.lictnt}>
                      <p>{filename}</p>
                      <div onClick={() => this.removeFromS3(file)}>
                        <div className={css.lisvg}>
                          <svg
                            width="10px"
                            height="10px"
                            viewBox="0 0 10 10"
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g strokeWidth="1" fillRule="evenodd">
                              <g transform="translate(-821.000000, -311.000000)">
                                <g transform="translate(809.000000, 299.000000)">
                                  <path
                                    d="M21.5833333,16.5833333 L17.4166667,16.5833333 L17.4166667,12.4170833 C17.4166667,12.1866667 17.2391667,12 17.00875,12 C16.77875,12 16.5920833,12.18625 16.5920833,12.41625 L16.5883333,16.5833333 L12.4166667,16.5833333 C12.18625,16.5833333 12,16.7695833 12,17 C12,17.23 12.18625,17.4166667 12.4166667,17.4166667 L16.5875,17.4166667 L16.5833333,21.5829167 C16.5829167,21.8129167 16.7691667,21.9995833 16.9991667,22 L16.9995833,22 C17.2295833,22 17.41625,21.81375 17.4166667,21.58375 L17.4166667,17.4166667 L21.5833333,17.4166667 C21.8133333,17.4166667 22,17.23 22,17 C22,16.7695833 21.8133333,16.5833333 21.5833333,16.5833333"
                                    transform="translate(17.000000, 17.000000) rotate(-45.000000) translate(-17.000000, -17.000000) "
                                  />
                                </g>
                              </g>
                            </g>
                          </svg>
                        </div>
                      </div>
                    </div>
                  </li>
                </React.Fragment>
              );
            });
          return (
            <Form
              className={classes}
              onSubmit={(e) => {
                this.submittedImages = images;
                handleSubmit(e);
              }}
            >
              {updateListingError ? (
                <p className={css.error}>
                  <FormattedMessage id="EditListingPhotosForm.updateFailed" />
                </p>
              ) : null}

              <div className={css.imagesField}>
                <FieldAddImage
                  id="addImage"
                  name="addImage"
                  accept={ACCEPT_IMAGES}
                  label={chooseImageText}
                  type="file"
                  disabled={imageUploadRequested}
                  formApi={form}
                  onImageUploadHandler={onImageUploadHandler}
                />
                <FieldArray
                  name="images"
                  validate={composeValidators(nonEmptyArray(imageRequiredMessage))}
                >
                  {({ fields }) =>
                    fields.map((name, index) => (
                      <FieldListingImage
                        key={name}
                        name={name}
                        onRemoveImage={(imageId) => {
                          fields.remove(index);
                          onRemoveImage(imageId);
                        }}
                        intl={intl}
                      />
                    ))
                  }
                </FieldArray>
              </div>
              {imagesError ? <div className={css.arrayError}>{imagesError}</div> : null}
              {uploadImageFailed}

              <div className={css.docsec}>
                <h2>Documentation</h2>
                <span>
                  Upload documents that show your Business legal and financial claims to your
                  investors.
                </span>
                <div className={css.privateMsgDiv}>
                  <LockIcon />
                  <FormattedMessage id="EditListingBusinessDetailsForm.businessHelpText5" />
                </div>
              </div>
              <div className={`${css.fileup} fileup`}>
                <FileUploader
                  onUpload={this.onFileUpload}
                  selectedFile={this.state.selectedFile}
                  onFileRemove={this.onFileRemove}
                  // uploadUrl={uploadedUrl}
                  fileFormatError={this.state.fileFormatError}
                />
              </div>
              {/* {console.log(654, this.state.selectedFile, uploadedFiles)} */}
              {!!this.state.selectedFile && (
                <PrimaryButton
                  type="button"
                  inProgress={this.state.fileUploadInProgress}
                  ready={this.state.fileUploadSuccess}
                  className={css.upload}
                  disabled={this.state.disable}
                  onClick={() => !!this.state.selectedFile && this.uploadToS3(values, urlValues)}
                >
                  Upload
                </PrimaryButton>
              )}

              <aside>
                {' '}
                <ul className={css.alrupd}>{uploadedFiles}</ul>
              </aside>

              {publishListingFailed}
              {showListingFailed}

              <Button
                className={css.submitButton}
                type="submit"
                inProgress={submitInProgress && this.state.fakeLoader}
                disabled={
                  submitDisabled ||
                  !(values?.images?.length >= 2) ||
                  this.state.selectedFile.length > 0
                }
                ready={submitReady}
              >
                {saveActionMsg}
              </Button>
              {currentListing?.attributes?.publicData?.isQuoteType &&
                !currentListing?.attributes?.publicData?.investmentSize && (
                  <span className={css.errorMsg}>
                    Mention the Investment Size under{' '}
                    <span
                      className={css.discoverMore}
                      onClick={() => {
                        // console.log(555);
                        const slug = createSlug(currentListing?.attributes?.title);
                        const draftType = LISTING_PAGE_PARAM_TYPE_DRAFT;
                        const tab = 'listingdetails';
                        const editRoute = createResourceLocatorString(
                          'EditListingQuotePage',
                          routeConfiguration(),
                          { slug, id: currentListing?.id?.uuid, type: draftType, tab },
                          {}
                        );

                        history.push(editRoute);
                      }}
                    >
                      Listing Details.
                    </span>
                  </span>
                )}
            </Form>
          );
        }}
      />
    );
  }
}

EditListingPhotosFormComponent.defaultProps = { fetchErrors: null, images: [] };

EditListingPhotosFormComponent.propTypes = {
  fetchErrors: shape({
    publishListingError: propTypes.error,
    showListingsError: propTypes.error,
    uploadImageError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  images: array,
  intl: intlShape.isRequired,
  onImageUpload: func.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  onRemoveImage: func.isRequired,
};
const mapDispatchToProps = (dispatch) => ({
  onUpdateListing: (data) => dispatch(requestUpdateListing(null, data)),
});
export default compose(
  injectIntl,
  connect(null, mapDispatchToProps)
)(EditListingPhotosFormComponent);
