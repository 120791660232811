import unionWith from 'lodash/unionWith';

import config from '../../config';
import { storableError } from '../../util/errors';
import { convertUnitToSubUnit, unitDivisor } from '../../util/currency';
import { parseDateFromISO8601, getExclusiveEndDate } from '../../util/dates';
import { createImageVariantConfig } from '../../util/sdkLoader';
import { isOriginInUse, isStockInUse } from '../../util/search';
import { parse } from '../../util/urlHelpers';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { fetchCurrentUser } from '../../ducks/user.duck';

const QUERY_PAGE_SIZE = 10;

// ================ Action types ================ //

export const SEARCH_LISTINGS_REQUEST = 'app/MyCustomQuote/SEARCH_LISTINGS_REQUEST';
export const SEARCH_LISTINGS_SUCCESS = 'app/MyCustomQuote/SEARCH_LISTINGS_SUCCESS';
export const SEARCH_LISTINGS_ERROR = 'app/MyCustomQuote/SEARCH_LISTINGS_ERROR';

// ================ Reducer ================ //

const initialState = {
  pagination: null,
  searchParams: null,
  searchInProgress: false,
  searchListingsError: null,
  currentPageResultIds: [],
};

const resultIds = (data) => data.data.map((l) => l.id);

const customQuotePageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case SEARCH_LISTINGS_REQUEST:
      return {
        ...state,
        searchParams: payload.searchParams,
        searchInProgress: true,
        searchMapListingIds: [],
        searchListingsError: null,
      };
    case SEARCH_LISTINGS_SUCCESS:
      return {
        ...state,
        currentPageResultIds: resultIds(payload.data),
        pagination: payload.data.meta,
        searchInProgress: false,
      };
    case SEARCH_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, searchInProgress: false, searchListingsError: payload };
    default:
      return state;
  }
};

export default customQuotePageReducer;

// ================ Action creators ================ //

export const searchListingsRequest = (searchParams) => ({
  type: SEARCH_LISTINGS_REQUEST,
  payload: { searchParams },
});

export const searchListingsSuccess = (response) => ({
  type: SEARCH_LISTINGS_SUCCESS,
  payload: { data: response.data },
});

export const searchListingsError = (e) => ({
  type: SEARCH_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const searchListings = (searchParams) => (dispatch, getState, sdk) => {
  dispatch(searchListingsRequest(searchParams));
  // const page = 1;
  const { aspectWidth = 1, aspectHeight = 1, variantPrefix = 'listing-card' } = config.listing;
  const aspectRatio = aspectHeight / aspectWidth;
  const { perPage, ...rest } = searchParams;
  const params = {
    ...rest,
    per_page: perPage,
    pub_isChatWithAdminListing: false,

    include: ['author', 'images'],
    'fields.listing': ['title', 'geolocation', 'price', 'publicData', 'metadata'],
    'fields.user': ['profile.displayName', 'profile.abbreviatedName'],
    'fields.image': [
      `variants.${variantPrefix}`,
      `variants.${variantPrefix}-2x`,
      `variants.default`,
    ],
    ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
    ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
    'limit.images': 2,
  };

  return sdk.listings
    .query(params, { expand: true })
    .then((response) => {
      dispatch(addMarketplaceEntities(response));
      dispatch(searchListingsSuccess(response));
      return response;
    })
    .catch((e) => {
      dispatch(searchListingsError(storableError(e)));
      throw e;
    });
};

export const loadData = () => async (dispatch, getState) => {
  // Pass sdk as an argument
  await dispatch(fetchCurrentUser());
  const { currentUser } = getState().user;
  const currentUserEmail = currentUser?.attributes?.email;
  const page = 1;
  const perPage = QUERY_PAGE_SIZE;
  return dispatch(
    searchListings({ pub_isQuoteType: true, pub_clientId: currentUserEmail, page, perPage })
  );
};
