import React from 'react';
import { bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';

// Import configs and util modules
import config from '../../../../config';
import { intlShape, injectIntl, FormattedMessage } from '../../../../util/reactIntl';
import { propTypes } from '../../../../util/types';
import { isOldTotalMismatchStockError } from '../../../../util/errors';
import * as validators from '../../../../util/validators';
import { formatMoney } from '../../../../util/currency';
import { types as sdkTypes } from '../../../../util/sdkLoader';

// Import shared components
import { Button, Form, FieldCurrencyInput, FieldTextInput } from '../../../../components';

// Import modules from this directory
import css from './EditListingPricingForm.module.css';

const { Money } = sdkTypes;

export const EditListingPricingFormComponent = props => (
  <FinalForm
    {...props}
    render={formRenderProps => {
      const {
        autoFocus,
        className,
        disabled,
        ready,
        handleSubmit,
        intl,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        touched,
        errors,
        values,
      } = formRenderProps;
      const priceRequired = validators.required(
        intl.formatMessage({
          id: 'EditListingPricingForm.priceRequired',
        })
      );
      const isFieldInvalid = fieldName => touched[fieldName] && !!errors[fieldName];

      const minPrice = new Money(config.listingMinimumPriceSubUnits, config.currency);
      const minPriceAmountForDownPayment = values?.price?.amount ? values?.price?.amount : 0;
      const minPriceForDownPayment = new Money(minPriceAmountForDownPayment, config.currency);

      const minPriceRequired = validators.moneySubUnitAmountAtLeast(
        intl.formatMessage(
          {
            id: 'EditListingPricingForm.priceTooLow',
          },
          {
            minPrice: formatMoney(intl, minPrice),
          }
        ),
        config.listingMinimumPriceSubUnits
      );
      const minPriceForDownPaymentRequired = validators.moneySubUnitAmountAtMax(
        intl.formatMessage(
          {
            id: 'EditListingPricingForm.priceTooLow',
          },
          {
            minPrice: formatMoney(intl, minPriceForDownPayment),
          }
        ),
        minPriceAmountForDownPayment
      );
      const priceValidators = config.listingMinimumPriceSubUnits
        ? validators.composeValidators(priceRequired, minPriceRequired)
        : priceRequired;

      const downPaymentPriceValidators = minPriceAmountForDownPayment
        ? validators.composeValidators(priceRequired, minPriceForDownPaymentRequired)
        : priceRequired;

      const infoValidator = validators.required(
        intl.formatMessage({ id: 'EditListingPricingForm.infoRequired' }),
        0
      );

      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;
      const { updateListingError, showListingsError, setStockError } = fetchErrors || {};

      const stockErrorMessage = isOldTotalMismatchStockError(setStockError)
        ? intl.formatMessage({ id: 'EditListingPricingForm.oldStockTotalWasOutOfSync' })
        : intl.formatMessage({ id: 'EditListingPricingForm.stockUpdateFailed' });

      return (
        <Form onSubmit={handleSubmit} className={classes}>
          {updateListingError ? (
            <p className={css.error}>
              <FormattedMessage id="EditListingPricingForm.updateFailed" />
            </p>
          ) : null}
          {showListingsError ? (
            <p className={css.error}>
              <FormattedMessage id="EditListingPricingForm.showListingFailed" />
            </p>
          ) : null}
          <FieldCurrencyInput
            id="price"
            name="price"
            className={classNames(css.input, {
              [css.invalidInput]: isFieldInvalid('price'),
            })}
            autoFocus={autoFocus}
            label={intl.formatMessage({ id: 'EditListingPricingForm.pricePerProduct' })}
            placeholder={intl.formatMessage({ id: 'EditListingPricingForm.priceInputPlaceholder' })}
            currencyConfig={config.currencyConfig}
            validate={priceValidators}
          />
          <FieldCurrencyInput
            id="downPrice"
            name="downPrice"
            className={classNames(css.input, {
              [css.invalidInput]: isFieldInvalid('downPrice'),
            })}
            autoFocus={autoFocus}
            label={intl.formatMessage({ id: 'EditListingPricingForm.downPricePerProduct' })}
            placeholder={intl.formatMessage({
              id: 'EditListingPricingForm.downPriceInputPlaceholder',
            })}
            currencyConfig={config.currencyConfig}
            validate={downPaymentPriceValidators}
          />
          <div className={css.FinancialInfoTopGap}>
            <h1 className={css.title}>Financial Details</h1>
            <FieldTextInput
              className={classNames(css.input, {
                [css.invalidInput]: isFieldInvalid('financialInfo'),
              })}
              id="financialInfo"
              name="financialInfo"
              type="textarea"
              label={intl.formatMessage({ id: 'EditListingPricingForm.financialInfoLabel' })}
              placeholder={intl.formatMessage({
                id: 'EditListingPricingForm.financialInfoPlaceholder',
              })}
              validate={infoValidator}
            />
          </div>

          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>
        </Form>
      );
    }}
  />
);

EditListingPricingFormComponent.defaultProps = { fetchErrors: null };

EditListingPricingFormComponent.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
};

export default compose(injectIntl)(EditListingPricingFormComponent);
