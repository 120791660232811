import React, { useState } from 'react';
import { string } from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import { twitterPageURL } from '../../util/urlHelpers';
import config from '../../config';
import { Form as FinalForm } from 'react-final-form';

import {
  IconSocialMediaFacebook,
  IconSocialMediaInstagram,
  IconSocialMediaTwitter,
  Logo,
  ExternalLink,
  NamedLink,
  Modal,
  PrimaryButton,
  FieldTextInput,
  Form,
} from '../../components';

import css from './Footer.module.css';
import { connect, useSelector } from 'react-redux';
import * as validators from '../../util/validators';
import axios from 'axios';
import { apiBaseUrl } from '../../util/api';
import { manageDisableScrolling } from '../../ducks/UI.duck';
import { compose } from 'redux';

const renderSocialMediaLinks = (intl) => {
  const { siteFacebookPage, siteInstagramPage, siteTwitterHandle } = config;
  const siteTwitterPage = twitterPageURL(siteTwitterHandle);

  const goToFb = intl.formatMessage({ id: 'Footer.goToFacebook' });
  const goToInsta = intl.formatMessage({ id: 'Footer.goToInstagram' });
  const goToTwitter = intl.formatMessage({ id: 'Footer.goToTwitter' });

  const fbLink = siteFacebookPage ? (
    <ExternalLink key="linkToFacebook" href={siteFacebookPage} className={css.icon} title={goToFb}>
      <IconSocialMediaFacebook />
    </ExternalLink>
  ) : null;

  const twitterLink = siteTwitterPage ? (
    <ExternalLink
      key="linkToTwitter"
      href={siteTwitterPage}
      className={css.icon}
      title={goToTwitter}
    >
      <IconSocialMediaTwitter />
    </ExternalLink>
  ) : null;

  const instragramLink = siteInstagramPage ? (
    <ExternalLink
      key="linkToInstagram"
      href={siteInstagramPage}
      className={css.icon}
      title={goToInsta}
    >
      <IconSocialMediaInstagram />
    </ExternalLink>
  ) : null;
  return [fbLink, twitterLink, instragramLink].filter((v) => v != null);
};

const FooterComponent = (props) => {
  return (
    <FinalForm
      {...props}
      onSubmit={() => {
        // console.log(111);
      }}
      render={(fieldRenderProps) => {
        const {
          rootClassName,
          className,
          intl,
          formId,
          inProgress,
          values,
          form,
          invalid,
          touched,
          errors,
          onManageDisableScrolling,
        } = fieldRenderProps;
        // console.log(147, invalid, touched, errors);
        // const { rootClassName, className, intl } = props;
        const socialMediaLinks = renderSocialMediaLinks(intl);
        const classes = classNames(rootClassName || css.root, className);
        const currentUser = useSelector((state) => state.user.currentUser);
        const [showContactModal, setShowContactModal] = useState(false);

        const emailRequiredMessage = intl.formatMessage({
          id: 'ContactDetailsForm.emailRequired',
        });
        const emailRequired = validators.required(emailRequiredMessage);
        const emailInvalidMessage = intl.formatMessage({
          id: 'ContactDetailsForm.emailInvalid',
        });
        const emailValid = validators.emailFormatValid(emailInvalidMessage);
        const messageRequiredMessage = intl.formatMessage({
          id: 'EnquiryForm.messageRequired',
        });

        const messageRequired = validators.requiredAndNonEmptyString(messageRequiredMessage);

        const submitInProgress = inProgress;
        const submitDisabled = submitInProgress;
        const profileFullname = `${currentUser?.attributes?.profile?.firstName} ${currentUser?.attributes?.profile?.lastName}`;
        const profileEmailId = `${currentUser?.attributes?.email}`;
        // console.log(1, values, currentUser);

        const handleFormSubmit = () => {
          const sendersName = values.fullName;
          const fromMailId = values.enquiryFromEmail;

          const msgText = values.enquiryMessage;
          // console.log(1);
          if (!values.enquiryFromEmail || !values.enquiryMessage) {
            // console.log('empty field');
            return;
          } else {
            // console.log(2);

            axios.post(`${apiBaseUrl()}/api/sendEnquiryMail`, { sendersName, msgText, fromMailId });
          }
          // console.log(3);
        };

        return (
          <Form
            className={classes}
            onSubmit={(val) => {
              // console.log(222, val);
            }}
          >
            <div className={classes}>
              <div className={css.topBorderWrapper}>
                <div className={css.content}>
                  {/* <div className={css.someLiksMobile}>{socialMediaLinks}</div> */}
                  <div className={css.links}>
                    <div className={css.organization} id="organization">
                      <NamedLink name="LandingPage" className={css.logoLink}>
                        <Logo format="desktop" className={css.logo} />
                        {/* BANKI BUSINESS ADVISER */}
                      </NamedLink>
                      <div className={css.organizationInfo}>
                        <p className={css.organizationDescription}>
                          <FormattedMessage id="Footer.organizationDescription" />
                        </p>
                      </div>
                    </div>
                    <div className={css.infoLinks}>
                      <ul className={css.list}>
                        <li className={css.listTopElement}>
                          <FormattedMessage id="Footer.support" />
                        </li>

                        <li className={css.listItem}>
                          <NamedLink name="ServicePackages" className={css.link}>
                            <FormattedMessage id="Footer.toFAQPage" />
                          </NamedLink>
                        </li>

                        <li
                          // className={css.listItem}
                          className={classNames(css.link, css.enquiryTxt)}
                          onClick={() => {
                            setShowContactModal(true);
                          }}
                        >
                          {/* <NamedLink
                    name="LandingPage"
                    // to={{ hash: '#contact' }}
                    className={css.link}
                  > */}
                          <FormattedMessage id="Footer.toEnquiryPage" />
                          {/* </NamedLink> */}
                        </li>
                      </ul>
                    </div>
                    <div className={css.searches}>
                      <ul className={css.list}>
                        <li className={css.listTopElement}>
                          <FormattedMessage id="Footer.company" />
                        </li>

                        <li className={css.listItem}>
                          <NamedLink name="AboutPage" className={css.link}>
                            <FormattedMessage id="Footer.toAboutPage" />
                          </NamedLink>
                        </li>

                        <li>
                          <NamedLink name="PrivacyPolicyPage" className={css.link}>
                            <FormattedMessage id="Footer.privacyPolicy" />
                          </NamedLink>
                        </li>
                      </ul>
                    </div>
                    <div className={css.searchesExtra}>
                      <ul className={css.list}>
                        <li className={css.listTopElement}>
                          <FormattedMessage id="Footer.marketPlace" />
                        </li>
                        {(currentUser === null ||
                          currentUser?.attributes?.profile?.protectedData?.entrepreneur ===
                            false) && (
                          <li className={css.listItem}>
                            <NamedLink name="SearchPage" className={css.link}>
                              <FormattedMessage id="Footer.searchProperty" />
                            </NamedLink>
                          </li>
                        )}
                        {(currentUser === null ||
                          currentUser?.attributes?.profile?.protectedData?.entrepreneur ===
                            true) && (
                          <li className={css.listItem}>
                            <NamedLink name="NewListingPage" className={css.link}>
                              <FormattedMessage id="Footer.toNewListingPage" />
                            </NamedLink>
                          </li>
                        )}
                        <li className={css.listItem}>
                          <NamedLink name="FAQPage" className={css.link}>
                            <FormattedMessage id="Footer.FAQPage" />
                          </NamedLink>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <p className={css.organizationCopyright}>
                <NamedLink name="LandingPage" className={css.copyrightLink}>
                  <FormattedMessage id="Footer.copyright" />
                </NamedLink>
              </p>
              <div className={css.bottmFooter}>
                <p>
                  Developed by
                  <ExternalLink href={'https://www.bitcanny.com'} className={css.DevelopLink}>
                    <FormattedMessage id="Footer.developlink" />
                  </ExternalLink>
                  {/* <NamedLink name="LandingPage" className={css.DevelopLink}>
                    <FormattedMessage id="Footer.developlink" />
                  </NamedLink> */}
                </p>
              </div>
              <Modal
                id="Footer.contactModal"
                isOpen={showContactModal}
                onClose={() => {
                  if (!currentUser) {
                    form.change('fullName', undefined);

                    form.change('enquiryFromEmail', undefined);
                  }
                  form.change('enquiryMessage', undefined);
                  setShowContactModal(false);
                }}
                usePortal
                onManageDisableScrolling={onManageDisableScrolling}
                className={classNames(css.mapPanel)}
              >
                <div className={css.termsWrapper}>
                  <h2 className={css.modalHeading}>
                    <FormattedMessage id="Footer.contactModalHeading" />
                  </h2>
                  <div className={css.inputDiv}>
                    <FieldTextInput
                      className={classNames(css.formFields)}
                      type="text"
                      name="fullName"
                      id={formId ? `${formId}.fullName` : 'fullName'}
                      label={'Your full name'}
                      placeholder={'Enter your name here...'}
                      // validate={messageRequired}
                      defaultValue={!!currentUser ? profileFullname : undefined}
                    />
                    <FieldTextInput
                      type="email"
                      name="enquiryFromEmail"
                      className={classNames(css.formFields, {
                        [css.errorMessage]: touched.enquiryFromEmail && !!errors.enquiryFromEmail,
                      })}
                      id={formId ? `${formId}.enquiryFromEmail` : 'enquiryFromEmail'}
                      label={'Your email id'}
                      placeholder={'Enter your email id'}
                      validate={validators.composeValidators(emailRequired, emailValid)}
                      defaultValue={!!currentUser ? profileEmailId : undefined}
                    />

                    <FieldTextInput
                      // className={css.field}
                      className={classNames(css.enquiryMessage)}
                      type="textarea"
                      name="enquiryMessage"
                      id={formId ? `${formId}.enquiryMessage` : 'enquiryMessage'}
                      label={'Message'}
                      placeholder={'Write your message here...'}
                      validate={messageRequired}
                    />
                  </div>
                  <PrimaryButton
                    className={css.buttonDetail}
                    type="button"
                    onClick={() => {
                      // console.log('first');
                      handleFormSubmit();
                      form.change('enquiryMessage', undefined);
                      if (!currentUser) {
                        // console.log('no user');
                        form.change('fullName', undefined);

                        form.change('enquiryFromEmail', undefined);
                      }
                      form.resetFieldState('enquiryFromEmail');
                      form.resetFieldState('enquiryMessage');
                      setShowContactModal(false);
                    }}
                    disabled={!values.enquiryFromEmail || !values.enquiryMessage || invalid}
                  >
                    <FormattedMessage id="EnquiryForm.submitButtonText" />
                  </PrimaryButton>
                </div>
              </Modal>
            </div>
          </Form>
        );
      }}
    />
  );
};

FooterComponent.defaultProps = {
  rootClassName: null,
  className: null,
};

FooterComponent.propTypes = {
  rootClassName: string,
  className: string,
  intl: intlShape.isRequired,
};
const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
});
const Footer = compose(connect(mapStateToProps, mapDispatchToProps), injectIntl)(FooterComponent);

export default Footer;
// export default injectIntl(Footer);

{
  /* <li>
                          <NamedLink name="TermsOfServicePage" className={css.link}>
                            <FormattedMessage id="Footer.termsOfUse" />
                          </NamedLink>
                        </li> */
}
{
  /* <p className={css.organizationCopyright}>
                  <NamedLink name="LandingPage" className={css.copyrightLink}>
                    <FormattedMessage id="Footer.copyright" />
                  </NamedLink>
                </p> */
}
{
  /* <li className={css.listItem}>
                  <NamedLink name="NewListingPage" className={css.link}>
                    <FormattedMessage id="Footer.toNewListingPage" />
                  </NamedLink>
                </li> */
}
{
  /* <li className={css.listItem}>
                  <NamedLink name="LandingPage" className={css.link}>
                    <FormattedMessage id="Footer.howitworks" />
                  </NamedLink>
                </li> */
}

{
  /* <li className={css.listItem}>
                  <NamedLink name="LandingPage" className={css.link}>
                    <FormattedMessage id="Footer.toHelpPage" />
                  </NamedLink>
                </li> */
}
{
  /* <li className={css.listItem}>
                  <NamedLink name="AboutPage" to={{ hash: '#contact' }} className={css.link}>
                    <FormattedMessage id="Footer.toContactPage" />
                  </NamedLink>
                </li> */
}

{
  /* <li className={css.listItem}>
                  <NamedLink
                    name="SearchPage"
                    to={{
                      search: '?pub_brand=adidas',
                    }}
                    className={css.link}
                  >
                    <FormattedMessage id="Footer.link1" />
                  </NamedLink>
                </li> */
}
{
  /* <li className={css.listItem}>
                  <NamedLink
                    name="SearchPage"
                    to={{
                      search: '?pub_brand=air_jordan',
                    }}
                    className={css.link}
                  >
                    <FormattedMessage id="Footer.link2" />
                  </NamedLink>
                </li> */
}
{
  /* <li className={css.listItem}>
                  <NamedLink
                    name="SearchPage"
                    to={{
                      search: '?pub_brand=converse',
                    }}
                    className={css.link}
                  >
                    <FormattedMessage id="Footer.link3" />
                  </NamedLink>
                </li> */
}
{
  /* <li className={css.listItem}>
                  <NamedLink name="AboutPage" className={css.link}>
                    <FormattedMessage id="Footer.toReviewPage" />
                  </NamedLink>
                </li> */
}

{
  /* <li className={css.listItem}>
                  <NamedLink
                    name="SearchPage"
                    to={{
                      search: '?pub_brand=new_balance',
                    }}
                    className={css.link}
                  >
                    <FormattedMessage id="Footer.link4" />
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  <NamedLink
                    name="SearchPage"
                    to={{
                      search: '?pub_brand=nike',
                    }}
                    className={css.link}
                  >
                    <FormattedMessage id="Footer.link5" />
                  </NamedLink>
                </li> */
}

{
  /* <li className={css.listItem}>
                  <NamedLink
                    name="SearchPage"
                    to={{
                      search: '?pub_brand=puma',
                    }}
                    className={css.link}
                  >
                    <FormattedMessage id="Footer.link6" />
                  </NamedLink>
                </li> */
}
{
  /* <li className={css.listItem}>
                  <NamedLink
                    name="SearchPage"
                    to={{
                      search: '?pub_brand=ultraboost',
                    }}
                    className={css.link}
                  >
                    <FormattedMessage id="Footer.link7" />
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  <NamedLink
                    name="SearchPage"
                    to={{
                      search: '?pub_brand=vans',
                    }}
                    className={css.link}
                  >
                    <FormattedMessage id="Footer.link8" />
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  <NamedLink
                    name="SearchPage"
                    to={{
                      search: '?pub_brand=yeezy',
                    }}
                    className={css.link}
                  >
                    <FormattedMessage id="Footer.link9" />
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  <NamedLink
                    name="SearchPage"
                    to={{
                      search: '?pub_brand=other',
                    }}
                    className={css.link}
                  >
                    <FormattedMessage id="Footer.link10" />
                  </NamedLink>
                </li> */
}
{
  /* <div className={css.extraLinks}>
              <div className={css.someLinks}>{socialMediaLinks}</div>
              <div className={css.legalMatters}>
                <ul className={css.tosAndPrivacy}>
                  <li>
                    <NamedLink name="TermsOfServicePage" className={css.legalLink}>
                      <FormattedMessage id="Footer.termsOfUse" />
                    </NamedLink>
                  </li>
                  <li>
                    <NamedLink name="PrivacyPolicyPage" className={css.legalLink}>
                      <FormattedMessage id="Footer.privacyPolicy" />
                    </NamedLink>
                  </li>
                </ul>
              </div>
            </div> */
}
{
  /* <div className={css.copyrightAndTermsMobile}>
            <NamedLink name="LandingPage" className={css.organizationCopyrightMobile}>
              <FormattedMessage id="Footer.copyright" />
            </NamedLink>
            <div className={css.tosAndPrivacyMobile}>
              <NamedLink name="PrivacyPolicyPage" className={css.privacy}>
                <FormattedMessage id="Footer.privacy" />
              </NamedLink>
              <NamedLink name="TermsOfServicePage" className={css.terms}>
                <FormattedMessage id="Footer.terms" />
              </NamedLink>
            </div>
          </div> */
}
