import React, { ReactElement } from 'react';
import { components } from 'react-select';
import classNames from 'classnames';
import css from './ReactSelectCustomComponent.module.css';
import { GrCheckboxSelected, GrCheckbox, GrDown, GrUp } from 'react-icons/gr';

const Option =
  (className?: string | null | undefined): ((props: any) => ReactElement) =>
  (props) => {
    return (
      <components.Option
        {...props}
        className={classNames(css.multiOption, className, {
          [css.selectedMutliOption]: props.isSelected,
        })}
      >
        {props.isSelected ? (
          <GrCheckboxSelected className={css.checkbox} />
        ) : (
          <GrCheckbox className={css.checkbox} />
        )}
        <span className={css.mutliOptnLabel}>{props.label}</span>
      </components.Option>
    );
  };

const Control =
  (className?: string | null | undefined): ((props: any) => ReactElement) =>
  ({ children, ...rest }) => {
    return (
      <components.Control
        {...rest}
        className={classNames(css.optionControl, className, {
          [css.emptyOptionControl]: !rest.hasValue,
          [css.focusedControl]: rest.isFocused,
        })}
      >
        {children}
      </components.Control>
    );
  };

const DropdownIndicator =
  (className?: string | null | undefined): ((props: any) => ReactElement) =>
  ({ children, ...rest }) =>
    (
      <components.DropdownIndicator
        {...rest}
        className={classNames(css.dropDownIndicator, className)}
      >
        {rest.selectProps.menuIsOpen ? (
          <GrUp className={css.arrow} />
        ) : (
          <GrDown className={css.arrow} />
        )}
      </components.DropdownIndicator>
    );

const Menu =
  (className?: string | null | undefined): ((props: any) => ReactElement) =>
  ({ children, ...rest }) => {
    return (
      <components.Menu {...rest} className={classNames(css.selectMenu, className)}>
        {children}
      </components.Menu>
    );
  };

const SingleOption =
  (className?: string | null | undefined): ((props: any) => ReactElement) =>
  ({ children, ...rest }) =>
    (
      <components.Option
        {...rest}
        className={classNames(css.singleOption, className, {
          [css.selectedSingleOption]: rest.isSelected,
        })}
      >
        {children}
      </components.Option>
    );

const ValueContainer =
  (className?: string | null | undefined): ((props: any) => ReactElement) =>
  ({ children, ...rest }) =>
    (
      <components.ValueContainer
        {...rest}
        className={classNames(css.valueContainer, className, {
          [css.moreThanTwoValueContainer]: React.Children.count(children) > 3,
        })}
      >
        {children}
      </components.ValueContainer>
    );

const MultiValue =
  (className?: string | null | undefined): ((props: any) => ReactElement) =>
  ({ children, ...rest }) => {
    return (
      <div className={classNames(css.value, className)}>
        <components.MultiValue {...rest}>{children}</components.MultiValue>
      </div>
    );
  };

const IndicatorsContainer =
  (className?: null | string): ((props: any) => ReactElement) =>
  ({ children, ...rest }) => {
    return (
      <components.IndicatorsContainer {...rest} className={className}>
        {children}
      </components.IndicatorsContainer>
    );
  };

export {
  Option,
  Control,
  DropdownIndicator,
  Menu,
  SingleOption,
  ValueContainer,
  MultiValue,
  IndicatorsContainer,
};
