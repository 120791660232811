import React, { Component } from 'react';
import PropTypes, { any } from 'prop-types';
import classNames from 'classnames';

// Import configs and util modules
import config from '../../../../config';
import { FormattedMessage } from '../../../../util/reactIntl';
import { LISTING_STATE_DRAFT } from '../../../../util/types';
import { ensureOwnListing } from '../../../../util/data';
import { types as sdkTypes } from '../../../../util/sdkLoader';
const { func, object, string, bool } = PropTypes;
// Import shared components
import { ListingLink } from '../../../../components';
import moment from 'moment';
// Import modules from this directory
import EditListingBusinessDetailsForm from './EditListingBusinessDetailsForm';
import css from './EditListingBusinessDetailsPannel.module.css';
import axios from 'axios';
import { apiBaseUrl } from '../../../../util/api';
import { findOptionsForSelectFilter } from '../../../../util/search';

const { Money } = sdkTypes;

const defaultProps = {
  className: null,
  rootClassName: null,
  listing: null,
};
const propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};
type proptype = any & typeof propTypes & typeof defaultProps;

class EditListingBusinessDetailsPannel extends Component<proptype, any> {
  static defaultProps: typeof defaultProps;
  static propTypes: typeof propTypes;
  constructor(props: any) {
    super(props);

    this.getInitialValues = this.getInitialValues.bind(this);

    this.state = {
      initialValues: this.getInitialValues(),
      verifiedDatas: [],
    };
  }

  getInitialValues() {
    const { listing } = this.props;
    if (
      !!listing?.attributes &&
      !!listing?.attributes?.publicData &&
      !!listing?.attributes?.privateData
    ) {
      const {
        avgMonthlySale,
        avgMonthlyCost,
        bookValue,
        businessName,
        liabilityAmnt,
        monthlyEbitda,
        monthlyNetIncome,
        monthlyOperatingCash,
        valuableAssetDesc,
        workingCapital,
        listingVerificableKeys,
      } = listing.attributes.privateData;
      const {
        businessEstDate,
        businessDesc,
        businessEmpCount,
        businessMarket,
        businessObjective,
        businessPitch,
        businessProgress,
        businessSector,
        businessType,
        businessWeb,
      } = listing.attributes.publicData;
      return {
        businessEstDate: !!businessEstDate ? businessEstDate : undefined,
        businessDesc,
        businessEmpCount,
        businessMarket,
        businessObjective,
        businessPitch,
        businessProgress,
        businessSector,
        businessType,
        avgMonthlySale,
        avgMonthlyCost,
        bookValue,
        businessName,
        liabilityAmnt,
        monthlyEbitda,
        monthlyNetIncome,
        monthlyOperatingCash,
        valuableAssetDesc,
        businessWeb,
        workingCapital,
        listingVerificableKeys,
      };
    }

    return {};

    // const currentListing = ensureOwnListing(listing);
    // const { geolocation, publicData, price } = currentListing.attributes;
    // // Only render current search if full place object is available in the URL params
    // // TODO bounds are missing - those need to be queried directly from Google Places
    // const locationFieldsPresent = publicData?.location?.address && geolocation;
    // const location = publicData?.location
    //   ? publicData.location
    //   : { address: null, building: null };
    // const { address = null, building = null } = location;
    // // const {
    // //   shippingEnabled,
    // //   pickupEnabled,
    // //   shippingPriceInSubunitsOneItem,
    // //   shippingPriceInSubunitsAdditionalItems,
    // // } = publicData;
    // // const deliveryOptions = [];
    // // if (shippingEnabled) {
    // //   deliveryOptions.push('shipping');
    // // }
    // // if (pickupEnabled) {
    // //   deliveryOptions.push('pickup');
    // // }
    // // const currency = price?.currency || config.currency;
    // // const shippingOneItemAsMoney = shippingPriceInSubunitsOneItem
    // //   ? new Money(shippingPriceInSubunitsOneItem, currency)
    // //   : null;
    // // const shippingAdditionalItemsAsMoney = shippingPriceInSubunitsAdditionalItems
    // //   ? new Money(shippingPriceInSubunitsAdditionalItems, currency)
    // //   : null;
    // // return {
    // //   building,
    // //   location: locationFieldsPresent
    // //     ? {
    // //         search: address,
    // //         selectedPlace: { address, origin: geolocation },
    // //       }
    // //     : { search: undefined, selectedPlace: undefined },
    // //   deliveryOptions,
    // //   shippingPriceInSubunitsOneItem: shippingOneItemAsMoney,
    // //   shippingPriceInSubunitsAdditionalItems: shippingAdditionalItemsAsMoney,
    // // };
  }

  componentDidMount() {
    const { listing } = this.props;

    const verifiedDatasFromPD = listing?.attributes?.metadata?.verifiedDatas;
    // console.log(321, verifiedDatasFromPD, this.state.verifiedDatas);
    if (
      verifiedDatasFromPD &&
      verifiedDatasFromPD?.length > 0 &&
      this.state.verifiedDatas?.length === 0
    ) {
      this.setState({ verifiedDatas: verifiedDatasFromPD });
      // console.log('first');
    }
  }
  render() {
    const {
      className,
      rootClassName,
      listing,
      disabled,
      ready,
      onSubmit,
      onChange,
      submitButtonText,
      panelUpdated,
      updateInProgress,
      errors,
    } = this.props;
    // console.log(258, this.state.verifiedDatas, this.state.initialValues);
    const verifiedDatasArray = this.state.verifiedDatas;
    // console.log(258, this.state.verifiedDatas, this.state.initialValues, verifiedDatasArray);

    const classes = classNames(rootClassName || css.root, className);
    const currentListing = ensureOwnListing(listing);

    const isPublished =
      currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
    const verificableData = findOptionsForSelectFilter('verificableData', config.custom.filters);

    let listingVerificableKeys = [...(this.state.initialValues?.listingVerificableKeys || [])];
    // console.log(333, this.state.initialValues.listingVerificableKeys);
    return (
      <div className={classes}>
        <h1 className={css.title}>Business Details</h1>
        <EditListingBusinessDetailsForm
          className={css.form}
          initialValues={this.state.initialValues}
          onSubmit={(values: any) => {
            const {
              businessEstDate,
              businessDesc,
              businessEmpCount,
              businessMarket,
              businessObjective,
              businessPitch,
              businessProgress,
              businessSector,
              businessType,
              avgMonthlySale,
              avgMonthlyCost,
              bookValue,
              businessName,
              liabilityAmnt,
              monthlyEbitda,
              monthlyNetIncome,
              monthlyOperatingCash,
              valuableAssetDesc,
              workingCapital,
              businessWeb,
            } = values;

            verificableData.forEach((data: { label: string; value: string; key: string }) => {
              if (values[data.key] !== undefined && !listingVerificableKeys.includes(data.key)) {
                listingVerificableKeys?.push(data.key);
              }
            });

            // console.log(
            //   321,
            //   verificableData,
            //   this.state.initialValues.listingVerificableKeys,
            //   values
            // );

            const changedValues: string[] = [];

            if (bookValue !== this.state.initialValues.bookValue) {
              changedValues.push('bookValue');
            }
            if (liabilityAmnt !== this.state.initialValues.liabilityAmnt) {
              changedValues.push('liabilityAmnt');
            }
            if (valuableAssetDesc !== this.state.initialValues.valuableAssetDesc) {
              changedValues.push('valuableAssetDesc');
            }
            if (monthlyNetIncome !== this.state.initialValues.monthlyNetIncome) {
              changedValues.push('monthlyNetIncome');
            }
            if (monthlyEbitda !== this.state.initialValues.monthlyEbitda) {
              changedValues.push('monthlyEbitda');
            }
            if (workingCapital !== this.state.initialValues.workingCapital) {
              changedValues.push('workingCapital');
            }
            if (monthlyOperatingCash !== this.state.initialValues.monthlyOperatingCash) {
              changedValues.push('monthlyOperatingCash');
            }
            if (avgMonthlyCost !== this.state.initialValues.avgMonthlyCost) {
              changedValues.push('avgMonthlyCost');
            }
            if (avgMonthlySale !== this.state.initialValues.avgMonthlySale) {
              changedValues.push('avgMonthlySale');
            }
            if (businessEmpCount !== this.state.initialValues.businessEmpCount) {
              changedValues.push('businessEmpCount');
            }
            if (businessType !== this.state.initialValues.businessType) {
              changedValues.push('businessType');
            }
            if (businessEstDate !== this.state.initialValues.businessEstDate) {
              changedValues.push('businessEstDate');
            }

            if (changedValues?.length > 0) {
              const listingId = listing?.id?.uuid;
              const updatedVerifiedArray = verifiedDatasArray?.filter(
                (val: string) => !changedValues.includes(val)
              );
              listingVerificableKeys = listingVerificableKeys.filter((val) => {
                const isValueChanged = changedValues.includes(val);
                return isValueChanged ? values[val] !== undefined : true;
              });

              const hasAnyValueChanged = changedValues.some((val) => values[val] !== undefined);
              // console.log(111, updatedVerifiedArray, listingVerificableKeys, hasAnyValueChanged);
              axios
                .post(`${apiBaseUrl()}/api/toggleVerify`, {
                  verifiedDatas: updatedVerifiedArray,
                  listingId,
                })
                .then((res) => {
                  const verifiedDatasFromPD =
                    res?.data?.data?.data?.attributes?.metadata?.verifiedDatas;
                  // console.log(321, verifiedDatasFromPD);
                  this.setState({ verifiedDatas: verifiedDatasFromPD });

                  // to setVerificationMailSent to flase if the the listing was verified and now the data has been changed

                  const hasListingVerificationMailSent =
                    res?.data?.data?.data?.attributes?.publicData?.hasListingVerificationMailSent;
                  // console.log(
                  //   321,

                  //   hasListingVerificationMailSent,
                  //   hasListingVerificationMailSent && hasAnyValueChanged
                  // );
                  if (hasListingVerificationMailSent && hasAnyValueChanged) {
                    // console.log('verify mail set false');
                    axios.post(`${apiBaseUrl()}/api/setVerificationMailSent`, {
                      id: listingId,
                      hasListingVerificationMailSent: false,
                    });
                  }
                })
                .catch((err) => {
                  console.log(err);
                });

              // if (listing?.attributes?.metadata?.isVeriified && hasAnyValueChanged) {
              //   console.log('has only changed not removed');

              //   axios.post(`${apiBaseUrl()}/api/verifyListing`, {
              //     id: listingId,
              //     isVerified: false,
              //   });
              // }
            }

            const updateValues = {
              publicData: {
                businessEstDate,
                businessEstDateSearch: moment(businessEstDate, 'YYYYMMDD')?.unix(),
                businessDesc,
                businessEmpCount,
                businessMarket,
                businessObjective,
                businessPitch,
                businessProgress,
                businessSector,
                businessType,
                businessWeb,
                businessSectorSearch: businessSector.value,
                businessTypeSearch: businessType.value,
                businessEmpCountSearch: businessEmpCount.value,
              },
              privateData: {
                avgMonthlySale,
                avgMonthlyCost,
                bookValue,
                businessName,
                liabilityAmnt,
                monthlyEbitda,
                monthlyNetIncome,
                monthlyOperatingCash,
                valuableAssetDesc,
                workingCapital,
                listingVerificableKeys: listingVerificableKeys,
              },
            };
            this.setState({
              initialValues: {
                businessEstDate,
                businessDesc,
                businessEmpCount,
                businessMarket,
                businessObjective,
                businessPitch,
                businessProgress,
                businessSector,
                businessType,
                avgMonthlySale,
                avgMonthlyCost,
                businessWeb,
                bookValue,
                businessName,
                liabilityAmnt,
                monthlyEbitda,
                monthlyNetIncome,
                monthlyOperatingCash,
                valuableAssetDesc,
                workingCapital,
                listingVerificableKeys,
              },
            });
            onSubmit(updateValues);
          }}
          onChange={onChange}
          saveActionMsg={submitButtonText}
          disabled={disabled}
          ready={ready}
          updated={panelUpdated}
          updateInProgress={updateInProgress}
          fetchErrors={errors}
          autoFocus
        />
      </div>
    );
  }
}

EditListingBusinessDetailsPannel.defaultProps = defaultProps;

EditListingBusinessDetailsPannel.propTypes = propTypes;

export default EditListingBusinessDetailsPannel;
