import React, { Component } from 'react';
import PropTypes, { any } from 'prop-types';
import classNames from 'classnames';

// Import configs and util modules
import config from '../../../../config';
import { FormattedMessage } from '../../../../util/reactIntl';
import { LISTING_STATE_DRAFT } from '../../../../util/types';
import { ensureOwnListing } from '../../../../util/data';
import { types as sdkTypes } from '../../../../util/sdkLoader';
const { func, object, string, bool } = PropTypes;
// Import shared components
import { ListingLink } from '../../../../components';
import moment from 'moment';
// Import modules from this directory
import EditListingQuoteBusinessDetailsForm from './EditListingQuoteBusinessDetailsForm';
import css from './EditListingQuoteBusinessDetailsPannel.module.css';

const { Money } = sdkTypes;

const defaultProps = {
  className: null,
  rootClassName: null,
  listing: null,
};
const propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};
type proptype = any & typeof propTypes & typeof defaultProps;

class EditListingQuoteBusinessDetailsPannel extends Component<proptype, any> {
  static defaultProps: typeof defaultProps;
  static propTypes: typeof propTypes;
  constructor(props: any) {
    super(props);

    this.getInitialValues = this.getInitialValues.bind(this);

    this.state = {
      initialValues: this.getInitialValues(),
    };
  }

  getInitialValues() {
    const { listing } = this.props;
    if (
      !!listing?.attributes &&
      !!listing?.attributes?.publicData &&
      !!listing?.attributes?.privateData
    ) {
      const {
        avgMonthlySale,
        avgMonthlyCost,
        bookValue,
        businessName,
        liabilityAmnt,
        monthlyEbitda,
        monthlyNetIncome,
        monthlyOperatingCash,
        valuableAssetDesc,
        workingCapital,
      } = listing.attributes.privateData;
      const {
        businessEstDate,
        businessDesc,
        businessEmpCount,
        businessMarket,
        businessObjective,
        businessPitch,
        businessProgress,
        businessSector,
        businessType,
        businessWeb,
      } = listing.attributes.publicData;
      return {
        businessEstDate: !!businessEstDate ? businessEstDate : undefined,
        businessDesc,
        businessEmpCount,
        businessMarket,
        businessObjective,
        businessPitch,
        businessProgress,
        businessSector,
        businessType,
        avgMonthlySale,
        avgMonthlyCost,
        bookValue,
        businessName,
        liabilityAmnt,
        monthlyEbitda,
        monthlyNetIncome,
        monthlyOperatingCash,
        valuableAssetDesc,
        businessWeb,
        workingCapital,
      };
    }

    return {};

    // const currentListing = ensureOwnListing(listing);
    // const { geolocation, publicData, price } = currentListing.attributes;
    // // Only render current search if full place object is available in the URL params
    // // TODO bounds are missing - those need to be queried directly from Google Places
    // const locationFieldsPresent = publicData?.location?.address && geolocation;
    // const location = publicData?.location
    //   ? publicData.location
    //   : { address: null, building: null };
    // const { address = null, building = null } = location;
    // // const {
    // //   shippingEnabled,
    // //   pickupEnabled,
    // //   shippingPriceInSubunitsOneItem,
    // //   shippingPriceInSubunitsAdditionalItems,
    // // } = publicData;
    // // const deliveryOptions = [];
    // // if (shippingEnabled) {
    // //   deliveryOptions.push('shipping');
    // // }
    // // if (pickupEnabled) {
    // //   deliveryOptions.push('pickup');
    // // }
    // // const currency = price?.currency || config.currency;
    // // const shippingOneItemAsMoney = shippingPriceInSubunitsOneItem
    // //   ? new Money(shippingPriceInSubunitsOneItem, currency)
    // //   : null;
    // // const shippingAdditionalItemsAsMoney = shippingPriceInSubunitsAdditionalItems
    // //   ? new Money(shippingPriceInSubunitsAdditionalItems, currency)
    // //   : null;
    // // return {
    // //   building,
    // //   location: locationFieldsPresent
    // //     ? {
    // //         search: address,
    // //         selectedPlace: { address, origin: geolocation },
    // //       }
    // //     : { search: undefined, selectedPlace: undefined },
    // //   deliveryOptions,
    // //   shippingPriceInSubunitsOneItem: shippingOneItemAsMoney,
    // //   shippingPriceInSubunitsAdditionalItems: shippingAdditionalItemsAsMoney,
    // // };
  }

  render() {
    const {
      className,
      rootClassName,
      listing,
      disabled,
      ready,
      onSubmit,
      onChange,
      submitButtonText,
      panelUpdated,
      updateInProgress,
      errors,
    } = this.props;
    const classes = classNames(rootClassName || css.root, className);
    const currentListing = ensureOwnListing(listing);

    const isPublished =
      currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;

    return (
      <div className={classes}>
        <h1 className={css.title}>Business Details</h1>
        <EditListingQuoteBusinessDetailsForm
          className={css.form}
          initialValues={this.state.initialValues}
          onSubmit={(values: any) => {
            const {
              businessEstDate,
              businessDesc,
              businessEmpCount,
              businessMarket,
              businessObjective,
              businessPitch,
              businessProgress,
              businessSector,
              businessType,
              avgMonthlySale,
              avgMonthlyCost,
              bookValue,
              businessName,
              liabilityAmnt,
              monthlyEbitda,
              monthlyNetIncome,
              monthlyOperatingCash,
              valuableAssetDesc,
              workingCapital,
              businessWeb,
            } = values;
            const updateValues = {
              publicData: {
                businessEstDate,
                businessEstDateSearch: moment(businessEstDate, 'YYYYMMDD')?.unix(),
                businessDesc,
                businessEmpCount,
                businessMarket,
                businessObjective,
                businessPitch,
                businessProgress,
                businessSector,
                businessType,
                businessWeb,
                businessSectorSearch: businessSector.value,
                businessTypeSearch: businessType.value,
                businessEmpCountSearch: businessEmpCount.value,
              },
              privateData: {
                avgMonthlySale,
                avgMonthlyCost,
                bookValue,
                businessName,
                liabilityAmnt,
                monthlyEbitda,
                monthlyNetIncome,
                monthlyOperatingCash,
                valuableAssetDesc,
                workingCapital,
              },
            };
            this.setState({
              initialValues: {
                businessEstDate,
                businessDesc,
                businessEmpCount,
                businessMarket,
                businessObjective,
                businessPitch,
                businessProgress,
                businessSector,
                businessType,
                avgMonthlySale,
                avgMonthlyCost,
                businessWeb,
                bookValue,
                businessName,
                liabilityAmnt,
                monthlyEbitda,
                monthlyNetIncome,
                monthlyOperatingCash,
                valuableAssetDesc,
                workingCapital,
              },
            });
            onSubmit(updateValues);
          }}
          onChange={onChange}
          saveActionMsg={submitButtonText}
          disabled={disabled}
          ready={ready}
          updated={panelUpdated}
          updateInProgress={updateInProgress}
          fetchErrors={errors}
          autoFocus
        />
      </div>
    );
  }
}

EditListingQuoteBusinessDetailsPannel.defaultProps = defaultProps;

EditListingQuoteBusinessDetailsPannel.propTypes = propTypes;

export default EditListingQuoteBusinessDetailsPannel;
