import React, { ReactElement, useEffect } from 'react';
import { bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import Select from 'react-select';
import { isInclusivelyAfterDay } from 'react-dates';
import TextField from '@material-ui/core/TextField';
// Import configs and util modules
import config from '../../../../config';
import { intlShape, injectIntl, FormattedMessage } from '../../../../util/reactIntl';
import { propTypes } from '../../../../util/types';
import {
  autocompleteSearchRequired,
  autocompletePlaceSelected,
  composeValidators,
  required,
  maxWordLength,
  urlFormatValid,
} from '../../../../util/validators';
// Import shared components
import {
  Form,
  LocationAutocompleteInputField,
  Button,
  FieldCurrencyInput,
  FieldTextInput,
  FieldCheckbox,
  FieldDateInput,
} from '../../../../components';
import {
  businessStat,
  businessTypes,
  employes,
} from '../../../../config/marketplace-custom-config';
import {
  Control,
  DropdownIndicator,
  IndicatorsContainer,
  Menu,
  SingleOption,
  ValueContainer,
} from '../../../../components/ReactSelectCustomComponent/ReactSelectCustomComponent';
import css from './EditListingQuoteBusinessDetailsForm.module.css';
import { ReactComponent as SubIcon } from '../../../../assets/subtraction.svg';
import { ReactComponent as LockIcon } from '../../../../assets/lock.svg';
import moment from 'moment';

function identity<Type>(v: Type): Type {
  return v;
}

const BUSINESS_DESC_LIMIT1 = 300;
const BUSINESS_DESC_LIMIT2 = 100;
const BUSINESS_DESC_LIMIT3 = 50;
const BUSINESS_DESC_LIMIT4 = 20;
const BUSINESS_NAME: string = 'businessName';
const BUSINESS_SECTOR: string = 'businessSector';
const BUSINESS_TYPE: string = 'businessType';
const BUSINESS_DESC: string = 'businessDesc';
const BUSINESS_WEB: string = 'businessWeb';
const BUSINESS_EMP_COUNT: string = 'businessEmpCount';
const BUSINESS_PITCH: string = 'businessPitch';
const BUSINESS_PROGRESS: string = 'businessProgress';
const BUSINESS_MARKET: string = 'businessMarket';
const BUSINESS_OBJECTIVE: string = 'businessObjective';
const MONTHLY_SALES: string = 'avgMonthlySale';
const MONTHLY_COST: string = 'avgMonthlyCost';
const MONTHLY_OPERATING_CASH: string = 'monthlyOperatingCash';
const WORKING_CAPITAL: string = 'workingCapital';
const MONTHLY_EBITDA: string = 'monthlyEbitda';
const MONTHLY_NET_INCOME: string = 'monthlyNetIncome';
const VALUEABLE_ASSET_DESC: string = 'valuableAssetDesc';
const LIABILITY_AMNT: string = 'liabilityAmnt';
const BOOK_VALUE: string = 'bookValue';
const BUSINESS_DATE: string = 'businessEstDate';

function getMaxLengthValidator(msgId: string, maxLength: number, intl: any): any {
  return maxWordLength(
    intl.formatMessage(
      {
        id: msgId,
      },
      { maxLength }
    ),
    maxLength
  );
}

function userTextareaDiv(
  label: string,
  placeholder: string,
  isInvalid: boolean,
  inputId: string,
  validator?: any,
  helpTextId?: string,
  isPrivate: boolean = false,
  className?: string,
  invalidClassName?: string
): ReactElement {
  return (
    <div
      className={classNames(css.textareaDiv, className, {
        [css.invalidTextArea]: isInvalid,
        [css.invalidClassName]: !!invalidClassName && isInvalid,
        [css.privateTextareaDiv]: isPrivate,
        [css.invalidPrivateTextarea]: isPrivate && isInvalid,
      })}
    >
      <FieldTextInput
        className={classNames(css.input, {
          [css.invalidInput]: isInvalid,
        })}
        type="textarea"
        name={inputId}
        id={inputId}
        label={label}
        placeholder={placeholder}
        validate={validator}
      />
      <SubIcon className={css.subIcon} />
      {helpTextId ? (
        <span className={css.helpText}>
          <FormattedMessage id={helpTextId} />
        </span>
      ) : null}
      {isPrivate && (
        <div className={css.privateMsgDiv}>
          <LockIcon />
          <FormattedMessage id="EditListingBusinessDetailsForm.businessHelpText5" />
        </div>
      )}
    </div>
  );
}

function EditListingQuoteBusinessDetailsFormComponent(props: any) {
  return (
    <FinalForm
      {...props}
      render={(formRenderProps: any) => {
        const {
          form,
          autoFocus,
          className,
          disabled,
          ready,
          handleSubmit,
          intl,
          pristine,
          invalid,
          saveActionMsg,
          updated,
          updateInProgress,
          fetchErrors,
          values,
          touched,
          errors,
          initialValues,
        } = formRenderProps;
        // This is a bug fix for Final Form.
        // Without this, React will return a warning:
        //   "Cannot update a component (`ForwardRef(Field)`)
        //   while rendering a different component (`ForwardRef(Field)`)"
        // This seems to happen because validation calls listeneres and
        // that causes state to change inside final-form.
        // https://github.com/final-form/react-final-form/issues/751
        //
        // TODO: it might not be worth the trouble to show these fields as disabled,
        // if this fix causes trouble in future dependency updates.
        const { pauseValidation, resumeValidation } = form;
        pauseValidation(false);
        useEffect(() => resumeValidation(), [values]);

        const {
          businessProgress,
          businessMarket,
          businessObjective,
          avgMonthlySale,
          avgMonthlyCost,
          monthlyOperatingCash,
          workingCapital,
          monthlyEbitda,
          monthlyNetIncome,
          valuableAssetDesc,
          liabilityAmnt,
          bookValue,
          businessSector,
          businessType,
          businessEmpCount,
          businessEstDate,
        } = values;
        // console.log(3695, values, businessEstDate);
        const businessNameLabel = intl.formatMessage({
          id: 'EditListingBusinessDetailsForm.businessNameLabel',
        });

        const businessNamePlaceholder = intl.formatMessage({
          id: 'EditListingBusinessDetailsForm.businessNamePlaceholder',
        });

        const businessSectorLabel = intl.formatMessage({
          id: 'EditListingBusinessDetailsForm.businessSectorLabel',
        });

        const businessSectorPlaceholder = intl.formatMessage({
          id: 'EditListingBusinessDetailsForm.businessSectorPlaceholder',
        });

        const businessTypeLabel = intl.formatMessage({
          id: 'EditListingBusinessDetailsForm.businessTypeLabel',
        });

        const businessTypePlaceholder = intl.formatMessage({
          id: 'EditListingBusinessDetailsForm.businessTypePlaceholder',
        });

        const businessDescLabel = intl.formatMessage({
          id: 'EditListingBusinessDetailsForm.businessDescLabel',
        });

        const businessDescPlaceholder = intl.formatMessage({
          id: 'EditListingBusinessDetailsForm.businessDescPlaceholder',
        });

        const businessEmpLabel = intl.formatMessage({
          id: 'EditListingBusinessDetailsForm.businessEmpLabel',
        });

        const businessEmpPlaceholder = intl.formatMessage({
          id: 'EditListingBusinessDetailsForm.businessEmpPlaceholder',
        });

        const businessPitchLabel = intl.formatMessage({
          id: 'EditListingBusinessDetailsForm.businessPitchLabel',
        });

        const businessPitchPlaceholder = intl.formatMessage({
          id: 'EditListingBusinessDetailsForm.businessPitchPlaceholder',
        });

        const businessProgressLabel = intl.formatMessage({
          id: 'EditListingBusinessDetailsForm.businessProgressLabel',
        });

        const businessProgressPlaceholder = intl.formatMessage({
          id: 'EditListingBusinessDetailsForm.businessProgressPlaceholder',
        });

        const businessMarketLabel = intl.formatMessage({
          id: 'EditListingBusinessDetailsForm.businessMarketLabel',
        });

        const businessMarketPlaceholder = intl.formatMessage({
          id: 'EditListingBusinessDetailsForm.businessMarketPlaceholder',
        });

        const businessObjectiveLabel = intl.formatMessage({
          id: 'EditListingBusinessDetailsForm.businessObjectiveLabel',
        });

        const businessObjectivePlaceholder = intl.formatMessage({
          id: 'EditListingBusinessDetailsForm.businessObjectivePlaceholder',
        });

        const businessMonthlySalesLabel = intl.formatMessage({
          id: 'EditListingBusinessDetailsForm.businessMonthlySalesLabel',
        });

        const businessMonthlySalesPlaceholder = intl.formatMessage({
          id: 'EditListingBusinessDetailsForm.businessMonthlySalesPlaceholder',
        });

        const businessMonthlyCostLabel = intl.formatMessage({
          id: 'EditListingBusinessDetailsForm.businessMonthlyCostLabel',
        });

        const businessMonthlyCostPlaceholder = intl.formatMessage({
          id: 'EditListingBusinessDetailsForm.businessMonthlyCostPlaceholder',
        });

        const businessMonthlyOperatingCashLabel = intl.formatMessage({
          id: 'EditListingBusinessDetailsForm.businessMonthlyOperatingCashLabel',
        });

        const businessMonthlyOperatingCashPlaceholder = intl.formatMessage({
          id: 'EditListingBusinessDetailsForm.businessMonthlyOperatingCashPlaceholder',
        });

        const businessWorkingCapitalLabel = intl.formatMessage({
          id: 'EditListingBusinessDetailsForm.businessWorkingCapitalLabel',
        });

        const businessWorkingCapitalPlaceholder = intl.formatMessage({
          id: 'EditListingBusinessDetailsForm.businessWorkingCapitalPlaceholder',
        });

        const businessMonthlyEbitdaLabel = intl.formatMessage({
          id: 'EditListingBusinessDetailsForm.businessMonthlyEbitdaLabel',
        });

        const businessMonthlyEbitdaPlaceholder = intl.formatMessage({
          id: 'EditListingBusinessDetailsForm.businessMonthlyEbitdaPlaceholder',
        });

        const businessMonthlyNetIncomeLabel = intl.formatMessage({
          id: 'EditListingBusinessDetailsForm.businessMonthlyNetIncomeLabel',
        });

        const businessMonthlyNetIncomePlaceholder = intl.formatMessage({
          id: 'EditListingBusinessDetailsForm.businessMonthlyNetIncomePlaceholder',
        });

        const businessValueableAssetDescLabel = intl.formatMessage({
          id: 'EditListingBusinessDetailsForm.businessValueableAssetDescLabel',
        });

        const businessValueableAssetDescPlaceholder = intl.formatMessage({
          id: 'EditListingBusinessDetailsForm.businessValueableAssetDescPlaceholder',
        });

        const businessLiabilityAmntLabel = intl.formatMessage({
          id: 'EditListingBusinessDetailsForm.businessLiabilityAmntLabel',
        });

        const businessLiabilityAmntPlaceholder = intl.formatMessage({
          id: 'EditListingBusinessDetailsForm.businessLiabilityAmntPlaceholder',
        });

        const businessBookValueLabel = intl.formatMessage({
          id: 'EditListingBusinessDetailsForm.businessBookValueLabel',
        });

        const businessBookValuePlaceholder = intl.formatMessage({
          id: 'EditListingBusinessDetailsForm.businessBookValuePlaceholder',
        });

        const businessBusinessDateLabel = intl.formatMessage({
          id: 'EditListingBusinessDetailsForm.businessBusinessDateLabel',
        });

        const businessBusinessDatePlaceholder = intl.formatMessage({
          id: 'EditListingBusinessDetailsForm.businessBusinessDatePlaceholder',
        });

        const businessNameRequired = required(
          intl.formatMessage({ id: 'EditListingBusinessDetailsForm.businessNameRequiredMessage' })
        );

        const businessDescRequired = required(
          intl.formatMessage({ id: 'EditListingBusinessDetailsForm.businessDescRequiredMessage' })
        );

        const businessDescMaxLength = maxWordLength(
          intl.formatMessage(
            {
              id: 'EditListingBusinessDetailsForm.businessDeschMaxlengthMessage',
            },
            { maxLength: BUSINESS_DESC_LIMIT2 }
          ),
          BUSINESS_DESC_LIMIT2
        );

        const businessDescValidator = composeValidators(
          businessDescRequired,
          businessDescMaxLength
        );

        const businessPitchRequired = required(
          intl.formatMessage({ id: 'EditListingBusinessDetailsForm.businessPitchRequiredMessage' })
        );

        const businessBusinessDateRequired = required(
          intl.formatMessage({
            id: 'EditListingBusinessDetailsForm.businessBusinessDateRequiredMessage',
          })
        );
        const businessPitchMaxLength = getMaxLengthValidator(
          'EditListingBusinessDetailsForm.businessPitchMaxlengthMessage',
          BUSINESS_DESC_LIMIT1,
          intl
        );
        const businessPitchValidtor = composeValidators(
          businessPitchRequired,
          businessPitchMaxLength
        );

        const businessProgressMaxLength = getMaxLengthValidator(
          'EditListingBusinessDetailsForm.businessProgressMaxlengthMessage',
          BUSINESS_DESC_LIMIT1,
          intl
        );

        const businessMarketMaxLength = getMaxLengthValidator(
          'EditListingBusinessDetailsForm.businessMarketMaxlengthMessage',
          BUSINESS_DESC_LIMIT1,
          intl
        );

        const businessObjectiveMaxLength = getMaxLengthValidator(
          'EditListingBusinessDetailsForm.businessObjectiveMaxlengthMessage',
          BUSINESS_DESC_LIMIT1,
          intl
        );

        const businessMonthlySalesMaxLength = getMaxLengthValidator(
          'EditListingBusinessDetailsForm.businessMonthlySalesMaxlengthMessage',
          BUSINESS_DESC_LIMIT4,
          intl
        );

        const businessMonthlyCostMaxLength = getMaxLengthValidator(
          'EditListingBusinessDetailsForm.businessMonthlyCostMaxlengthMessage',
          BUSINESS_DESC_LIMIT4,
          intl
        );

        const businessMonthlyOperatingCashMaxLength = getMaxLengthValidator(
          'EditListingBusinessDetailsForm.businessMonthlyOperatingCashMaxlengthMessage',
          BUSINESS_DESC_LIMIT4,
          intl
        );

        const businessWorkingCapitalMaxLength = getMaxLengthValidator(
          'EditListingBusinessDetailsForm.businessWorkingCapitalMaxlengthMessage',
          BUSINESS_DESC_LIMIT4,
          intl
        );

        const businessMonthlyEbitdaMaxLength = getMaxLengthValidator(
          'EditListingBusinessDetailsForm.businessMonthlyEbitdaMaxlengthMessage',
          BUSINESS_DESC_LIMIT4,
          intl
        );

        const businessMonthlyNetIncomeMaxLength = getMaxLengthValidator(
          'EditListingBusinessDetailsForm.businessMonthlyNetIncomeMaxlengthMessage',
          BUSINESS_DESC_LIMIT4,
          intl
        );

        const businessValueableAssetDescMaxLength = getMaxLengthValidator(
          'EditListingBusinessDetailsForm.businessValueableAssetDescMaxlengthMessage',
          BUSINESS_DESC_LIMIT3,
          intl
        );

        const businessLiabilityAmntMaxLength = getMaxLengthValidator(
          'EditListingBusinessDetailsForm.businessLiabilityAmntMaxlengthMessage',
          BUSINESS_DESC_LIMIT3,
          intl
        );

        const businessBookValueMaxLength = getMaxLengthValidator(
          'EditListingBusinessDetailsForm.businessBookValueMaxlengthMessage',
          BUSINESS_DESC_LIMIT3,
          intl
        );

        const urlRequiredMessage = intl.formatMessage({
          id: 'EditListingBusinessDetailsForm.urlRequired',
        });
        const urlRequired = required(urlRequiredMessage);
        const urlInvalidMessage = intl.formatMessage({
          id: 'EditListingBusinessDetailsForm.urlInvalid',
        });
        const urlValid = urlFormatValid(urlInvalidMessage);
        const classes = classNames(css.root, className);
        const submitReady = (updated && pristine) || ready;
        const submitInProgress = updateInProgress;
        const submitDisabled =
          invalid ||
          disabled ||
          submitInProgress ||
          !businessEmpCount ||
          !businessSector ||
          !businessType ||
          !businessEstDate;
        // console.log(2123, submitDisabled);

        // const shippingLabel = intl.formatMessage({
        //   id: 'EditListingListingDetailsForm.shippingLabel',
        // });
        // const pickupLabel = intl.formatMessage({ id: 'EditListingListingDetailsForm.pickupLabel' });

        const isFieldInvalid = (fieldName: string): boolean =>
          touched[fieldName] && !!errors[fieldName];

        const isOutsideRange = (day: any): boolean => {
          return isInclusivelyAfterDay(day, moment().add(1, 'day'));
        };
        const isWebaddValid = (fieldName: string): boolean =>
          touched[fieldName] && !!errors[fieldName];
        return (
          <Form className={classes} onSubmit={handleSubmit}>
            <section className={css.businessDetailsSection}>
              {/* <h3 className={css.sectionHeading}>
                <FormattedMessage id="EditListingBusinessDetailsForm.businessHeading" />
              </h3> */}
              <div className={css.inputDiv}>
                <FieldTextInput
                  className={classNames(css.input, {
                    [css.invalidInput]: isFieldInvalid(BUSINESS_NAME),
                  })}
                  type="text"
                  name={BUSINESS_NAME}
                  id={BUSINESS_NAME}
                  label={businessNameLabel}
                  placeholder={businessNamePlaceholder}
                  validate={businessNameRequired}
                />
                <div className={css.privateMsgDiv}>
                  <LockIcon />
                  <FormattedMessage id="EditListingBusinessDetailsForm.businessHelpText5" />
                </div>
              </div>
              <div className={css.selectDiv}>
                <span className={css.selectLabel}>{businessSectorLabel}</span>
                <Select
                  id={BUSINESS_SECTOR}
                  name={BUSINESS_SECTOR}
                  options={businessStat}
                  placeholder={businessSectorPlaceholder}
                  components={{
                    Option: SingleOption(css.SingleOption),
                    Control: Control(css.Control),
                    IndicatorSeparator: null,
                    DropdownIndicator: DropdownIndicator(css.DropdownIndicator),
                    Menu: Menu(),
                    ValueContainer: ValueContainer(),
                    IndicatorsContainer: IndicatorsContainer(css.IndicatorsContainer),
                  }}
                  onChange={(e) => form.change(BUSINESS_SECTOR, e)}
                  defaultValue={businessSector}
                />
              </div>
              <div className={css.selectDiv}>
                <span className={css.selectLabel}>{businessTypeLabel}</span>
                <Select
                  id={BUSINESS_TYPE}
                  name={BUSINESS_TYPE}
                  options={businessTypes}
                  placeholder={businessTypePlaceholder}
                  components={{
                    Option: SingleOption(css.SingleOption),
                    Control: Control(css.Control),
                    IndicatorSeparator: null,
                    DropdownIndicator: DropdownIndicator(css.DropdownIndicator),
                    Menu: Menu(),
                    ValueContainer: ValueContainer(),
                    IndicatorsContainer: IndicatorsContainer(css.IndicatorsContainer),
                  }}
                  onChange={(e) => form.change(BUSINESS_TYPE, e)}
                  defaultValue={businessType}
                />
              </div>

              {userTextareaDiv(
                businessDescLabel,
                businessDescPlaceholder,
                isFieldInvalid(BUSINESS_DESC),
                BUSINESS_DESC,
                businessDescValidator,
                'EditListingBusinessDetailsForm.businessHelpText2'
              )}
              {/* <FieldDateInput
                id={BUSINESS_DATE}
                name={BUSINESS_DATE}
                label={businessBusinessDateLabel}
                placeholderText={businessBusinessDatePlaceholder}
                className={classNames(css.businessDateField, {
                  [css.invalidBusinessDateField]: isFieldInvalid(BUSINESS_DATE),
                })}
                isOutsideRange={isOutsideRange}
                pickerRootClass={css.pickerRootClass}
                validate={businessBusinessDateRequired}
                displayFormat="DD/MM/YYYY"
              /> */}
              <div className={css.bnsdate}>
                <label>{businessBusinessDateLabel}</label>
                <TextField
                  className={css.businessdte}
                  id="date"
                  type="date"
                  // defaultValue="2017-05-24"
                  value={values.businessEstDate}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => {
                    form.change(BUSINESS_DATE, e.target.value);
                  }}
                />
              </div>
              <div className={css.inputDiv}>
                <FieldTextInput
                  className={classNames(css.input, {
                    [css.invalidInput]: isFieldInvalid(BUSINESS_NAME),
                  })}
                  type="text"
                  name={BUSINESS_WEB}
                  id={BUSINESS_WEB}
                  label={'Business Website'}
                  placeholder={'Enter your business website'}
                  // validate={businessNameRequired}
                  // validate={composeValidators(urlRequired, urlValid)}
                  validate={composeValidators(urlValid)}
                />
              </div>
              <div className={css.selectDiv}>
                <span className={css.selectLabel}>{businessEmpLabel}</span>
                <Select
                  id={BUSINESS_EMP_COUNT}
                  name={BUSINESS_EMP_COUNT}
                  options={employes}
                  placeholder={businessEmpPlaceholder}
                  components={{
                    Option: SingleOption(css.SingleOption),
                    Control: Control(css.Control),
                    IndicatorSeparator: null,
                    DropdownIndicator: DropdownIndicator(css.DropdownIndicator),
                    Menu: Menu(),
                    ValueContainer: ValueContainer(),
                    IndicatorsContainer: IndicatorsContainer(css.IndicatorsContainer),
                  }}
                  onChange={(e) => form.change(BUSINESS_EMP_COUNT, e)}
                  defaultValue={businessEmpCount}
                />
              </div>
              {userTextareaDiv(
                businessPitchLabel,
                businessPitchPlaceholder,
                isFieldInvalid(BUSINESS_PITCH),
                BUSINESS_PITCH,
                businessPitchValidtor,
                'EditListingBusinessDetailsForm.businessHelpText1'
              )}
              {userTextareaDiv(
                businessMarketLabel,
                businessMarketPlaceholder,
                isFieldInvalid(BUSINESS_MARKET),
                BUSINESS_MARKET,
                !!businessMarket ? businessMarketMaxLength : null,
                'EditListingBusinessDetailsForm.businessHelpText1'
              )}
              {userTextareaDiv(
                businessProgressLabel,
                businessProgressPlaceholder,
                isFieldInvalid(BUSINESS_PROGRESS),
                BUSINESS_PROGRESS,
                !!businessProgress ? businessProgressMaxLength : null,
                'EditListingBusinessDetailsForm.businessHelpText1'
              )}
              {userTextareaDiv(
                businessObjectiveLabel,
                businessObjectivePlaceholder,
                isFieldInvalid(BUSINESS_OBJECTIVE),
                BUSINESS_OBJECTIVE,
                !!businessObjective ? businessObjectiveMaxLength : null,
                'EditListingBusinessDetailsForm.businessHelpText1'
              )}
            </section>
            <section className={css.financialDetailsSection}>
              <h3 className={css.sectionHeading}>
                <FormattedMessage id="EditListingBusinessDetailsForm.financialHeading" />
              </h3>
              <p className={css.financialSubheading}>
                <FormattedMessage id="EditListingBusinessDetailsForm.financialSubHeading" />
              </p>
              {userTextareaDiv(
                businessMonthlySalesLabel,
                businessMonthlySalesPlaceholder,
                isFieldInvalid(MONTHLY_SALES),
                MONTHLY_SALES,
                !!avgMonthlySale ? businessMonthlySalesMaxLength : null,
                'EditListingBusinessDetailsForm.businessHelpText4',
                true
              )}
              {userTextareaDiv(
                businessMonthlyCostLabel,
                businessMonthlyCostPlaceholder,
                isFieldInvalid(MONTHLY_COST),
                MONTHLY_COST,
                !!avgMonthlyCost ? businessMonthlyCostMaxLength : null,
                'EditListingBusinessDetailsForm.businessHelpText4',
                true
              )}
              {userTextareaDiv(
                businessMonthlyOperatingCashLabel,
                businessMonthlyOperatingCashPlaceholder,
                isFieldInvalid(MONTHLY_OPERATING_CASH),
                MONTHLY_OPERATING_CASH,
                !!monthlyOperatingCash ? businessMonthlyOperatingCashMaxLength : null,
                'EditListingBusinessDetailsForm.businessHelpText4',
                true
              )}
              {userTextareaDiv(
                businessWorkingCapitalLabel,
                businessWorkingCapitalPlaceholder,
                isFieldInvalid(WORKING_CAPITAL),
                WORKING_CAPITAL,
                !!workingCapital ? businessWorkingCapitalMaxLength : null,
                'EditListingBusinessDetailsForm.businessHelpText4',
                true
              )}
              {userTextareaDiv(
                businessMonthlyEbitdaLabel,
                businessMonthlyEbitdaPlaceholder,
                isFieldInvalid(MONTHLY_EBITDA),
                MONTHLY_EBITDA,
                !!monthlyEbitda ? businessMonthlyEbitdaMaxLength : null,
                'EditListingBusinessDetailsForm.businessHelpText4',
                true
              )}
              {userTextareaDiv(
                businessMonthlyNetIncomeLabel,
                businessMonthlyNetIncomePlaceholder,
                isFieldInvalid(MONTHLY_NET_INCOME),
                MONTHLY_NET_INCOME,
                !!monthlyNetIncome ? businessMonthlyNetIncomeMaxLength : null,
                'EditListingBusinessDetailsForm.businessHelpText4',
                true
              )}
              {userTextareaDiv(
                businessValueableAssetDescLabel,
                businessValueableAssetDescPlaceholder,
                isFieldInvalid(VALUEABLE_ASSET_DESC),
                VALUEABLE_ASSET_DESC,
                !!valuableAssetDesc ? businessValueableAssetDescMaxLength : null,
                'EditListingBusinessDetailsForm.businessHelpText3',
                true
              )}
              {userTextareaDiv(
                businessLiabilityAmntLabel,
                businessLiabilityAmntPlaceholder,
                isFieldInvalid(LIABILITY_AMNT),
                LIABILITY_AMNT,
                !!liabilityAmnt ? businessLiabilityAmntMaxLength : null,
                'EditListingBusinessDetailsForm.businessHelpText3',
                true
              )}
              {userTextareaDiv(
                businessBookValueLabel,
                businessBookValuePlaceholder,
                isFieldInvalid(BOOK_VALUE),
                BOOK_VALUE,
                !!bookValue ? businessBookValueMaxLength : null,
                'EditListingBusinessDetailsForm.businessHelpText3',
                true
              )}
            </section>
            <Button
              className={css.submitButton}
              type="submit"
              inProgress={submitInProgress}
              disabled={submitDisabled}
              ready={submitReady}
            >
              {saveActionMsg}
            </Button>
          </Form>
        );
      }}
    />
  );
}

EditListingQuoteBusinessDetailsFormComponent.defaultProps = {
  selectedPlace: null,
  fetchErrors: null,
};

EditListingQuoteBusinessDetailsFormComponent.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  selectedPlace: propTypes.place,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
};

export default compose(injectIntl)(EditListingQuoteBusinessDetailsFormComponent);

{
  /* <FieldCheckbox
            id="pickup"
            className={css.deliveryCheckbox}
            name="deliveryOptions"
            label={pickupLabel}
            value="pickup"
          />
          <div className={pickupClasses}>
            {errorMessage}
            {errorMessageShowListing}

           
          </div>
          <FieldCheckbox
            id="shipping"
            className={css.deliveryCheckbox}
            name="deliveryOptions"
            label={shippingLabel}
            value="shipping"
          />

          <div className={shippingClasses}>
            <FieldCurrencyInput
              id="shippingPriceInSubunitsOneItem"
              name="shippingPriceInSubunitsOneItem"
              className={css.input}
              label={intl.formatMessage({
                id: 'EditListingListingDetailsForm.shippingOneItemLabel',
              })}
              placeholder={intl.formatMessage({
                id: 'EditListingListingDetailsForm.shippingOneItemPlaceholder',
              })}
              currencyConfig={config.currencyConfig}
              disabled={!shippingEnabled}
              validate={
                shippingEnabled
                  ? required(
                      intl.formatMessage({
                        id: 'EditListingListingDetailsForm.shippingOneItemRequired',
                      })
                    )
                  : null
              }
              hideErrorMessage={!shippingEnabled}
              // Whatever parameters are being used to calculate
              // the validation function need to be combined in such
              // a way that, when they change, this key prop
              // changes, thus reregistering this field (and its
              // validation function) with Final Form.
              // See example: https://codesandbox.io/s/changing-field-level-validators-zc8ei
              key={shippingEnabled ? 'oneItemValidation' : 'noOneItemValidation'}
            />

            <FieldCurrencyInput
              id="shippingPriceInSubunitsAdditionalItems"
              name="shippingPriceInSubunitsAdditionalItems"
              className={css.input}
              label={intl.formatMessage({
                id: 'EditListingListingDetailsForm.shippingAdditionalItemsLabel',
              })}
              placeholder={intl.formatMessage({
                id: 'EditListingListingDetailsForm.shippingAdditionalItemsPlaceholder',
              })}
              currencyConfig={config.currencyConfig}
              disabled={!shippingEnabled}
              validate={
                shippingEnabled
                  ? required(
                      intl.formatMessage({
                        id: 'EditListingListingDetailsForm.shippingAdditionalItemsRequired',
                      })
                    )
                  : null
              }
              hideErrorMessage={!shippingEnabled}
              // Whatever parameters are being used to calculate
              // the validation function need to be combined in such
              // a way that, when they change, this key prop
              // changes, thus reregistering this field (and its
              // validation function) with Final Form.
              // See example: https://codesandbox.io/s/changing-field-level-validators-zc8ei
              key={shippingEnabled ? 'additionalItemsValidation' : 'noAdditionalItemsValidation'}
            />
          </div> */
}
