import React, { useState, useRef, useEffect } from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import arrayMutators from 'final-form-arrays';
import { types as sdkTypes } from '../../../../../src/util/sdkLoader';
import axios from 'axios';
import { Country } from 'country-state-city';
// Import configs and util modules
import config from '../../../../config';
import { intlShape, injectIntl, FormattedMessage } from '../../../../util/reactIntl';
import { propTypes } from '../../../../util/types';
import { maxLength, required, composeValidators } from '../../../../util/validators';
import Select, { components } from 'react-select';
// Import shared components
import { Form, Button, FieldTextInput, InlineTextButton, Modal } from '../../../../components';
// Import modules from this directory
import css from './EditListingQuoteDetailsForm.module.css';
import { MdLocationOn } from 'react-icons/md';
import SearchMap from '../../../SearchPage/SearchMap/SearchMap';
import { locationBounds } from '../../../../components/LocationAutocompleteInput/GeocoderMapbox';

const TITLE_MAX_LENGTH = 60;
const { LatLng } = sdkTypes;
const key = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
const countries = Country.getAllCountries();
const defaultCountryOptions = countries.map((c) => ({ value: c.isoCode, label: c.name }));
const EditListingQuoteDetailsFormComponent = (props) => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    render={(formRenderProps) => {
      const {
        autoFocus,
        className,
        disabled,
        ready,
        handleSubmit,
        intl,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        currentUser,
        initialValues,
        values,
        form,
        touched,
        errors,
        location = {},
        listing,
        onManageDisableScrolling,
      } = formRenderProps;

      const [country, setCountry] = useState(
        initialValues.country
          ? defaultCountryOptions.find((c) => c.value === initialValues.country)
          : null
      );

      const [city, setCity] = useState(null);
      const [area, setArea] = useState(null);
      const [zip, setZip] = useState(initialValues.zip);
      const [lat, setLat] = useState(initialValues.lat);
      const [lng, setLng] = useState(initialValues.lng);
      const [isCompleteAddress, setIsCompleteAddress] = useState(!!initialValues.address);
      const [showMap, setShowMap] = useState(false);
      const [address, setAddress] = useState(initialValues.address);
      const [searchText, setSearchText] = useState(null);
      const [initialMapUpdate, setInitialMapUpdate] = useState(false);

      // console.log(2122, values, values.country, country, zip);

      const orgMaybe = !!initialValues.lat && !!initialValues.lng ? new LatLng(lat, lng) : null;
      const origin = useRef(orgMaybe);
      const bounds = useRef(locationBounds(orgMaybe, 1000));
      const createDummyListing = (user, geolocation) => {
        const dummyListing = {};
        dummyListing.type = 'listing';
        dummyListing.id = user?.id;
        dummyListing.attributes = {
          geolocation,
          title: user?.attributes?.profile?.displayName,
          description: '',
          deleted: false,
          state: 'published',
          price: null,
          publicData: {},
        };
        dummyListing.author = { ...user, type: 'user' };
        dummyListing.image = null;
        return dummyListing;
      };
      const newListing = useRef(!!listing.id ? { ...listing } : createDummyListing(currentUser));
      const isFieldInvalid = (fieldName) => touched[fieldName] && !!errors[fieldName];

      const titleMessage = intl.formatMessage({
        id: 'EditListingDetailsForm.title',
      });
      const titlePlaceholderMessage = intl.formatMessage({
        id: 'EditListingDetailsForm.titlePlaceholder',
      });
      const titleRequiredMessage = intl.formatMessage({
        id: 'EditListingDetailsForm.titleRequired',
      });
      const maxLengthMessage = intl.formatMessage(
        { id: 'EditListingDetailsForm.maxLength' },
        {
          maxLength: TITLE_MAX_LENGTH,
        }
      );
      const descriptionMessage = intl.formatMessage({
        id: 'EditListingDetailsForm.description',
      });
      const descriptionPlaceholderMessage = intl.formatMessage({
        id: 'EditListingDetailsForm.descriptionPlaceholder',
      });
      const maxLength60Message = maxLength(maxLengthMessage, TITLE_MAX_LENGTH);
      const descriptionRequiredMessage = intl.formatMessage({
        id: 'EditListingDetailsForm.descriptionRequired',
      });

      const { updateListingError, createListingDraftError, showListingsError } = fetchErrors || {};
      const errorMessageUpdateListing = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingQuoteDetailsForm.updateFailed" />
        </p>
      ) : null;

      // This error happens only on first tab (of EditListingWizard)
      const errorMessageCreateListingDraft = createListingDraftError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingQuoteDetailsForm.createListingDraftError" />
        </p>
      ) : null;

      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingQuoteDetailsForm.showListingFailed" />
        </p>
      ) : null;

      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled =
        invalid ||
        disabled ||
        submitInProgress ||
        !values.title ||
        !values.description ||
        !values.zip ||
        !values.city ||
        !values.address ||
        !values.lat ||
        !values.lng ||
        !values.country ||
        !isCompleteAddress;

      useEffect(() => {
        let timerId = null;
        if (initialValues?.zip !== undefined) {
          getAddress(initialValues?.zip);
        }
        setInitialMapUpdate(true);
        timerId = setTimeout(() => setInitialMapUpdate(false), 300);
        return () => timerId && clearTimeout(timerId);
      }, []);

      useEffect(() => {
        let timerId = null;
        if (showMap) {
          setInitialMapUpdate(true);
          timerId = setTimeout(() => setInitialMapUpdate(false), 300);
        }
        return () => timerId && clearTimeout(timerId);
      }, [showMap]);

      const getCity = (addressArray) => {
        if (!addressArray) return '';
        let city = '';
        for (let i = 0; i < addressArray.length; i++) {
          if (
            addressArray[i].types[0] &&
            ('administrative_area_level_2' === addressArray[i].types[0] ||
              'administrative_area_level_3' === addressArray[i].types[0])
          ) {
            city = addressArray[i].long_name;
            return city;
          } else if (addressArray[i].types[0] && addressArray[i].types[0] === 'locality') {
            city = addressArray[i].long_name;
            return city;
          }
        }
      };

      const getCountry = (addressArray) => {
        if (!addressArray) return '';
        let country = '';
        for (let i = 0; i < addressArray.length; i++) {
          if (addressArray[i].types[0] && 'country' === addressArray[i].types[0]) {
            country = addressArray[i].long_name;
            return country;
          }
        }
      };

      const getPostalCode = (addressArray) => {
        if (!addressArray) return '';
        let postalcode = '';
        for (let i = 0; i < addressArray.length; i++) {
          if (addressArray[i].types[0] && 'postal_code' === addressArray[i].types[0]) {
            postalcode = addressArray[i].long_name;
            return postalcode;
          }
        }
      };

      const getArea = (addressArray) => {
        if (!addressArray) return '';
        let area = '';
        for (let i = 0; i < addressArray.length; i++) {
          if (addressArray[i].types[0]) {
            for (let j = 0; j < addressArray[i].types.length; j++) {
              if (
                'sublocality_level_1' === addressArray[i].types[j] ||
                'locality' === addressArray[i].types[j]
              ) {
                area = addressArray[i].long_name;
                return area;
              }
            }
          }
        }
      };
      const getAddressFromLatLng = async (lng, lat) => {
        try {
          const { results } = (
            await axios.get(
              `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&sensor=true&key=${key}`
            )
          ).data;
          const currCountry = getCountry(results[0]?.address_components);
          const currCountryAsObject = defaultCountryOptions.find((c) => c.label === currCountry);
          const currCity = getCity(results[0]?.address_components);
          const currArea = getArea(results[0]?.address_components);
          const org = new LatLng(
            results[0].geometry.location.lat,
            results[0].geometry.location.lng
          );
          origin.current = org;
          bounds.current = locationBounds(org, 1000);
          newListing.current.attributes.geolocation = new LatLng(
            origin.current.lat,
            origin.current.lng
          );
          const newPostalCode = getPostalCode(results[0].address_components);
          setLat(results[0].geometry.location.lat);
          setLng(results[0].geometry.location.lng);
          setCity(currCity);
          setArea(currArea);
          form.change('city', currCity);
          if (!!newPostalCode) setZip(newPostalCode);
          !!newPostalCode && form.change('zip', newPostalCode);
          if (currCountryAsObject) {
            setCountry(currCountryAsObject);
            form.change('country', currCountryAsObject?.value);
          }
          setAddress(results[0].formatted_address);
          setSearchText(results[0].formatted_address);
          form.change('lat', results[0].geometry.location.lat);
          form.change('lng', results[0].geometry.location.lng);
          form.change('address', results[0].formatted_address);
          // setShowProviderAddress(true);
          setIsCompleteAddress(true);
        } catch (error) {
          setCity(null);
          setArea(null);
        }
      };
      const getAddress = async (zipCode) => {
        if (zipCode.length > 4) {
          try {
            const { results } = (
              await axios.get(
                `https://maps.googleapis.com/maps/api/geocode/json?region=ie&address=${zipCode}&sensor=true&key=${key}`
              )
            ).data;
            const currCity = getCity(results[0]?.address_components);
            const currArea = getArea(results[0]?.address_components);
            const currCountry = getCountry(results[0]?.address_components);
            const currCountryAsObject = defaultCountryOptions.find((c) => c.label === currCountry);
            const org = new LatLng(
              results[0]?.geometry.location.lat,
              results[0]?.geometry.location.lng
            );
            origin.current = org;
            bounds.current = locationBounds(org, 1000);
            newListing.current.attributes.geolocation = new LatLng(
              origin.current.lat,
              origin.current.lng
            );

            setLat(results[0]?.geometry.location.lat);
            setLng(results[0]?.geometry.location.lng);
            setIsCompleteAddress(!!address);
            if (!address) setAddress(results[0]?.formatted_address);
            setCity(currCity);
            setArea(currArea);
            if (currCountryAsObject) {
              setCountry(currCountryAsObject);
              form.change('country', currCountryAsObject?.value);
            }

            form.change('city', currCity);
            form.change('lat', results[0].geometry.location.lat);
            form.change('lng', results[0].geometry.location.lng);
            form.change('address', results[0].formatted_address);
            setSearchText(results[0].formatted_address);
          } catch (error) {
            setCity(null);
            setArea(null);
          }
        } else {
          setCity(null);
          setArea(null);
        }
      };

      const getAddressFromText = async (text) => {
        if (text.length > 4) {
          try {
            const encodedSearchText = encodeURI(text);
            const { results } = (
              await axios.get(
                // `https://maps.googleapis.com/maps/api/place/textsearch/json?query=${encodedSearchText}&key=${key}`
                `https://maps.googleapis.com/maps/api/geocode/json?address=${encodedSearchText}&sensor=true&key=${key}`
              )
            ).data;
            const currCountry = getCountry(results[0]?.address_components);
            const currCountryAsObject = defaultCountryOptions.find((c) => c.label === currCountry);
            const currCity = getCity(results[0]?.address_components);
            const currArea = getArea(results[0]?.address_components);
            const org = new LatLng(
              results[0]?.geometry.location.lat,
              results[0]?.geometry.location.lng
            );
            origin.current = org;
            bounds.current = locationBounds(org, 1000);
            newListing.current.attributes.geolocation = new LatLng(
              origin.current.lat,
              origin.current.lng
            );
            const newPostalCode = getPostalCode(results[0].address_components);
            // setZip(newPostalCode);
            setInitialMapUpdate(true);
            setLat(results[0]?.geometry.location.lat);
            setLng(results[0]?.geometry.location.lng);
            setIsCompleteAddress(!!address);
            if (!address) setAddress(results[0]?.formatted_address);
            if (currCountryAsObject) {
              setCountry(currCountryAsObject);
              form.change('country', currCountryAsObject?.value);
            }
            setCity(currCity);
            setArea(currArea);
            form.change('city', currCity);
            form.change('lat', results[0].geometry.location.lat);
            form.change('lng', results[0].geometry.location.lng);
            form.change('address', results[0].formatted_address);
            // !!newPostalCode && form.change('zip', newPostalCode);
            setSearchText(results[0].formatted_address);
            setInitialMapUpdate(false);
          } catch (error) {
            setCity(null);
            setArea(null);
          }
        } else {
          setCity(null);
          setArea(null);
        }
      };
      const Option = (props) => {
        return (
          <components.Option
            {...props}
            className={classNames(css.multiOption, { [css.selectedMutliOption]: props.isSelected })}
          >
            {props.isSelected ? (
              <GrCheckboxSelected className={css.checkbox} />
            ) : (
              <GrCheckbox className={css.checkbox} />
            )}
            <span className={css.mutliOptnLabel}>{props.label}</span>
          </components.Option>
        );
      };
      const MultiValue = ({ children, ...rest }) => {
        return (
          <div className={css.value}>
            <components.MultiValue {...rest}>{children}</components.MultiValue>
          </div>
        );
      };

      const ValueContainer = ({ children, ...rest }) => (
        <components.ValueContainer
          {...rest}
          className={classNames(css.valueContainer, {
            [css.moreThanTwoValueContainer]: React.Children.count(children) > 3,
          })}
        >
          {children}
        </components.ValueContainer>
      );

      const SingleOption = ({ children, ...rest }) => (
        <components.Option
          {...rest}
          className={classNames(css.singleOption, { [css.selectedSingleOption]: rest.isSelected })}
        >
          {children}
        </components.Option>
      );

      const DropdownIndicator = ({ children, ...rest }) => (
        <components.DropdownIndicator {...rest} className={css.dropDownIndicator}>
          {children}
        </components.DropdownIndicator>
      );
      const Control = ({ children, ...rest }) => {
        return (
          <components.Control
            {...rest}
            className={classNames(css.optionControl, {
              [css.emptyOptionControl]: !rest.hasValue,
              [css.focusedControl]: rest.isFocused,
            })}
          >
            {children}
          </components.Control>
        );
      };

      const Menu = ({ children, ...rest }) => {
        return (
          <components.Menu {...rest} className={css.selectMenu}>
            {children}
          </components.Menu>
        );
      };
      return (
        <Form
          className={classes}
          onSubmit={handleSubmit}
          onChange={(e) => {
            const value = e.target.value;
            const id = e.target.id;
            if (id === 'zip') {
              setZip(value);
              setAddress('');
              setTimeout(() => {
                getAddress(value);
              }, 500);
            } else if (id === 'city') {
              setCity(value);
              setAddress('');
              if (value.length > 4) {
                setTimeout(() => {
                  getAddressFromText(value);
                }, 500);
              }
            }
          }}
        >
          {errorMessageCreateListingDraft}
          {errorMessageUpdateListing}
          {errorMessageShowListing}
          <FieldTextInput
            id="title"
            name="title"
            className={classNames(css.title, {
              [css.invalidInput]: isFieldInvalid('title'),
            })}
            type="text"
            label={titleMessage}
            placeholder={titlePlaceholderMessage}
            maxLength={TITLE_MAX_LENGTH}
            validate={composeValidators(required(titleRequiredMessage), maxLength60Message)}
            autoFocus={autoFocus}
          />
          <FieldTextInput
            id="description"
            name="description"
            className={classNames(css.title, css.description, {
              [css.invalidInput]: isFieldInvalid('description'),
            })}
            type="textarea"
            label={descriptionMessage}
            placeholder={descriptionPlaceholderMessage}
            validate={composeValidators(required(descriptionRequiredMessage))}
          />
          <span
            className={classNames(css.helpText, {
              [css.messageNone]: isFieldInvalid('description'),
            })}
          >
            Write a description of up to 100 words.
          </span>
          <div className={css.adddiv}>
            <span className={css.loc}>Location *</span>
            <div className={css.selectDiv}>
              <span className={css.selectLabel}>Country</span>
              <Select
                id="category"
                name="category"
                options={defaultCountryOptions}
                onChange={(e) => {
                  setCountry(e);
                  // console.log(232, e);
                  setZip(null);
                  form.change('country', e.value);
                  if (e.value !== country?.value) {
                    form.change('city', '');
                    form.change('zip', '');
                    form.change('address', '');
                    setAddress('');
                  }
                }}
                placeholder="Choose country"
                value={country}
                // defaultValue={selectedCategory}
                isSearchable={true}
                isClearable={true}
                components={{
                  Control,
                  Menu,
                  IndicatorSeparator: undefined,
                  Option: SingleOption,
                  DropdownIndicator,
                }}
              />
            </div>
            <div className={css.flddiv}>
              <div>
                <FieldTextInput
                  id="zip"
                  name="zip"
                  className={classNames(css.city, {
                    [css.invalidInput]: isFieldInvalid('zip'),
                  })}
                  type="text"
                  label={'Postal Code*'}
                  placeholder={'Enter the postal code'}
                  maxLength={TITLE_MAX_LENGTH}
                  // validate={composeValidators(required(titleRequiredMessage), maxLength60Message)}
                />
                {country?.value === 'OM' && country?.label === 'Oman' && zip === null ? (
                  <span className={css.zipFormatText}>
                    {' '}
                    Please include the city name before/after the postal code (e.g., MUSCAT 133, 133
                    MUSCAT){' '}
                  </span>
                ) : null}
              </div>{' '}
              <FieldTextInput
                id="city"
                name="city"
                className={classNames(css.city, {
                  [css.invalidInput]: isFieldInvalid('City'),
                })}
                type="text"
                label={'City*'}
                placeholder={'City'}
                maxLength={TITLE_MAX_LENGTH}
                // validate={composeValidators(required(titleRequiredMessage), maxLength60Message)}
              />
            </div>
            <div>
              {
                <button
                  type="button"
                  className={classNames(css.mapButton, { [css.hidebtn]: !address })}
                  onClick={() => setShowMap(true)}
                >
                  <span className={css.MapLogo}>
                    <MdLocationOn />
                  </span>

                  <span>
                    {address && isCompleteAddress
                      ? address
                      : 'Put a mark on the map for more detail address.*'}
                  </span>
                </button>
              }
            </div>
          </div>
          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>
          <Modal
            id="detailsForm.map"
            isOpen={showMap}
            onClose={() => setShowMap(false)}
            onManageDisableScrolling={onManageDisableScrolling}
            className={classNames(css.mapPanel, css.highZIndexCont)}
            scrollLayerClassName={css.modalScrollLayer}
            containerClassName={css.modalContainer}
            usePortal
          >
            {showMap && (
              <>
                <h2>Select Location From Map*</h2>
                <p>
                  Indicate the location of this property from the map by moving the red location
                  dot.
                </p>
                <div className={css.garageAddress}>
                  <MdLocationOn />
                  {/* <span>{address}</span> */}
                  <input
                    type="text"
                    placeholder="Search Location…"
                    value={searchText}
                    onChange={(e) => {
                      const value = e.target.value;
                      setSearchText(value);
                      setTimeout(() => {
                        getAddressFromText(value);
                      }, 500);
                    }}
                  />
                </div>
                <SearchMap
                  reusableContainerClassName={css.map}
                  activeListingId={null}
                  bounds={bounds.current}
                  center={origin.current}
                  isSearchMapOpenOnMobile={true}
                  location={location}
                  listings={[newListing.current]}
                  onMapMoveEnd={() => {}}
                  onCloseAsModal={() => {
                    // onManageDisableScrolling('SearchPage.map', false);
                  }}
                  messages={intl.messages}
                  setAddress={getAddressFromLatLng}
                  initialMapUpdate={initialMapUpdate}
                />
              </>
            )}
          </Modal>
        </Form>
      );
    }}
  />
);

EditListingQuoteDetailsFormComponent.defaultProps = {
  className: null,
  fetchErrors: null,
  filterConfig: config.custom.filters,
};

EditListingQuoteDetailsFormComponent.propTypes = {
  className: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    createListingDraftError: propTypes.error,
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  filterConfig: propTypes.filterConfig,
};

export default compose(injectIntl)(EditListingQuoteDetailsFormComponent);
