import React, { useEffect, useRef, useState } from 'react';
import { bool, func, object, string } from 'prop-types';
import classNames from 'classnames';

// Import configs and util modules
import { FormattedMessage } from '../../../../util/reactIntl';
import { ensureOwnListing } from '../../../../util/data';
import { LISTING_STATE_DRAFT } from '../../../../util/types';

// Import shared components
import { IconSpinner, ListingLink } from '../../../../components';

// Import modules from this directory
import EditListingQuoteClientForm from './EditListingQuoteClientForm';
import { Country } from 'country-state-city';
import { types as sdktypes, createInstance } from '../../../../../src/util/sdkLoader';
import css from './EditListingQuoteClientPanel.module.css';
import config from '../../../../config';
const { LatLng, UUID } = sdktypes;
const EditListingQuoteClientPanel = (props) => {
  const {
    className,
    rootClassName,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
    currentUser,
    onManageDisableScrolling,
    isQuoteType,
    params,
  } = props;
  const [loader, setLoader] = useState(false);

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);

  const { description, title, publicData } = currentListing.attributes;

  const sdkInstance = useRef(createInstance({ clientId: config.sdk.clientId }));

  //   const clientId = params.email || null;

  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const panelTitle = isPublished ? (
    <FormattedMessage
      id="EditListingQuoteClientPanel.title"
      values={{ listingTitle: <ListingLink listing={listing} /> }}
    />
  ) : (
    <FormattedMessage id="EditListingQuoteDetailsPanel.createListingTitle" />
  );

  return (
    <div className={classes}>
      <h1 className={css.title}>{panelTitle}</h1>
      {!loader || !updateInProgress ? (
        <EditListingQuoteClientForm
          className={css.form}
          initialValues={{
            clientId: publicData.clientId,
          }}
          saveActionMsg={submitButtonText}
          listing={listing}
          onSubmit={(values) => {
            setLoader(true);

            const { clientId } = values;
            const updateValues = {
              publicData: {
                clientId,
                isSelected: true,
              },
            };

            onSubmit(updateValues);
          }}
          currentUser={currentUser}
          onChange={onChange}
          disabled={disabled}
          ready={ready}
          updated={panelUpdated}
          updateInProgress={updateInProgress}
          fetchErrors={errors}
          autoFocus
          onManageDisableScrolling={onManageDisableScrolling}
        />
      ) : (
        <div className={css.spiner}>
          <IconSpinner className={css.innerSpinner} />
        </div>
      )}
    </div>
  );
};

EditListingQuoteClientPanel.defaultProps = {
  className: null,
  rootClassName: null,
  errors: null,
  listing: null,
};

EditListingQuoteClientPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingQuoteClientPanel;
