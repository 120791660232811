import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { BiArrowBack } from 'react-icons/bi';
import { MdLocationOn } from 'react-icons/md';
import { AiOutlineEye } from 'react-icons/ai';
import { BsStarFill, BsStarHalf, BsStar } from 'react-icons/bs';
import { AvatarMedium, Button, Footer, Map, Modal, UserCard } from '../../../../components';
import css from './EditListingPreviewPanel.module.css';
import config from '../../../../config';
import { formatMoney } from '../../../../util/currency';
import { intlShape } from '../../../../util/reactIntl';
import { types as sdkTypes } from '../../../../util/sdkLoader';
import { ReactComponent as PDFimg } from '../../../../assets/Icon awesome-file-pdf.svg';
import SectionGallery from '../../../ListingPage/SectionGallery';
import { createResourceLocatorString } from '../../../../util/routes';
import routeConfiguration from '../../../../routing/routeConfiguration';
import classNames from 'classnames';
import { PACKAGE } from '../EditListingWizardTab';
import moment from 'moment';
import axios from 'axios';
import { apiBaseUrl } from '../../../../util/api';
import { compose } from 'redux';

const CANCELLED = 'cancel';
const FAILED = 'cancel';
const { Money, LatLng } = sdkTypes;
const muttrahCords = { lat: 58.5643, lng: 23.6204 };

const PayPalButton =
  typeof window !== 'undefined' && window.paypal !== 'undefined'
    ? paypal.Buttons.driver('react', { React, ReactDOM })
    : null;

function PaymentButtons(props) {
  const {
    listingId,
    history,
    packages,
    packagesObject,
    currentUser,
    setPaymentError,
    setErrorModal,
  } = props;
  const createOrder = async (data) => {
    // Order is created on the server and the order id is returned
    return fetch(`${apiBaseUrl()}/api/createOrder`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        listingId,
      }),
      credentials: 'include',
      // use the "body" param to optionally pass additional order information
      // like product skus and quantities
    })
      .then((response) => response.json())
      .then((order) => order.id);
  };
  const onApprove = async (data) => {
    // Order is captured on the server and the response is returned to the browser
    return fetch(`${apiBaseUrl()}/api/captureOrder`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        orderID: data.orderID,
        listingId,
        packages: packagesObject,
      }),
      credentials: 'include',
    }).then((response) =>
      history.push(createResourceLocatorString('ManageListingsPage', routeConfiguration(), {}, {}))
    );
  };
  const onSubscriptionCreate = async () => {
    const subscriptionResp = await fetch(`${apiBaseUrl()}/api/createSubscription`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify({
        currentUser,
        listingId,
      }),
    });
    const id = await subscriptionResp.json();
    return id;
  };
  const onSubscriptionApprove = async (data) => {
    const orderID = data.orderID;
    const amount = packagesObject?.price?.amount || 0;
    // Order is captured on the server and the response is returned to the browser
    return fetch(`${apiBaseUrl()}/api/captureSubscriptionOrder`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        data: { ...data, listingId, packages: packagesObject },
      }),
      credentials: 'include',
    }).then((response) => {
      window.gtag('event', 'purchase', {
        // send_to: 'AW-16548771675/JAZVCI-j48sZENvuiNM9',
        value: amount,
        currency: 'USD',
        transaction_id: orderID,
      });
      history.push(createResourceLocatorString('ManageListingsPage', routeConfiguration(), {}, {}));
    });
  };
  const onError = async (error) => {
    setPaymentError({ errorType: CANCELLED, error });
    setErrorModal(true);
  };
  const onCancel = async (data) => {
    setPaymentError({ errorType: FAILED, data });
    setErrorModal(true);
  };
  return packages === 'basic' ? (
    <PayPalButton
      createSubscription={onSubscriptionCreate}
      onApprove={onSubscriptionApprove}
      onError={onError}
      onCancel={onCancel}
    />
  ) : (
    <PayPalButton
      createOrder={createOrder}
      onApprove={onApprove}
      onError={onError}
      onCancel={onCancel}
    />
  );
  // return (
  //   <PayPalButton createOrder={(data) => createOrder(data)} onApprove={(data) => onApprove(data)} />
  // );
}
const EditListingPreviewPanelComponent = (props) => {
  const [origin, setOrigin] = useState(new LatLng(muttrahCords.lat, muttrahCords.lng));
  const [currentTab, setCurrentTab] = useState('propertyInfo');
  const [paymentError, setPaymentError] = useState(null);
  const [errorModal, setErrorModal] = useState(false);
  const [showButtons, setShowButtons] = useState(false);
  const [quoteModal, setQuoteModal] = useState(true);

  const {
    listing,
    ready,
    updateInProgress,
    history,
    intl,
    currentUser,
    backButton,
    width,
    onSubmit,
    isQuoteType,
  } = props;
  // console.log(32159, isQuoteType);
  const priceData = (price, intl) => {
    if (price && price.currency === config.currency) {
      const formattedPrice = formatMoney(intl, price);
      return { formattedPrice, priceTitle: formattedPrice };
    } else if (price) {
      return {
        formattedPrice: `(${price.currency})`,
        priceTitle: `Unsupported currency (${price.currency})`,
      };
    }
    return {};
  };

  const price = listing?.attributes?.price;
  const title = listing?.attributes?.title;
  const publicData = listing?.attributes?.publicData;
  const description = listing?.attributes?.description;
  const authorName = currentUser?.attributes?.profile?.displayName;
  const isEntre = currentUser?.attributes?.profile?.protectedData;
  const state = listing.attributes.state;
  const {
    downPrice,
    lookingFor,
    investmentSize,
    investmentOffer,
    businessSector,
    businessType,
    businessDesc,
    lat,
    lng,
    address,

    businessPitch,
    businessEstDate,
    businessEmpCount,
    businessProgress,
    businessMarket,
    businessObjective,
    investmentSizePerDeal,
    packages,
  } = publicData;
  // console.log(5858, listing);
  const { urlValues = [] } = listing?.attributes?.privateData || {};

  const dateOfEstablishment = moment(businessEstDate).format('MMMM Do YYYY');
  const employeeCount = businessEmpCount?.label;
  const s3docs = urlValues?.map((url) => {
    const fileName = url?.split('/').pop().replace('+', ' ');
    return { fileName, url };
  });
  useEffect(() => {
    setOrigin(new LatLng(lat, lng));
  }, []);

  const formattedDownPrice = downPrice
    ? formatMoney(intl, new Money(downPrice?.amount ? downPrice?.amount : 0, config?.currency))
    : 0;
  const formattedInvSize = investmentSize
    ? typeof investmentSize === 'string' || typeof investmentSize === 'number'
      ? formatMoney(intl, new Money(investmentSize, config.currency))
      : formatMoney(
          intl,
          new Money(investmentSize.amount ? investmentSize.amount : 0, config.currency)
        )
    : 0;
  const { formattedPrice, priceTitle } = priceData(price, intl);
  const backButtonComponent = (
    <button
      className={css.backbtn}
      type="button"
      onClick={() => {
        backButton(PACKAGE);
      }}
    >
      <span>
        <BiArrowBack />{' '}
      </span>
      Back
    </button>
  );
  const infButtons = (
    <div className={css.infodiv}>
      <button
        className={classNames(css.infobtn, { [css.prpcls]: currentTab === 'propertyInfo' })}
        onClick={() => setCurrentTab('propertyInfo')}
      >
        Basic Information
      </button>
      <button
        className={classNames(css.infobtn, { [css.prpcls]: currentTab === 'otherInfo' })}
        onClick={() => setCurrentTab('otherInfo')}
      >
        Detailed Information
      </button>
    </div>
  );
  const priceSection = (
    <div className={css.prcsctn}>
      <div className={css.price}>{investmentSizePerDeal.label}</div>
      <div className={css.bkngamt}>Booking Amount - {formattedInvSize}</div>
    </div>
  );
  const locationSection = (
    <div>
      <div className={css.address}>
        <MdLocationOn />
        {address ? address : null}
      </div>

      <div className={css.mapWrapper}>
        <Map
          reusableContainerClassName={css.map}
          center={origin}
          mapRootClassName={css.mapRootClassName}
          className={css.mapbox}
        />
      </div>
    </div>
  );

  const docsSection =
    s3docs?.length > 0 ? (
      <div className={css.docs}>
        <span>Documentation</span>
        <div className={css.alldoc}>
          {s3docs?.map((d, idx) => (
            <div key={idx} className={css.adocc}>
              <div className={css.adoc}>
                <PDFimg />
                <span>{d.fileName.replaceAll('%20', ' ')}</span>
                <a href={d.url} target="_blank">
                  <AiOutlineEye />
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>
    ) : null;
  const authorSection = (
    <div
      className={classNames(css.authorsec, {
        [css.authorseco]: currentTab === 'otherInfo',
      })}
    >
      <div className={css.authorinfo}>
        <div>
          <AvatarMedium user={listing.author} />
        </div>

        <div className={css.authordet}>
          <span>{authorName}</span>
          <span>{isEntre ? 'Entrepreneur' : 'Investor'}</span>
        </div>
      </div>

      {/* <div className={css.rating}>
        <span>Rating 3.5</span>
        <div className={css.startrate}>
          <BsStarFill />
          <BsStarFill />
          <BsStarFill />
          <BsStarHalf />
          <BsStar />
        </div>
      </div> */}
    </div>
  );
  const saveButton = state === 'draft' && (
    <Button
      className={css.bookButton}
      ready={ready}
      inProgress={updateInProgress}
      type="button"
      onClick={() => {
        return isQuoteType ? onSubmit({}) : setShowButtons(true);
      }}
    >
      {isQuoteType ? 'Send Quote' : 'Pay and Publish'}
    </Button>
  );
  const otherInfo = (
    <div className={css.allinfo}>
      {lookingFor?.label ? (
        <div className={css.infoone}>
          <span>Looking For</span>
          <span>{lookingFor.label}</span>
        </div>
      ) : null}
      {formattedInvSize ? (
        <div className={css.infoone}>
          <span>Investment Size</span>
          <span>{formattedInvSize}</span>
        </div>
      ) : null}
      {investmentOffer ? (
        <div className={css.infoone}>
          <span>Investment Offers</span>
          <span>{investmentOffer}</span>
        </div>
      ) : null}
      {businessSector?.label ? (
        <div className={css.infoone}>
          <span>Business Sector</span>
          <span>{businessSector.label}</span>
        </div>
      ) : null}
      {businessType?.label ? (
        <div className={css.infoone}>
          <span>Type of Business</span>
          <span>{businessType.label}</span>
        </div>
      ) : null}
      {businessDesc ? (
        <div className={css.infoone}>
          <span>Business Description</span>
          <span>{businessDesc}</span>
        </div>
      ) : null}
      {dateOfEstablishment ? (
        <div className={css.infoone}>
          <span>Date of Business Establishment</span>
          <span>{dateOfEstablishment}</span>
        </div>
      ) : null}
      {employeeCount ? (
        <div className={css.infoone}>
          <span>No of Employee</span>
          <span>{employeeCount}</span>
        </div>
      ) : null}
      {businessPitch ? (
        <div className={css.infoone}>
          <span>The Pitch</span>
          <span>{businessPitch}</span>
        </div>
      ) : null}
      {businessMarket ? (
        <div className={css.infoone}>
          <span>The Market</span>
          <span>{businessMarket}</span>
        </div>
      ) : null}
      {businessProgress ? (
        <div className={css.infoone}>
          <span>Progress/Proof</span>
          <span>{businessProgress}</span>
        </div>
      ) : null}
      {businessObjective ? (
        <div className={css.infoone}>
          <span>Objective/Future</span>
          <span>{businessObjective}</span>
        </div>
      ) : null}
    </div>
  );
  const errorContent =
    paymentError?.errorType === FAILED ? (
      <div>
        <h2>Payment Failed</h2>
        <p>Your request has failed. Please resfresh the page and try again</p>
      </div>
    ) : paymentError?.errorType === CANCELLED ? (
      <div>
        <h2>Payment Cancelled</h2>
        <p>
          Your request has been cancelled. Please complete your purchase to publish your business
          listin.
        </p>
      </div>
    ) : null;
  return (
    <div className={css.maindiv}>
      <div className={css.topDiv}>
        {backButtonComponent}
        {width > 767 ? infButtons : null}
      </div>
      <div className={css.btmdiv}>
        <div className={css.imgsctn}>
          <SectionGallery
            listing={listing}
            className={css.imggal}
            fromPreview={true}
            imgClassName={css.img}
            wrapperClassName={css.wrapperClassName}
            thumbnailPosition={width <= 767 ? 'left' : 'bottom'}
          />
        </div>
        {width <= 767 ? <div className={css.smlscdiv}>{infButtons}</div> : null}

        {currentTab === 'propertyInfo' ? (
          <div className={css.secdiv}>
            {priceSection}
            <div className={css.location}>
              <div className={css.title}>{title}</div>
              {locationSection}
            </div>
            <div className={css.desc}>{description}</div>
            {docsSection}
            {authorSection}
            {saveButton}
          </div>
        ) : (
          <div className={css.secdiv}>
            {authorSection}
            {otherInfo}
            {saveButton}
          </div>
        )}
      </div>
      {isQuoteType ? (
        <Modal
          className={css.paypalModal}
          id="quotemodal"
          isOpen={quoteModal}
          onClose={() => setQuoteModal(false)}
          onManageDisableScrolling={() => {}}
          // className={classNames(css.mapPanel, css.highZIndexCont)}
          // scrollLayerClassName={css.modalScrollLayer}
          // containerClassName={css.modalContainer}
          usePortal
        >
          <div className={css.modaldiv}> You have successfully created a private quote!!</div>
        </Modal>
      ) : null}
      <Modal
        className={css.paypalModal}
        id="error_modal"
        isOpen={errorModal}
        onClose={() => setErrorModal(false)}
        onManageDisableScrolling={() => {}}
        // className={classNames(css.mapPanel, css.highZIndexCont)}
        // scrollLayerClassName={css.modalScrollLayer}
        // containerClassName={css.modalContainer}
        usePortal
      >
        <div className={css.modaldiv}>{errorContent}</div>
      </Modal>
      <Modal
        className={css.paypalModal}
        id="paybuttons"
        isOpen={showButtons}
        onClose={() => setShowButtons(false)}
        onManageDisableScrolling={() => {}}
        // className={classNames(css.mapPanel, css.highZIndexCont)}
        // scrollLayerClassName={css.modalScrollLayer}
        // containerClassName={css.modalContainer}
        usePortal
      >
        {packages && (
          <div className={css.modaldiv}>
            <p>Hi, {currentUser?.attributes?.profile.firstName}</p>
            <p>Complete your payment to publish the listing and explore our Marketplace features</p>
            <div>
              <div>
                <span>Selected Package: </span>
                <span>{packages?.name}</span>
              </div>
              <div>
                <span>Amount: </span>
                <span>${packages?.price?.amount}</span>
              </div>
            </div>

            {packages.name === 'basic' ? (
              <div>
                {/* <Button
                inProgress={updateInProgress}
                ready={ready}
                className={css.zerbtn}
                onClick={() => onSubmit({})}
              >
                Publish now
              </Button> */}
                <PaymentButtons
                  listingId={listing.id.uuid}
                  history={history}
                  packages={packages.name}
                  packagesObject={packages}
                  currentUser={currentUser}
                  setErrorModal={setErrorModal}
                  setPaymentError={setPaymentError}
                />
                <p className={css.zeroinf}>
                  You will be charged <span>$10</span> on a monthly basis to keep the listing active
                  on the market place. On payment failure, your listing will be deactivated. Make
                  sure to <span>add payment method</span>
                </p>
              </div>
            ) : (
              <>
                <p className={css.cpmspn}>Choose payment method:</p>
                <PaymentButtons
                  listingId={listing.id.uuid}
                  history={history}
                  packages={packages.name}
                  setPaymentError={setPaymentError}
                  setErrorModal={setErrorModal}
                  packagesObject={packages}
                  currentUser={currentUser}
                />
              </>
            )}
          </div>
        )}
      </Modal>
    </div>
  );
};
const { func, object, string, bool } = PropTypes;
EditListingPreviewPanelComponent.propTypes = {
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  listing: object,
  updateInProgress: bool.isRequired,
  intl: intlShape.isRequired,
};
const EditListingPreviewPanel = compose(withRouter)(EditListingPreviewPanelComponent);
export default EditListingPreviewPanel;
