import unionWith from 'lodash/unionWith';

import config from '../../config';
import { storableError } from '../../util/errors';
import { convertUnitToSubUnit, unitDivisor } from '../../util/currency';
import { parseDateFromISO8601, getExclusiveEndDate } from '../../util/dates';
import { createImageVariantConfig, types as sdkTypes } from '../../util/sdkLoader';
import { isOriginInUse, isStockInUse } from '../../util/search';
import { parse } from '../../util/urlHelpers';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import axios from 'axios';
import { apiBaseUrl, deserialize } from '../../util/api';
const { Money } = sdkTypes;
// Pagination page size might need to be dynamic on responsive page layouts
// Current design has max 3 columns 12 is divisible by 2 and 3
// So, there's enough cards to fill all columns on full pagination pages
const RESULT_PAGE_SIZE = 24;

// ================ Action types ================ //

export const SEARCH_ADMIN_DASHBOARD_LISTINGS_REQUEST =
  'app/SearchPage/SEARCH_ADMIN_DASHBOARD_LISTINGS_REQUEST';
export const SEARCH_ADMIN_DASHBOARD_LISTINGS_SUCCESS =
  'app/SearchPage/SEARCH_ADMIN_DASHBOARD_LISTINGS_SUCCESS';
export const SEARCH_ADMIN_DASHBOARD_LISTINGS_ERROR =
  'app/SearchPage/SEARCH_ADMIN_DASHBOARD_LISTINGS_ERROR';

export const SEARCH_USER_REQUEST = 'app/AdminDashboard/SEARCH_USER_REQUEST';
export const SEARCH_USER_SUCCESS = 'app/AdminDashboard/SEARCH_USER_SUCCESS';
export const SEARCH_USER_ERRORS = 'app/AdminDashboard/SEARCH_USER_ERRORS';

export const SEARCH_TRANSACTIONS_REQUEST = 'app/AdminDashboard/SEARCH_TRANSACTIONS_REQUEST';
export const SEARCH_TRANSACTIONS_SUCCESS = 'app/AdminDashboard/SEARCH_TRANSACTIONS_SUCCESS';
export const SEARCH_TRANSACTIONS_ERROR = 'app/AdminDashboard/SEARCH_TRANSACTIONS_ERROR';

export const UPDATE_TXS_LIST = 'app/AdminDashboard/UPDATE_TXS_LIST';

// ================ Reducer ================ //

const initialState = {
  pagination: null,
  searchParams: null,
  searchLoading: false,
  searchListingsError: null,
  currentPageResultIds: [],
  searchUsersError: null,
  currentPageUsersData: [],
  payoutTransactionIds: [],
  payoutTransactionsError: null,
};

const resultIds = (data) => data.data.map((l) => l.id);

const adminDashboardReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case UPDATE_TXS_LIST:
      return {
        ...state,
        payoutTransactionIds: payload,
      };
    case SEARCH_ADMIN_DASHBOARD_LISTINGS_REQUEST:
      return {
        ...state,
        searchParams: payload.searchParams,
        searchLoading: true,
        searchListingsError: null,
        pagination: null,
        currentPageResultIds: [],
      };
    case SEARCH_ADMIN_DASHBOARD_LISTINGS_SUCCESS:
      return {
        ...state,
        currentPageResultIds: resultIds(payload.data),
        pagination: payload.data.meta,
        searchLoading: false,
      };
    case SEARCH_ADMIN_DASHBOARD_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, searchLoading: false, searchListingsError: payload };

    case SEARCH_TRANSACTIONS_REQUEST:
      return {
        ...state,
        searchParams: payload.searchParams,
        searchLoading: true,
        searchListingsError: null,
        pagination: null,
        currentPageResultIds: [],
        payoutTransactionIds: [],
        payoutTransactionsError: null,
      };
    case SEARCH_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        pagination: payload.data.meta,
        searchLoading: false,
        payoutTransactionIds: resultIds(payload.data),
        payoutTransactionsError: null,
      };
    case SEARCH_TRANSACTIONS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return {
        ...state,
        searchLoading: false,
        searchListingsError: payload,
        payoutTransactionIds: [],
        payoutTransactionsError: payload,
      };

    case SEARCH_USER_REQUEST:
      return {
        ...state,
        searchLoading: true,
        searchParams: payload?.searchParams,
        searchUsersError: null,
        pagination: null,
        currentPageUsersData: [],
      };

    case SEARCH_USER_SUCCESS:
      // console.log(222, payload.data.meta, 222);
      return {
        ...state,
        currentPageUsersData: payload.data.data.map((m) => ({ id: m.id, type: 'user' })),
        pagination: payload.data.meta,
        searchLoading: false,
      };
    case SEARCH_USER_ERRORS:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, searchLoading: false, searchUsersError: payload };

    default:
      return state;
  }
};

export default adminDashboardReducer;

// ================ Action creators ================ //

export const searchListingsRequest = (searchParams) => ({
  type: SEARCH_ADMIN_DASHBOARD_LISTINGS_REQUEST,
  payload: { searchParams },
});

export const searchListingsSuccess = (response) => ({
  type: SEARCH_ADMIN_DASHBOARD_LISTINGS_SUCCESS,
  payload: { data: response.data },
});

export const searchListingsError = (e) => ({
  type: SEARCH_ADMIN_DASHBOARD_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const searchTransactionsRequest = (searchParams) => ({
  type: SEARCH_TRANSACTIONS_REQUEST,
  payload: { searchParams },
});

export const searchTransactionsSuccess = (response) => ({
  type: SEARCH_TRANSACTIONS_SUCCESS,
  payload: { data: response.data },
});

export const updateTxsList = (payload) => ({
  type: UPDATE_TXS_LIST,
  payload,
});

export const searchTransactionsError = (e) => ({
  type: SEARCH_TRANSACTIONS_ERROR,
  error: true,
  payload: e,
});

export const searchUsersRequest = (searchParams) => ({
  type: SEARCH_USER_REQUEST,
  payload: { searchParams },
});
export const searchUsersSuccess = (response) => ({
  type: SEARCH_USER_SUCCESS,
  payload: { data: response.data },
});

export const searchUsersError = (e) => ({
  type: SEARCH_USER_ERRORS,
  error: true,
  payload: e,
});

export const searchListings = (searchParams) => async (dispatch, getState, sdk) => {
  dispatch(searchListingsRequest(searchParams));

  const { perPage, tab, ...rest } = searchParams;

  //   axios

  const resp =
    tab === 'custom'
      ? await axios.post(`${apiBaseUrl()}/api/getCustomListings`, {
          page: rest.page,
          per_page: perPage,
        })
      : // : tab === 'verified'
      // ? await axios.post(`${apiBaseUrl()}/api/getVerifiedListings`, {
      //     page: rest.page,
      //     per_page: perPage,
      //   })
      // tab === 'unverified'
      tab === 'listings'
      ? await axios.post(`${apiBaseUrl()}/api/getUnVerifiedListings`, {
          page: rest.page,
          per_page: perPage,
        })
      : tab === 'sold'
      ? await axios.post(`${apiBaseUrl()}/api/getSoldListings`, {
          page: rest.page,
          per_page: perPage,
        })
      : null;
  // console.log(858, resp);
  const listingIds = resp?.data;
  const params = {
    ids: listingIds,
    pub_isChatWithAdminListing: false,

    per_page: perPage,
    ...rest,
    'fields.listing': ['title', 'geolocation', 'price', 'publicData', 'metadata'],
  };
  return sdk.listings
    .query(params, { expand: true })
    .then((response) => {
      dispatch(addMarketplaceEntities(response));
      dispatch(searchListingsSuccess(response));
      return response;
    })
    .catch((e) => {
      dispatch(searchListingsError(storableError(e)));
      throw e;
    });
};

export const closeOthersListing = (listingId) => async () => {
  // console.log(123, listingId);
  const url = `${apiBaseUrl()}/api/user/closeListing`;
  return axios.post(url, {
    listingId,
  });
};

export const searchUsers = (searchParams) => async (dispatch, getState, sdk) => {
  dispatch(searchUsersRequest(searchParams));

  const { perPage, tab, ...rest } = searchParams;

  //   axios
  // console.log('first');

  return axios
    .post(`${apiBaseUrl()}/api/getMarketplaceUsers`, {
      page: rest.page,
      per_page: perPage,
      tab,
    })
    .then((response) => {
      // console.log(858, deserialize(JSON.stringify(response.data)), 858);
      const updatedData = {
        status: 200,
        statusText: '',
        data: deserialize(JSON.stringify(response.data)),
      };
      dispatch(addMarketplaceEntities(updatedData));
      dispatch(searchUsersSuccess(response));
    })
    .catch((error) => {
      dispatch(searchUsersError(error));
    });
};
export const removeProcessedTxs = (params) => async (dispatch, getState) => {
  const { txId } = params;
  const payoutTransactionIdsState = getState().AdminDashboard.payoutTransactionIds;
  const newTxs = payoutTransactionIdsState.filter((tx) => tx.uuid !== txId);
  dispatch(updateTxsList(newTxs));
};

export const getPayoutTransaction = (searchParams) => async (dispatch, getState, sdk) => {
  const payoutTransactionIdsState = getState().AdminDashboard.payoutTransactionIds;
  if (payoutTransactionIdsState.length > 0) {
    return;
  }
  dispatch(searchTransactionsRequest(searchParams));
  try {
    const txsResp = await axios.get(`${apiBaseUrl()}/api/getPendingPayouts`);
    txsResp.data.data.forEach((tx) => {
      const payinTotal = tx.attributes.payinTotal;
      const payoutTotal = tx.attributes.payoutTotal;
      const payoutTotalinMoney = new Money(payoutTotal.amount, payoutTotal.currency);
      const payinTotalinMoney = new Money(payinTotal.amount, payinTotal.currency);
      tx.attributes.payoutTotal = payoutTotalinMoney;
      tx.attributes.payinTotal = payinTotalinMoney;
    });
    dispatch(addMarketplaceEntities(txsResp));
    dispatch(searchTransactionsSuccess(txsResp));
  } catch (error) {}
};

export const loadData = (params, search) => {
  const queryParams = parse(search, {
    latlng: ['origin'],
    latlngBounds: ['bounds'],
  });
  // Add minStock filter with default value (1), if stock management is in use.
  // This can be overwriten with passed-in query parameters.
  const minStockMaybe = isStockInUse(config) ? { minStock: 1 } : {};
  const { page = 1, address, origin, ...rest } = queryParams;
  const originMaybe = isOriginInUse(config) && origin ? { origin } : {};

  const { aspectWidth = 1, aspectHeight = 1, variantPrefix = 'listing-card' } = config.listing;
  const aspectRatio = aspectHeight / aspectWidth;

  // console.log(878, params, params.tab, 878);
  if (params.tab === 'investors' || params.tab === 'entrepreneurs') {
    // console.log(111);
    return searchUsers({
      tab: params.tab,
      // ...minStockMaybe,
      ...rest,
      page,
      perPage: RESULT_PAGE_SIZE,
    });
  } else if (params.tab === 'payouts') {
    return getPayoutTransaction({});
  } else {
    return searchListings({
      tab: params.tab,
      // ...minStockMaybe,
      ...rest,
      page,
      perPage: RESULT_PAGE_SIZE,
      include: ['author', 'images', 'author.profileImage'],
      'fields.listing': ['title', 'geolocation', 'price', 'publicData'],
      'fields.user': ['profile.displayName', 'profile.abbreviatedName'],
      'fields.image': [
        `variants.${variantPrefix}`,
        `variants.${variantPrefix}-2x`,
        'variants.default',
      ],
      ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
      ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
      'limit.images': 1,
    });
  }
};
