import React from 'react';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import {
  IconEdit,
  IconSuccess,
  PrimaryButton,
  InlineTextButton,
  IconEnquiry,
} from '../../components';
import css from './StripeConnectAccountStatusBox.module.css';
import { AiFillEdit } from 'react-icons/ai';

const STATUS_VERIFICATION_NEEDED = 'verificationNeeded';
const STATUS_VERIFICATION_SUCCESS = 'verificationSuccess';
const STATUS_VERIFICATION_ERROR = 'verificationError';
const STATUS_VERIFICATION_NOT_STARTED = 'verificationNotStarted';
const STATUS_EMAIL_NOT_CONFIRMED = 'emailNotConfirmed';
const STATUS_ONBOARD_NOT_COMPLETED = 'onboardNotCompleted';
6;

const StripeConnectAccountStatusBox = (props) => {
  const { type, onGetStripeConnectAccountLink, inProgress, disabled, isPaypal = false } = props;

  if (type === STATUS_VERIFICATION_NEEDED) {
    return (
      <div className={classNames(css.verificiationBox, css.verficiationNeededBox)}>
        <div className={css.verificiationBoxTextWrapper}>
          <div className={css.verificationBoxTitle}>
            {/* <FormattedMessage id="StripeConnectAccountStatusBox.verificationNeededTitle" /> */}
            {isPaypal ? (
              <FormattedMessage id="PaypalAccountStatusBox.verificationNeededTitle" />
            ) : (
              <FormattedMessage id="StripeConnectAccountStatusBox.verificationNeededTitle" />
            )}
          </div>
          <div className={css.verificationBoxText}>
            {/* <FormattedMessage id="StripeConnectAccountStatusBox.verificationNeededText" /> */}
            {isPaypal ? (
              <FormattedMessage id="PaypalAccountStatusBox.loginVerificationNeededText" />
            ) : (
              // PaypalAccountStatusBox.verificationNeededText
              <FormattedMessage id="StripeConnectAccountStatusBox.verificationNeededText" />
            )}
          </div>
        </div>

        <PrimaryButton
          className={css.getVerifiedButton}
          spinnerClassName={css.spinner}
          type="button"
          inProgress={inProgress}
          disabled={disabled}
          onClick={onGetStripeConnectAccountLink}
        >
          <FormattedMessage
            id={
              isPaypal
                ? 'PaypalAccountStatusBox.loginButton'
                : 'StripeConnectAccountStatusBox.getVerifiedButton'
            }
          />
        </PrimaryButton>
      </div>
    );
  } else if (type === STATUS_VERIFICATION_SUCCESS) {
    return (
      <div
        className={classNames(css.verificiationBox, css.verficiationSuccessBox, {
          [css.verficiationNeededBox]: isPaypal,
        })}
      >
        <div
          className={classNames(
            css.verificiationBoxTextWrapper,
            css.verificationBoxSuccessTextWrapper
          )}
        >
          <div className={css.verificationBoxTitle}>
            <IconSuccess className={css.icon} />
            {/* <FormattedMessage id="StripeConnectAccountStatusBox.verificationSuccessTitle" /> */}
            <div className={css.messageContentDiv}>
              {isPaypal ? (
                <FormattedMessage id="PaypalAccountStatusBox.verificationSuccessTitle" />
              ) : (
                <FormattedMessage id="StripeConnectAccountStatusBox.verificationSuccessTitle" />
              )}
              {!isPaypal && (
                <InlineTextButton
                  className={css.editVerificationButton}
                  spinnerClassName={css.spinner}
                  type="button"
                  inProgress={inProgress}
                  disabled={disabled}
                  onClick={onGetStripeConnectAccountLink}
                >
                  {/* <IconEdit
              className={css.icon}
              pencilClassName={css.iconEditPencil}
            /> */}
                  <IconEditSvg className={css.editPlanIcon} />

                  <FormattedMessage id="StripeConnectAccountStatusBox.editAccountButton" />
                </InlineTextButton>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  } else if (type === STATUS_VERIFICATION_ERROR) {
    return (
      <div className={classNames(css.verificiationBox, css.verficiationErrorBox)}>
        <div className={css.verificiationBoxTextWrapper}>
          <div className={css.verificationBoxTitle}>
            <FormattedMessage id="StripeConnectAccountStatusBox.verificationFailedTitle" />
          </div>
          <div className={css.verificationBoxText}>
            {/* <FormattedMessage id="StripeConnectAccountStatusBox.verificationFailedText" /> */}
            {isPaypal ? (
              <FormattedMessage id="PaypalAccountStatusBox.verificationFailedText" />
            ) : (
              <FormattedMessage id="StripeConnectAccountStatusBox.verificationFailedText" />
            )}
          </div>
        </div>

        <PrimaryButton
          className={css.getVerifiedButton}
          spinnerClassName={css.spinner}
          type="button"
          inProgress={inProgress}
          disabled={disabled}
          onClick={onGetStripeConnectAccountLink}
        >
          <FormattedMessage id="StripeConnectAccountStatusBox.getVerifiedButton" />
        </PrimaryButton>
      </div>
    );
  } else if (type === STATUS_VERIFICATION_NOT_STARTED) {
    return (
      <div className={classNames(css.verificiationBox, css.verficiationNeededBox)}>
        <div className={css.verificiationBoxTextWrapper}>
          <div className={css.verificationBoxTitle}>
            {isPaypal ? (
              <FormattedMessage id="PaypalAccountStatusBox.verificationNotStartedTitle" />
            ) : (
              <FormattedMessage id="StripeConnectAccountStatusBox.verificationNotStartedTitle" />
            )}
          </div>
          <div className={css.verificationBoxText}>
            {isPaypal ? (
              <FormattedMessage id="PaypalAccountStatusBox.verificationFailedText" />
            ) : (
              <FormattedMessage id="StripeConnectAccountStatusBox.verificationFailedText" />
            )}
          </div>
        </div>

        <PrimaryButton
          className={css.verificationNotStartedBtn}
          spinnerClassName={css.spinner}
          type="button"
          inProgress={inProgress}
          disabled={disabled}
          onClick={onGetStripeConnectAccountLink}
        >
          {isPaypal ? (
            <FormattedMessage id="PaypalAccountStatusBox.loginButton" /> // PaypalAccountStatusBox.addAccountButton
          ) : (
            <FormattedMessage id="StripeConnectAccountStatusBox.addAccountButton" />
          )}
        </PrimaryButton>
      </div>
    );
  } else if (type === STATUS_EMAIL_NOT_CONFIRMED) {
    return (
      <div className={classNames(css.verificiationBox, css.verficiationSuccessBox)}>
        <div
          className={classNames(
            css.verificiationBoxTextWrapper,
            css.verificationBoxSuccessTextWrapper
          )}
        >
          <div className={css.verificationBoxTitle}>
            <IconSuccess className={css.icon} />
            {isPaypal ? (
              <>
                {/* <FormattedMessage id="PaypalAccountStatusBox.emailNotConfirmedTitle" /> */}
                <FormattedMessage id="PaypalAccountStatusBox.verificationSuccessTitle" />
              </>
            ) : (
              <FormattedMessage id="StripeConnectAccountStatusBox.verificationSuccessTitle" />
            )}
          </div>
        </div>
        {!isPaypal ? (
          <InlineTextButton
            className={css.editVerificationButton}
            spinnerClassName={css.spinner}
            type="button"
            inProgress={inProgress}
            disabled={disabled}
            onClick={onGetStripeConnectAccountLink}
          >
            {/* {!isPaypal && ( */}
            <AiFillEdit />
            {/* )} */}
            <FormattedMessage
              id={'StripeConnectAccountStatusBox.editAccountButton'}
              // id={
              //   isPaypal
              //     ? 'StripeConnectAccountStatusBox.checkAccountButton'
              //     : 'StripeConnectAccountStatusBox.editAccountButton'
              // }
            />
          </InlineTextButton>
        ) : null}
      </div>
    );
  } else if (type === STATUS_ONBOARD_NOT_COMPLETED) {
    return (
      <div className={classNames(css.verificiationBox, css.verficiationSuccessBox)}>
        <div
          className={classNames(
            css.verificiationBoxTextWrapper,
            css.verificationBoxSuccessTextWrapper
          )}
        >
          <div className={css.verificationBoxTitle}>
            <IconEnquiry className={css.icon} />
            {isPaypal ? (
              <FormattedMessage id="PaypalAccountStatusBox.onboardNotCompletedTitle" />
            ) : (
              <FormattedMessage id="StripeConnectAccountStatusBox.onboardNotCompletedTitle" />
            )}
          </div>
          <div className={css.verificationBoxText}>
            {isPaypal ? (
              <FormattedMessage id="PaypalAccountStatusBox.onboardNotCompletedText" />
            ) : (
              <FormattedMessage id="StripeConnectAccountStatusBox.onboardNotCompletedText" />
            )}
          </div>
        </div>

        {isPaypal && (
          <InlineTextButton
            className={css.editVerificationButton}
            spinnerClassName={css.spinner}
            type="button"
            inProgress={inProgress}
            disabled={disabled}
            onClick={onGetStripeConnectAccountLink}
          >
            <FormattedMessage id="StripeConnectAccountStatusBox.onboardNotCompletedButton" />
          </InlineTextButton>
        )}
      </div>
    );
  } else {
    throw new Error(`Unknown type ${type} for StripeConnectAccountStatusBox`);
  }
};

export default StripeConnectAccountStatusBox;
