import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Import configs and util modules
import config from '../../../../config';
import { FormattedMessage } from '../../../../util/reactIntl';
import { LISTING_STATE_DRAFT } from '../../../../util/types';
import { ensureOwnListing } from '../../../../util/data';
import { types as sdkTypes } from '../../../../util/sdkLoader';

// Import shared components
import { ListingLink } from '../../../../components';

// Import modules from this directory
import EditListingQuotePackageForm from './EditListingQuotePackageForm';
import css from './EditListingQuotePackagePanel.module.css';

const { Money } = sdkTypes;
const currency = config.currency;

class EditListingQuotePackagePanel extends Component {
  constructor(props) {
    super(props);

    this.getInitialValues = this.getInitialValues.bind(this);

    this.state = {
      initialValues: this.getInitialValues(),
    };
  }

  getInitialValues() {
    const { listing } = this.props;
    const currentListing = ensureOwnListing(listing);
    const { publicData } = currentListing.attributes;

    // Only render current search if full place object is available in the URL params
    // TODO bounds are missing - those need to be queried directly from Google Places

    return {
      packages: publicData?.packages,
    };
  }

  render() {
    const {
      className,
      rootClassName,
      listing,
      disabled,
      ready,
      onSubmit,
      onChange,
      submitButtonText,
      panelUpdated,
      updateInProgress,
      errors,
    } = this.props;

    const classes = classNames(rootClassName || css.root, className);
    const currentListing = ensureOwnListing(listing);

    const isPublished =
      currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
    const panelTitle = isPublished ? (
      <FormattedMessage
        id="EditListingPackagePanel.title"
        values={{ listingTitle: <ListingLink listing={listing} /> }}
      />
    ) : (
      <FormattedMessage id="EditListingPackagePanel.createListingTitle" />
    );

    return (
      <div className={classes}>
        <h1 className={css.title}>{panelTitle}</h1>
        <p className={css.subscriptionMessage}>
          Choose a package to subscribe to for one month for this listing.
        </p>
        <EditListingQuotePackageForm
          className={css.form}
          initialValues={this.state.initialValues}
          onSubmit={(values) => {
            const { packages } = values;
            const updateValues = {
              publicData: {
                packages: {
                  ['name']: packages.name,
                  ['price']: { amount: packages.price, currency: currency },
                },
              },
            };
            const { publicData } = currentListing.attributes;

            this.setState({
              initialValues: {
                packages: publicData?.packages,
              },
            });
            onSubmit(updateValues);
          }}
          onChange={onChange}
          saveActionMsg={submitButtonText}
          disabled={disabled}
          ready={ready}
          updated={panelUpdated}
          updateInProgress={updateInProgress}
          fetchErrors={errors}
          autoFocus
        />
      </div>
    );
  }
}

const { func, object, string, bool } = PropTypes;

EditListingQuotePackagePanel.defaultProps = {
  className: null,
  rootClassName: null,
  listing: null,
};

EditListingQuotePackagePanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingQuotePackagePanel;
