import React, { useEffect } from 'react';
import { bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';

// Import configs and util modules
import config from '../../../../config';
import { types as sdkTypes } from '../../../../util/sdkLoader';

import { intlShape, injectIntl, FormattedMessage } from '../../../../util/reactIntl';
import { propTypes } from '../../../../util/types';
import {
  autocompleteSearchRequired,
  autocompletePlaceSelected,
  composeValidators,
  required,
} from '../../../../util/validators';
import { ReactComponent as TickMark } from '../../../../assets/feather-check-circle.svg';
// Import shared components
import {
  Form,
  LocationAutocompleteInputField,
  Button,
  FieldCurrencyInput,
  FieldTextInput,
  FieldCheckbox,
} from '../../../../components';
const { Money } = sdkTypes;

// Import modules from this directory
import css from './EditListingPackageForm.module.css';

const identity = (v) => v;
const packageIdentity = { basic: 'basic', silver: 'silver', golden: 'golden' };
const currency = config.currency;

export const EditListingPackageFormComponent = (props) => (
  <FinalForm
    {...props}
    render={(formRenderProps) => {
      const {
        form,
        autoFocus,
        className,
        disabled,
        ready,
        handleSubmit,
        intl,
        pristine,
        invalid,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        values,
        initialValues,
        listing,
      } = formRenderProps;
      const state = listing.attributes.state;
      const isRadioDisabled =
        state === 'published' &&
        initialValues.packages &&
        Object.keys(initialValues.packages).length > 0;
      // This is a bug fix for Final Form.
      // Without this, React will return a warning:
      //   "Cannot update a component (`ForwardRef(Field)`)
      //   while rendering a different component (`ForwardRef(Field)`)"
      // This seems to happen because validation calls listeneres and
      // that causes state to change inside final-form.
      // https://github.com/final-form/react-final-form/issues/751
      //
      // TODO: it might not be worth the trouble to show these fields as disabled,
      // if this fix causes trouble in future dependency updates.
      const { pauseValidation, resumeValidation } = form;
      pauseValidation(false);
      useEffect(() => resumeValidation(), [values]);

      const { updateListingError, showListingsError } = fetchErrors || {};

      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled =
        invalid ||
        disabled ||
        submitInProgress ||
        !values.packages?.name ||
        typeof values.packages?.price !== 'number';
      // console.log(12333, values.packages);
      return (
        <Form className={classes} onSubmit={handleSubmit}>
          <div className={css.allPackages}>
            <div
              className={classNames(css.singlePackages, {
                [css.actpkg]: values.packages?.name === packageIdentity.basic,
              })}
            >
              <div className={classNames(css.packageTop, css.blutop)}>
                <span>Basic Package</span>
                {values.packages?.name === packageIdentity.basic ? <TickMark /> : null}
              </div>
              <div className={css.startingMonth}>
                <div>Starting at-</div>
                <div className={css.monthlyFee}>
                  <span>$0</span>
                  {/* <span>/Month</span> */}
                </div>
              </div>
              <input
                type="radio"
                className={css.radinp}
                id="basicPackage"
                name="packages"
                disabled={isRadioDisabled}
                onChange={() => {
                  form.change('packages', {
                    name: packageIdentity.basic,
                    price: 0,
                  });
                }}
                checked={values?.packages?.name === packageIdentity?.basic}
              />
              <label htmlFor="basicPackage" className={css.selectbtn}>
                Select Plan
              </label>

              <ul>
                <li>Ability to create instant listing.</li>
                <li>Communicate with prospects through Banki.</li>
                <li>Valid for one month. $10/month will be charged after that.</li>
              </ul>
            </div>
            <div
              className={classNames(css.singlePackages, {
                [css.actpkg]: values.packages?.name === packageIdentity.silver,
              })}
            >
              <div className={classNames(css.packageTop, css.siltop)}>
                <span>Silver Package</span>
                {values.packages?.name === packageIdentity.silver ? <TickMark /> : null}
              </div>
              <div className={css.startingMonth}>
                <div>Starting at-</div>
                <div className={css.monthlyFee}>
                  <span>$50</span>
                  {/* <span>/Month</span> */}
                </div>
              </div>
              <input
                type="radio"
                id="silverPackage"
                name="packages"
                disabled={isRadioDisabled}
                className={css.radinp}
                onChange={() => {
                  form.change('packages', {
                    name: packageIdentity.silver,
                    price: 50,
                  });
                }}
                checked={values?.packages?.name === packageIdentity?.silver}
              />
              <label htmlFor="silverPackage" className={css.selectbtn}>
                Select Plan
              </label>
              <ul>
                <li>Listing information can be marked as verified and gets higher visibility.</li>
                <li>Banki prepare Business Valuation model for your listing.</li>
                <li>
                  Listing competitiveness is rated to get higher visibility. Rating guidance will be
                  provided.
                </li>
              </ul>
            </div>
            <div
              className={classNames(css.singlePackages, {
                [css.actpkg]: values.packages?.name === packageIdentity.golden,
              })}
            >
              <div className={classNames(css.packageTop, css.goltop)}>
                <span>Golden Package</span>
                {values.packages?.name === packageIdentity.golden ? <TickMark /> : null}
              </div>
              <div className={css.startingMonth}>
                <div>Starting at-</div>
                <div className={css.monthlyFee}>
                  <span>$250</span>
                  {/* <span>/Month</span> */}
                </div>
              </div>
              <input
                type="radio"
                className={css.radinp}
                id={'goldenPackage'}
                name="packages"
                disabled={isRadioDisabled}
                onChange={() => {
                  form.change('packages', {
                    name: packageIdentity.golden,
                    price: 250,
                  });
                }}
                checked={values?.packages?.name === packageIdentity?.golden}
              />
              <label htmlFor="goldenPackage" className={css.selectbtn}>
                Select Plan
              </label>
              <ul>
                <li>Banki promotes the listing actively through our investors lists.</li>
                <li>
                  Bank will handle all communication with perspective buyers , run background check
                  and provide short lists of buyers to the listing Owner.
                </li>
                <li>Banki will prepare professional Pitch/ Business Plan for your listing.</li>
              </ul>
            </div>
          </div>

          {/* <FieldCheckbox
            id="pickup"
            className={css.deliveryCheckbox}
            name="deliveryOptions"
            label={pickupLabel}
            value="pickup"
          />
          <div className={pickupClasses}>
            {errorMessage}
            {errorMessageShowListing}
            <LocationAutocompleteInputField
              disabled={!pickupEnabled}
              className={css.input}
              inputClassName={css.locationAutocompleteInput}
              iconClassName={css.locationAutocompleteInputIcon}
              predictionsClassName={css.predictionsRoot}
              validClassName={css.validLocation}
              autoFocus={autoFocus}
              name="location"
              label={titleRequiredMessage}
              placeholder={addressPlaceholderMessage}
              useDefaultPredictions={false}
              format={identity}
              valueFromForm={values.location}
              validate={
                pickupEnabled
                  ? composeValidators(
                      autocompleteSearchRequired(addressRequiredMessage),
                      autocompletePlaceSelected(addressNotRecognizedMessage)
                    )
                  : () => {}
              }
              hideErrorMessage={!pickupEnabled}
              // Whatever parameters are being used to calculate
              // the validation function need to be combined in such
              // a way that, when they change, this key prop
              // changes, thus reregistering this field (and its
              // validation function) with Final Form.
              // See example: https://codesandbox.io/s/changing-field-level-validators-zc8ei
              key={
                pickupEnabled ? 'locationValidation' : 'noLocationValidation'
              }
            />

            <FieldTextInput
              className={css.input}
              type="text"
              name="building"
              id="building"
              label={buildingMessage}
              placeholder={buildingPlaceholderMessage}
              disabled={!pickupEnabled}
            />
          </div>
          <FieldCheckbox
            id="shipping"
            className={css.deliveryCheckbox}
            name="deliveryOptions"
            label={shippingLabel}
            value="shipping"
          />

          <div className={shippingClasses}>
            <FieldCurrencyInput
              id="shippingPriceInSubunitsOneItem"
              name="shippingPriceInSubunitsOneItem"
              className={css.input}
              label={intl.formatMessage({
                id: 'EditListingDeliveryForm.shippingOneItemLabel',
              })}
              placeholder={intl.formatMessage({
                id: 'EditListingDeliveryForm.shippingOneItemPlaceholder',
              })}
              currencyConfig={config.currencyConfig}
              disabled={!shippingEnabled}
              validate={
                shippingEnabled
                  ? required(
                      intl.formatMessage({
                        id: 'EditListingDeliveryForm.shippingOneItemRequired',
                      })
                    )
                  : null
              }
              hideErrorMessage={!shippingEnabled}
              // Whatever parameters are being used to calculate
              // the validation function need to be combined in such
              // a way that, when they change, this key prop
              // changes, thus reregistering this field (and its
              // validation function) with Final Form.
              // See example: https://codesandbox.io/s/changing-field-level-validators-zc8ei
              key={
                shippingEnabled ? 'oneItemValidation' : 'noOneItemValidation'
              }
            />

            <FieldCurrencyInput
              id="shippingPriceInSubunitsAdditionalItems"
              name="shippingPriceInSubunitsAdditionalItems"
              className={css.input}
              label={intl.formatMessage({
                id: 'EditListingDeliveryForm.shippingAdditionalItemsLabel',
              })}
              placeholder={intl.formatMessage({
                id:
                  'EditListingDeliveryForm.shippingAdditionalItemsPlaceholder',
              })}
              currencyConfig={config.currencyConfig}
              disabled={!shippingEnabled}
              validate={
                shippingEnabled
                  ? required(
                      intl.formatMessage({
                        id:
                          'EditListingDeliveryForm.shippingAdditionalItemsRequired',
                      })
                    )
                  : null
              }
              hideErrorMessage={!shippingEnabled}
              // Whatever parameters are being used to calculate
              // the validation function need to be combined in such
              // a way that, when they change, this key prop
              // changes, thus reregistering this field (and its
              // validation function) with Final Form.
              // See example: https://codesandbox.io/s/changing-field-level-validators-zc8ei
              key={
                shippingEnabled
                  ? 'additionalItemsValidation'
                  : 'noAdditionalItemsValidation'
              }
            />
          </div> */}

          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>
        </Form>
      );
    }}
  />
);

EditListingPackageFormComponent.defaultProps = {
  selectedPlace: null,
  fetchErrors: null,
};

EditListingPackageFormComponent.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  selectedPlace: propTypes.place,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
};

export default compose(injectIntl)(EditListingPackageFormComponent);
