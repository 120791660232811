import React, { useState, useEffect } from 'react';
import { any, bool, func, object, number, string } from 'prop-types';
import classNames from 'classnames';

import { FormattedMessage, intlShape } from '../../../util/reactIntl';
import { ACCOUNT_SETTINGS_PAGES } from '../../../routing/routeConfiguration';
import { propTypes } from '../../../util/types';
import {
  Avatar,
  InlineTextButton,
  Logo,
  Menu,
  MenuLabel,
  MenuContent,
  MenuItem,
  NamedLink,
} from '../../../components';

import TopbarSearchForm from '../TopbarSearchForm/TopbarSearchForm';
import { ReactComponent as NotificationBell } from '../../../containers/LandingPage/SectionFilteredSearches/images/Icon ionic-ios-notifications-outline.svg';
// import { SlEnvolopeLetter } from 'react-icons/sl';
// import { TiMessages } from 'react-icons/ti';
// import { MdOutlineMessage } from 'react-icons/md';
// import { MdOutlineMail } from 'react-icons/md';
import { AiOutlineMail } from 'react-icons/ai';
import css from './TopbarDesktop.module.css';

const TopbarDesktop = (props) => {
  const {
    className,
    appConfig,
    currentUser,
    currentPage,
    rootClassName,
    currentUserHasListings,
    notificationCount,
    intl,
    isAuthenticated,
    onLogout,
    onSearchSubmit,
    initialSearchFormValues,
  } = props;
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  const authenticatedOnClientSide = mounted && isAuthenticated;
  const isAuthenticatedOrJustHydrated = isAuthenticated || !mounted;

  const classes = classNames(rootClassName || css.root, className);

  const search = (
    <TopbarSearchForm
      className={css.searchLink}
      desktopInputRoot={css.topbarSearchWithLeftPadding}
      onSubmit={onSearchSubmit}
      initialValues={initialSearchFormValues}
      appConfig={appConfig}
    />
  );

  const notificationDot = notificationCount > 0 ? <div className={css.notificationDot} /> : null;

  const inboxLink = authenticatedOnClientSide ? (
    <NamedLink
      className={css.inboxLink}
      name="InboxPage"
      params={{
        tab:
          currentUser?.attributes?.profile?.protectedData?.entrepreneur === true
            ? 'sales'
            : 'orders',
      }}
    >
      <span className={css.inbox}>
        {/* <FormattedMessage id="TopbarDesktop.inbox" /> */}
        {/* <NotificationBell /> */}
        <AiOutlineMail className={css.msgBox} />
        {notificationDot}
      </span>
    </NamedLink>
  ) : null;

  const currentPageClass = (page) => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  };
  const menuLabel = (
    <MenuLabel className={css.profileMenuLabel} isOpenClassName={css.profileMenuIsOpen}>
      <Avatar className={css.avatar} user={currentUser} disableProfileLink />
    </MenuLabel>
  );
  const providerMenu = (
    <Menu>
      {menuLabel}
      <MenuContent className={css.profileMenuContent}>
        <MenuItem key="ManageListingsPage">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('ManageListingsPage'))}
            name="ManageListingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.yourListingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="ProfileSettingsPage">
          <NamedLink
            className={classNames(css.profileSettingsLink, currentPageClass('ProfileSettingsPage'))}
            name="ProfileSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.profileSettingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="AccountSettingsPage">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('AccountSettingsPage'))}
            name="AccountSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.accountSettingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="FavouritesPage">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('FavouritesPage'))}
            name="FavouritesPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.FavouritesPageLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="logout">
          <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.logout" />
          </InlineTextButton>
        </MenuItem>
      </MenuContent>
    </Menu>
  );
  const customerMenu = (
    <Menu>
      {menuLabel}
      <MenuContent className={css.profileMenuContent}>
        <MenuItem key="ProfileSettingsPage">
          <NamedLink
            className={classNames(css.profileSettingsLink, currentPageClass('ProfileSettingsPage'))}
            name="ProfileSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.profileSettingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="AccountSettingsPage">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('AccountSettingsPage'))}
            name="AccountSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.accountSettingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="FavouritesPage">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('FavouritesPage'))}
            name="FavouritesPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.FavouritesPageLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="logout">
          <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.logout" />
          </InlineTextButton>
        </MenuItem>
      </MenuContent>
    </Menu>
  );
  const adminMenu = (
    <Menu>
      {menuLabel}

      <MenuContent className={css.profileMenuContent}>
        <MenuItem key="AdminDashboard">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('AdminDashboard'))}
            name="AdminDashboard"
            // params={{ tab: 'verified' }}
            params={{ tab: 'listings' }}
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.adminDashboard" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="ManageListingsPage">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('ManageListingsPage'))}
            name="ManageListingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.yourListingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="ProfileSettingsPage">
          <NamedLink
            className={classNames(css.profileSettingsLink, currentPageClass('ProfileSettingsPage'))}
            name="ProfileSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.profileSettingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="AccountSettingsPage">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('AccountSettingsPage'))}
            name="AccountSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.accountSettingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="FavouritesPage">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('FavouritesPage'))}
            name="FavouritesPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.FavouritesPageLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="logout">
          <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.logout" />
          </InlineTextButton>
        </MenuItem>
      </MenuContent>
    </Menu>
  );
  const profileMenu = authenticatedOnClientSide
    ? currentUser?.attributes?.profile?.privateData?.isSuperAdmin
      ? adminMenu
      : currentUser?.attributes?.profile?.protectedData?.entrepreneur
      ? providerMenu
      : customerMenu
    : null;

  const signupLink = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink name="SignupPage" className={css.signupLink}>
      <span className={css.signup}>
        <FormattedMessage id="TopbarDesktop.signup" />
      </span>
    </NamedLink>
  );

  const loginLink = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink name="LoginPage" className={css.loginLink}>
      <span className={css.login}>
        <FormattedMessage id="TopbarDesktop.login" />
      </span>
    </NamedLink>
  );

  const createCustomListing = isAuthenticatedOrJustHydrated &&
    currentUser?.attributes?.profile?.protectedData?.entrepreneur === true && (
      <NamedLink className={css.createListingLink} name="QuoteListingPage">
        <span className={css.createListing}>
          <FormattedMessage id="TopbarDesktop.createCustomListing" />
        </span>
      </NamedLink>
    );

  const myQuotes = isAuthenticatedOrJustHydrated &&
    currentUser?.attributes?.profile?.protectedData?.entrepreneur === false && (
      <NamedLink className={css.createListingLink} name="MyCustomQuote">
        <span className={css.createListing}>
          <FormattedMessage id="TopbarDesktop.quotesForYou" />
        </span>
      </NamedLink>
    );

  return (
    <nav className={classes}>
      <NamedLink className={css.logoLink} name="LandingPage">
        <Logo
          format="desktop"
          className={css.logo}
          alt={intl.formatMessage({ id: 'TopbarDesktop.logo' })}
        />
        {/* BANKI BUSINESS ADVISER */}
      </NamedLink>
      {search}
      {isAuthenticatedOrJustHydrated &&
        currentUser?.attributes?.profile?.protectedData?.entrepreneur === true && (
          <NamedLink className={css.createListingLink} name="NewListingPage">
            <span className={`${css.nrmlst} ${css.createListing}`}>
              <FormattedMessage id="TopbarDesktop.createListing" />
            </span>
          </NamedLink>
        )}
      {createCustomListing}
      {myQuotes}
      {inboxLink}
      {profileMenu}
      {loginLink}
      {signupLink}
    </nav>
  );
};

TopbarDesktop.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  currentPage: null,
  notificationCount: 0,
  initialSearchFormValues: {},
  appConfig: null,
};

TopbarDesktop.propTypes = {
  rootClassName: string,
  className: string,
  currentUserHasListings: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentPage: string,
  isAuthenticated: bool.isRequired,
  onLogout: func.isRequired,
  notificationCount: number,
  onSearchSubmit: func.isRequired,
  initialSearchFormValues: object,
  intl: intlShape.isRequired,
  appConfig: object,
};

export default TopbarDesktop;
