import React, { Component, useEffect } from 'react';
import { array, bool, func, number, object, oneOf, shape, string } from 'prop-types';
import { compose } from 'redux';
import classNames from 'classnames';
import { BiArrowBack } from 'react-icons/bi';
// Import configs and util modules
import config from '../../../config';
import routeConfiguration from '../../../routing/routeConfiguration';
import { FormattedMessage, injectIntl, intlShape } from '../../../util/reactIntl';
import { createResourceLocatorString } from '../../../util/routes';
import { withViewport } from '../../../util/contextHelpers';
import { propTypes } from '../../../util/types';
import {
  createSlug,
  LISTING_PAGE_PARAM_TYPE_DRAFT,
  LISTING_PAGE_PARAM_TYPE_NEW,
  LISTING_PAGE_PARAM_TYPES,
} from '../../../util/urlHelpers';
import { ensureCurrentUser, ensureListing } from '../../../util/data';

// Import shared components
import {
  Modal,
  NamedRedirect,
  Tabs,
  StripeConnectAccountStatusBox,
  StripeConnectAccountForm,
  NamedLink,
} from '../../../components';

// Import modules from this directory
import EditListingWizardTab, {
  DETAILS,
  DELIVERY,
  PRICING,
  CLIENT,
  PHOTOS,
  PACKAGE,
  LISTING_DETAILS,
  BUSINESS_DETAILS,
  PRICING_FINANCE,
  PHOTO_DOCUMENTS,
  PREVIEW,
} from './EditListingWizardTab';
import css from './EditListingWizard.module.css';

// You can reorder these panels.
// Note 1: You need to change save button translations for new listing flow
// Note 2: Ensure that draft listing is created after the first panel
// and listing publishing happens after last panel.

// export const TABS = [
//   DETAILS,
//   LISTING_DETAILS,
//   BUSINESS_DETAILS,
//   // PRICING_FINANCE,
//   PHOTO_DOCUMENTS,
//   PACKAGE,
//   PREVIEW,
// ];

// Tabs are horizontal in small screens
const MAX_HORIZONTAL_NAV_SCREEN_WIDTH = 1023;

const STRIPE_ONBOARDING_RETURN_URL_SUCCESS = 'success';
const STRIPE_ONBOARDING_RETURN_URL_FAILURE = 'failure';

const tabLabel = (intl, tab) => {
  let key = null;
  if (tab === DETAILS) {
    key = 'EditListingWizard.tabLabelDetails';
  } else if (tab === DELIVERY) {
    key = 'EditListingWizard.tabLabelDelivery';
  } else if (tab === PRICING) {
    key = 'EditListingWizard.tabLabelPricing';
  } else if (tab === BUSINESS_DETAILS) {
    key = 'EditListingWizard.tabLabelBusinessDetails';
  } else if (tab === LISTING_DETAILS) {
    key = 'EditListingWizard.tabLabelListingDetails';
  } else if (tab === PACKAGE) {
    key = 'EditListingWizard.tabLabelPackage';
  } else if (tab === PHOTO_DOCUMENTS) {
    key = 'EditListingWizard.tabLabelPhotoDocuments';
  } else if (tab === PRICING_FINANCE) {
    key = 'EditListingWizard.tabLabelPricingFinance';
  } else if (tab === CLIENT) {
    key = 'EditListingWizard.tabLabelClient';
  } else if (tab === PREVIEW) {
    key = 'EditListingWizard.tabLabelPreview';
  }

  return intl.formatMessage({ id: key });
};

/**
 * Check if a wizard tab is completed.
 *
 * @param tab wizard's tab
 * @param listing is contains some specific data if tab is completed
 *
 * @return true if tab / step is completed.
 */
const tabCompleted = (tab, listing, isQuoteType) => {
  const { availabilityPlan, description, price, title, publicData, privateData } =
    listing.attributes;
  const images = listing.images;
  const deliveryOptionPicked =
    publicData && (publicData.shippingEnabled || publicData.pickupEnabled);

  switch (tab) {
    case DETAILS:
      return !!(description && title && publicData.lat,
      publicData.lng,
      publicData.zip,
      publicData.city,
      publicData.address);

    case DELIVERY:
      return !!deliveryOptionPicked;
    case PRICING:
      return !!price;
    case PHOTOS:
      return images && images.length > 0;
    case LISTING_DETAILS:
      if (publicData?.clientId) return true;
      else
        return (
          !!publicData &&
          publicData.lookingFor !== undefined &&
          publicData.lookingFor.label &&
          publicData.lookingFor.value &&
          publicData.lookingForArr.length > 0 &&
          !!publicData.investmentSize &&
          !!publicData.investmentOffer &&
          !!privateData.email &&
          !!privateData.phoneNumber
        );
    case BUSINESS_DETAILS:
      return (
        !!privateData.businessName &&
        publicData.businessSector.label &&
        publicData.businessSector.value &&
        publicData.businessType.label &&
        publicData.businessType.value &&
        publicData.businessEmpCount.label &&
        publicData.businessEmpCount.value &&
        !!publicData.businessDesc &&
        publicData.businessEstDate !== undefined &&
        !!publicData.businessPitch
      );
    case CLIENT:
      if (publicData?.clientId) return true;
      else return !!(publicData && publicData.clientId && publicData.isSelected);

    case PRICING_FINANCE:
      return !!price && !!publicData.downPrice && !!publicData.financialInfo;
    //requried condition
    // return (
    //   !!publicData.propertyValue &&
    //   !!publicData.downPayment &&
    //   !!publicData.financialInfo &&
    //   publicData.avgMonthlySale !== undefined &&
    //   publicData.avgMonthlyCost !== undefined &&
    //   publicData.monthlyOperatingCash !== undefined &&
    //   publicData.workingCaptial !== undefined &&
    //   publicData.monthlyEbitda !== undefined &&
    //   publicData.monthlyNetIncome !== undefined &&
    //   publicData.valuableAssetDesc !== undefined &&
    //   publicData.liabilityAmnt !== undefined &&
    //   publicData.bookvalue !== undefined
    // );
    case PHOTO_DOCUMENTS:
      return images && images.length > 0;
    case PACKAGE:
      return !!publicData && !!publicData.packages;
    //required condition
    // (
    //   privateData.packageInfo !== undefined &&
    //   Object.keys(privateData.packageInfo).length > 0
    // );
    case PREVIEW:
      return true;
    default:
      return false;
  }
};

/**
 * Check which wizard tabs are active and which are not yet available. Tab is active if previous
 * tab is completed. In edit mode all tabs are active.
 *
 * @param isNew flag if a new listing is being created or an old one being edited
 * @param listing data to be checked
 *
 * @return object containing activity / editability of different tabs of this wizard
 */

const scrollToTab = (tabPrefix, tabId) => {
  const el = document.querySelector(`#${tabPrefix}_${tabId}`);
  if (el) {
    el.scrollIntoView({
      block: 'start',
      behavior: 'smooth',
    });
  }
};

// Create return URL for the Stripe onboarding form
const createReturnURL = (returnURLType, rootURL, routes, pathParams) => {
  const path = createResourceLocatorString(
    'EditListingStripeOnboardingPage',
    routes,
    { ...pathParams, returnURLType },
    {}
  );
  const root = rootURL.replace(/\/$/, '');
  return `${root}${path}`;
};

// Get attribute: stripeAccountData
const getStripeAccountData = (stripeAccount) => stripeAccount.attributes.stripeAccountData || null;

// Get last 4 digits of bank account returned in Stripe account
const getBankAccountLast4Digits = (stripeAccountData) =>
  stripeAccountData && stripeAccountData.external_accounts.data.length > 0
    ? stripeAccountData.external_accounts.data[0].last4
    : null;

// Check if there's requirements on selected type: 'past_due', 'currently_due' etc.
const hasRequirements = (stripeAccountData, requirementType) =>
  stripeAccountData != null &&
  stripeAccountData.requirements &&
  Array.isArray(stripeAccountData.requirements[requirementType]) &&
  stripeAccountData.requirements[requirementType].length > 0;

// Redirect user to Stripe's hosted Connect account onboarding form
const handleGetStripeConnectAccountLinkFn = (getLinkFn, commonParams) => (type) => () => {
  getLinkFn({ type, ...commonParams })
    .then((url) => {
      window.location.href = url;
    })
    .catch((err) => console.error(err));
};

const RedirectToStripe = ({ redirectFn }) => {
  useEffect(redirectFn('custom_account_verification'), []);
  return <FormattedMessage id="EditListingWizard.redirectingToStripe" />;
};

// Create a new or edit listing through EditListingWizard
class EditListingWizard extends Component {
  constructor(props) {
    super(props);

    // Having this info in state would trigger unnecessary rerendering
    this.hasScrolledToTab = false;

    this.state = {
      draftId: null,
      showPayoutDetails: false,
    };
    this.handleCreateFlowTabScrolling = this.handleCreateFlowTabScrolling.bind(this);
    this.handlePublishListing = this.handlePublishListing.bind(this);
    this.handlePayoutModalClose = this.handlePayoutModalClose.bind(this);
  }

  componentDidMount() {
    const { stripeOnboardingReturnURL } = this.props;

    // if (stripeOnboardingReturnURL != null && !this.showPayoutDetails) {
    //   this.setState({ showPayoutDetails: true });
    // }
  }

  handleCreateFlowTabScrolling(shouldScroll) {
    this.hasScrolledToTab = shouldScroll;
  }

  handlePublishListing(id) {
    const { onPublishListingDraft, currentUser, stripeAccount, isQuoteType } = this.props;
    const {
      profile: {
        privateData: { isPaypalOnBoardCompleted = false, merchantIdInPayPal },
      },
    } = currentUser?.attributes ?? { profile: { privateData: {} } };
    const stripeConnected =
      currentUser && currentUser.stripeAccount && !!currentUser.stripeAccount.id;

    const stripeAccountData = stripeConnected ? getStripeAccountData(stripeAccount) : null;

    const paypalPresent = isPaypalOnBoardCompleted && !!merchantIdInPayPal;
    if (!isQuoteType) {
      onPublishListingDraft(id);
    } else if (isQuoteType && paypalPresent) {
      onPublishListingDraft(id);
    } else {
      this.setState({
        draftId: id,
        showPayoutDetails: true,
      });
    }
  }

  handlePayoutModalClose() {
    this.setState({ showPayoutDetails: false });
  }

  render() {
    const {
      id,
      className,
      rootClassName,
      params,
      listing,
      viewport,
      intl,
      errors,
      fetchInProgress,
      payoutDetailsSaveInProgress,
      payoutDetailsSaved,
      onManageDisableScrolling,
      onPayoutDetailsChange,
      onGetStripeConnectAccountLink,
      getAccountLinkInProgress,
      createStripeAccountError,
      updateStripeAccountError,
      fetchStripeAccountError,
      stripeAccountFetched,
      stripeAccount,
      stripeAccountError,
      stripeAccountLinkError,
      currentUser,
      isQuoteType,
      history,
      ...rest
    } = this.props;
    let TABS = isQuoteType
      ? [
          DETAILS,
          LISTING_DETAILS,
          BUSINESS_DETAILS,
          CLIENT,
          // PRICING_FINANCE,
          PHOTO_DOCUMENTS,
          PREVIEW,
        ]
      : [
          DETAILS,
          LISTING_DETAILS,
          BUSINESS_DETAILS,
          // PRICING_FINANCE,
          PHOTO_DOCUMENTS,
          PACKAGE,
          PREVIEW,
        ];

    const tabsActive = (isNew, listing) => {
      return TABS.reduce((acc, tab) => {
        const previousTabIndex = TABS.findIndex((t) => t === tab) - 1;
        const isActive =
          previousTabIndex >= 0
            ? !isNew || tabCompleted(TABS[previousTabIndex], listing, isQuoteType)
            : true;
        return { ...acc, [tab]: isActive };
      }, {});
    };

    const selectedTab = params.tab;
    const isNewListingFlow = [LISTING_PAGE_PARAM_TYPE_NEW, LISTING_PAGE_PARAM_TYPE_DRAFT].includes(
      params.type
    );
    // console.log(selectedTab, isNewListingFlow, 787);
    const rootClasses = rootClassName || css.root;
    const classes = classNames(rootClasses, className);
    const currentListing = ensureListing(listing);
    const tabsStatus = tabsActive(isNewListingFlow, currentListing);

    // If selectedTab is not active, redirect to the beginning of wizard
    if (!tabsStatus[selectedTab]) {
      const currentTabIndex = TABS.indexOf(selectedTab);
      const nearestActiveTab = TABS.slice(0, currentTabIndex)
        .reverse()
        .find((t) => tabsStatus[t]);

      return <NamedRedirect name="EditListingPage" params={{ ...params, tab: nearestActiveTab }} />;
    }

    const { width } = viewport;
    const hasViewport = width > 0;
    const hasHorizontalTabLayout = hasViewport && width <= MAX_HORIZONTAL_NAV_SCREEN_WIDTH;
    const hasVerticalTabLayout = hasViewport && width > MAX_HORIZONTAL_NAV_SCREEN_WIDTH;
    const hasFontsLoaded =
      hasViewport && document.documentElement.classList.contains('fontsLoaded');

    // Check if scrollToTab call is needed (tab is not visible on mobile)
    if (hasVerticalTabLayout) {
      this.hasScrolledToTab = true;
    } else if (hasHorizontalTabLayout && !this.hasScrolledToTab && hasFontsLoaded) {
      const tabPrefix = id;
      scrollToTab(tabPrefix, selectedTab);
      this.hasScrolledToTab = true;
    }

    const tabLink = (tab) => {
      if (tab === 'details' && isQuoteType && isNewListingFlow) {
        return { name: 'EditListingQuotePage', params: { ...params, tab } };
      } else {
        return { name: 'EditListingPage', params: { ...params, tab } };
      }
    };

    const formDisabled = getAccountLinkInProgress;
    const ensuredCurrentUser = ensureCurrentUser(currentUser);
    const currentUserLoaded = !!ensuredCurrentUser.id;
    const stripeConnected = currentUserLoaded && !!stripeAccount && !!stripeAccount.id;

    const rootURL = config.canonicalRootURL;
    const routes = routeConfiguration();
    const { returnURLType, ...pathParams } = params;
    const successURL = createReturnURL(
      STRIPE_ONBOARDING_RETURN_URL_SUCCESS,
      rootURL,
      routes,
      pathParams
    );
    const failureURL = createReturnURL(
      STRIPE_ONBOARDING_RETURN_URL_FAILURE,
      rootURL,
      routes,
      pathParams
    );

    const accountId = stripeConnected ? stripeAccount.id : null;
    const stripeAccountData = stripeConnected ? getStripeAccountData(stripeAccount) : null;

    const requirementsMissing =
      stripeAccount &&
      (hasRequirements(stripeAccountData, 'past_due') ||
        hasRequirements(stripeAccountData, 'currently_due'));

    const savedCountry = stripeAccountData ? stripeAccountData.country : null;

    const handleGetStripeConnectAccountLink = handleGetStripeConnectAccountLinkFn(
      onGetStripeConnectAccountLink,
      {
        accountId,
        successURL,
        failureURL,
      }
    );

    const returnedNormallyFromStripe = returnURLType === STRIPE_ONBOARDING_RETURN_URL_SUCCESS;
    const returnedAbnormallyFromStripe = returnURLType === STRIPE_ONBOARDING_RETURN_URL_FAILURE;
    const showVerificationNeeded = stripeConnected && requirementsMissing;

    // Redirect from success URL to basic path for StripePayoutPage
    // if (returnedNormallyFromStripe && stripeConnected && !requirementsMissing) {
    //   return <NamedRedirect name="EditListingPage" params={pathParams} />;
    // }
    const backButton = (tab) => {
      if (Object.keys(params).length > 0) {
        const newParams = { ...params, tab: tab };
        history.replace(
          createResourceLocatorString('EditListingPage', routeConfiguration(), newParams)
        );
      }
    };
    // console.log(147, listing, selectedTab, isQuoteType, isNewListingFlow);

    return (
      <div className={classes}>
        {selectedTab !== PREVIEW ? (
          <div className={css.bckhead}>
            <button
              className={css.bckbtn}
              type="button"
              onClick={() => {
                // const toTab =
                //   selectedTab === LISTING_DETAILS
                //     ? DETAILS
                //     : selectedTab === BUSINESS_DETAILS
                //     ? LISTING_DETAILS
                //     : selectedTab === PRICING_FINANCE
                //     ? BUSINESS_DETAILS
                //     : selectedTab === PHOTO_DOCUMENTS
                //     ? PRICING_FINANCE
                //     : selectedTab === PACKAGE
                //     ? PHOTO_DOCUMENTS
                //     : selectedTab === PREVIEW
                //     ? PACKAGE
                //     : selectedTab === CLIENT
                //     ? BUSINESS_DETAILS
                //     : null;
                const toTab = isQuoteType
                  ? selectedTab === LISTING_DETAILS
                    ? DETAILS
                    : selectedTab === BUSINESS_DETAILS
                    ? LISTING_DETAILS
                    : selectedTab === CLIENT
                    ? BUSINESS_DETAILS
                    : selectedTab === PHOTO_DOCUMENTS
                    ? CLIENT
                    : selectedTab === PREVIEW
                    ? PHOTO_DOCUMENTS
                    : null
                  : selectedTab === LISTING_DETAILS
                  ? DETAILS
                  : selectedTab === BUSINESS_DETAILS
                  ? LISTING_DETAILS
                  : selectedTab === PHOTO_DOCUMENTS
                  ? BUSINESS_DETAILS
                  : selectedTab === PACKAGE
                  ? PHOTO_DOCUMENTS
                  : selectedTab === PREVIEW
                  ? PACKAGE
                  : null;
                // console.log(321, listing);

                if (
                  isNewListingFlow &&
                  listing?.attributes?.state === 'draft' &&
                  selectedTab === DETAILS
                ) {
                  // console.log(1);
                  history.push(
                    createResourceLocatorString('ManageListingsPage', routeConfiguration(), {}, {})
                  );
                } else if (isNewListingFlow && selectedTab === DETAILS) {
                  // console.log(2);
                  history.goBack();
                } else if (
                  !isNewListingFlow &&
                  listing?.attributes?.state === 'published' &&
                  selectedTab === DETAILS
                ) {
                  // console.log(3);
                  const listingSlug = listing ? createSlug(listing?.attributes?.title) : null;

                  history.replace(
                    createResourceLocatorString(
                      'ListingPage',
                      routeConfiguration(),
                      {
                        id: listing?.id?.uuid,
                        slug: listingSlug,
                      },
                      {}
                    )
                  );
                } else {
                  // console.log(3);

                  backButton(toTab);
                }
              }}
            >
              <span>
                <BiArrowBack />{' '}
              </span>
              Back
            </button>
            <span className={css.heading}>Sell Your Business / Investment Opportunity</span>
          </div>
        ) : null}

        <Tabs
          rootClassName={css.tabsContainer}
          navRootClassName={classNames(css.nav, { [css.hideNav]: selectedTab === 'preview' })}
          tabRootClassName={css.tab}
        >
          {TABS.map((tab) => {
            return (
              <EditListingWizardTab
                {...rest}
                width={width}
                key={tab}
                tabId={`${id}_${tab}`}
                tabLabel={tabLabel(intl, tab)}
                tabLinkProps={tabLink(tab)}
                selected={selectedTab === tab}
                disabled={isNewListingFlow && !tabsStatus[tab]}
                tab={tab}
                intl={intl}
                params={params}
                listing={listing}
                marketplaceTabs={TABS}
                errors={errors}
                handleCreateFlowTabScrolling={this.handleCreateFlowTabScrolling}
                handlePublishListing={this.handlePublishListing}
                fetchInProgress={fetchInProgress}
                currentUser={currentUser}
                history={history}
                isQuoteType={isQuoteType}
                backButton={backButton}
                onManageDisableScrolling={onManageDisableScrolling}
              />
            );
          })}
        </Tabs>
        <Modal
          id="EditListingWizard.payoutModal"
          isOpen={this.state.showPayoutDetails}
          onClose={this.handlePayoutModalClose}
          onManageDisableScrolling={onManageDisableScrolling}
          usePortal
        >
          <div className={css.modalPayoutDetailsWrapper}>
            <h1 className={css.modalTitle}>
              <FormattedMessage id="EditListingWizard.payoutModalTitleOneMoreThing" />
              <br />
              <FormattedMessage id="EditListingWizard.payoutModalTitlePayoutPreferences" />
            </h1>
            {!currentUserLoaded ? (
              <FormattedMessage id="StripePayoutPage.loadingData" />
            ) : (
              <React.Fragment>
                <p className={css.modalMessage}>
                  <FormattedMessage id="EditListingWizard.payoutModalInfo" />
                </p>
                <NamedLink name="StripePayoutPage" className={css.modalBtn}>
                  <FormattedMessage id="EditListingWizard.payoutModalBtn" />
                </NamedLink>
              </React.Fragment>
            )}
          </div>
        </Modal>
      </div>
    );
  }
}

EditListingWizard.defaultProps = {
  className: null,
  currentUser: null,
  rootClassName: null,
  listing: null,
  stripeAccount: null,
  stripeAccountFetched: null,
  updateInProgress: false,
  createStripeAccountError: null,
  updateStripeAccountError: null,
  fetchStripeAccountError: null,
  stripeAccountError: null,
  stripeAccountLinkError: null,
};

EditListingWizard.propTypes = {
  id: string.isRequired,
  className: string,
  currentUser: propTypes.currentUser,
  rootClassName: string,
  params: shape({
    id: string.isRequired,
    slug: string.isRequired,
    type: oneOf(LISTING_PAGE_PARAM_TYPES).isRequired,
    // tab: oneOf(TABS).isRequired,
  }).isRequired,
  stripeAccount: object,
  stripeAccountFetched: bool,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: shape({
    attributes: shape({
      publicData: object,
      description: string,
      geolocation: object,
      pricing: object,
      title: string,
    }),
    images: array,
  }),

  errors: shape({
    createListingDraftError: object,
    updateListingError: object,
    publishListingError: object,
    showListingsError: object,
    uploadImageError: object,
  }).isRequired,
  createStripeAccountError: propTypes.error,
  updateStripeAccountError: propTypes.error,
  fetchStripeAccountError: propTypes.error,
  stripeAccountError: propTypes.error,
  stripeAccountLinkError: propTypes.error,

  fetchInProgress: bool.isRequired,
  getAccountLinkInProgress: bool.isRequired,
  payoutDetailsSaveInProgress: bool.isRequired,
  payoutDetailsSaved: bool.isRequired,
  onPayoutDetailsChange: func.isRequired,
  onPayoutDetailsSubmit: func.isRequired,
  onGetStripeConnectAccountLink: func.isRequired,
  onManageDisableScrolling: func.isRequired,

  // from withViewport
  viewport: shape({
    width: number.isRequired,
    height: number.isRequired,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

export default compose(withViewport, injectIntl)(EditListingWizard);
