import classNames from 'classnames';
import React from 'react';
import config from '../../config';
import ListingImageGallery from './ListingImageGallery/ListingImageGallery';

import css from './ListingPage.module.css';

const SectionGallery = (props) => {
  const { listing, className, fromPreview, imgClassName, wrapperClassName, thumbnailPosition } =
    props;
  const classes = classNames(className, imgClassName);
  const images = listing.images;
  const { variantPrefix } = config.listing;
  const imageVariants = ['scaled-small', 'scaled-medium', 'scaled-large', 'scaled-xlarge'];
  const thumbnailVariants = [variantPrefix, `${variantPrefix}-2x`, `${variantPrefix}-4x`];

  return (
    <ListingImageGallery
      rootClassName={classes}
      images={images}
      imageVariants={imageVariants}
      thumbnailVariants={thumbnailVariants}
      imgClassName={imgClassName}
      // className={wrapperClassName}
      wrapperClassName={wrapperClassName}
      thumbnailPosition={thumbnailPosition}
    />
  );
};

export default SectionGallery;
