import React, { Component } from 'react';
import PropTypes, { any } from 'prop-types';
import classNames from 'classnames';

// Import configs and util modules
import config from '../../../../config';
import { FormattedMessage } from '../../../../util/reactIntl';
import { LISTING_STATE_DRAFT } from '../../../../util/types';
import { ensureOwnListing } from '../../../../util/data';
import { types as sdkTypes } from '../../../../util/sdkLoader';
const { func, object, string, bool } = PropTypes;

// Import shared components
import { ListingLink } from '../../../../components';

// Import modules from this directory
import EditListingListingDetailsForm from './EditListingListingDetailsForm';
import css from './EditListingListingDetailsPannel.module.css';

const { Money } = sdkTypes;

const defaultProps = {
  className: null,
  rootClassName: null,
  listing: null,
};
const propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};
type propType = typeof defaultProps | typeof PropTypes | any;
type ListingDetailsValuesType = {
  phoneNumber: string;
  email: string;
  price: any;
  lookingFor: { label: string; value: string };
  investmentSize: string;
  investmentOffer: string;
  investmentSizePerDeal: { label: string; value: string };
};

class EditListingListingDetailsPannel extends Component<propType, any> {
  static defaultProps: typeof defaultProps;
  static propTypes: typeof propTypes;
  constructor(props: any) {
    super(props);

    this.getInitialValues = this.getInitialValues.bind(this);

    this.state = {
      initialValues: this.getInitialValues(),
    };
  }

  getInitialValues() {
    const { listing, currentUser } = this.props;
    const { attributes } = currentUser;
    let values: Partial<ListingDetailsValuesType> = {};
    if (!!attributes.email && !!attributes?.profile?.protectedData?.phoneNumber) {
      values.phoneNumber = attributes.profile.protectedData.phoneNumber;
      values.email = attributes.email;
    }
    if (!!listing?.attributes?.publicData && !!listing?.attributes?.privateData) {
      const { lookingFor, investmentOffer, investmentSize, investmentSizePerDeal } =
        listing.attributes.publicData;
      const { email, phoneNumber } = listing.attributes.privateData;
      if (!!investmentSize) {
        values.investmentSize = (parseInt(investmentSize.amount) / 100).toString();
      }
      values.lookingFor = lookingFor;
      values.investmentSizePerDeal = investmentSizePerDeal;
      values.investmentOffer = investmentOffer;
      if (!!email) values.email = email;
      if (!!phoneNumber) values.phoneNumber = phoneNumber;
    }
    return values;
  }

  render() {
    const {
      className,
      rootClassName,
      listing,
      disabled,
      ready,
      onSubmit,
      onChange,
      submitButtonText,
      panelUpdated,
      updateInProgress,
      errors,
      currentUser,
    } = this.props;

    const classes = classNames(rootClassName || css.root, className);
    const currentListing = ensureOwnListing(listing);

    const isPublished =
      currentListing.id && currentListing.attributes.state! !== LISTING_STATE_DRAFT;
    const panelTitle = isPublished ? (
      <FormattedMessage
        id="EditListingListingDetailsPannel.title"
        values={{ listingTitle: <ListingLink listing={listing} /> }}
      />
    ) : (
      <FormattedMessage id="EditListingListingDetailsPannel.createListingTitle" />
    );

    return (
      <div className={classes}>
        <h1 className={css.title}>{panelTitle}</h1>
        <EditListingListingDetailsForm
          className={css.form}
          initialValues={this.state.initialValues}
          onSubmit={(values: ListingDetailsValuesType) => {
            const {
              email,
              phoneNumber,
              lookingFor,
              investmentSize,
              investmentOffer,
              investmentSizePerDeal,
            } = values;
            const { attributes } = currentUser;
            const userInfo: Partial<{ email: string; phoneNumber: string }> = {};
            if (!!attributes.email && !!attributes?.profile?.protectedData?.phoneNumber) {
              userInfo.phoneNumber = attributes.profile.protectedData.phoneNumber;
              userInfo.email = attributes.email;
            }

            this.setState({
              initialValues: {
                email,
                phoneNumber,
                lookingFor,
                investmentSize,
                investmentOffer,
                lookingForArr: [lookingFor.value],
                investmentSizePerDeal,
                investmentSizePerDealArr: [investmentSizePerDeal.value],
              },
            });
            const updateValues = {
              price: new Money(parseInt(investmentSize) * 100, config.currency),
              publicData: {
                investmentSizePerDeal,
                investmentSizePerDealArr: [investmentSizePerDeal.value],
                lookingFor,
                investmentOffer,
                investmentSize: {
                  amount: parseInt(investmentSize) * 100,
                  currency: config.currency,
                },
                lookingForArr: [lookingFor.value],
              },
              privateData: {
                email: !!userInfo.email && userInfo.email !== email ? userInfo.email : email,
                phoneNumber:
                  !!userInfo.phoneNumber && userInfo.phoneNumber !== phoneNumber
                    ? userInfo.phoneNumber
                    : phoneNumber,
              },
            };
            onSubmit(updateValues);
          }}
          onChange={onChange}
          saveActionMsg={submitButtonText}
          disabled={disabled}
          ready={ready}
          updated={panelUpdated}
          updateInProgress={updateInProgress}
          fetchErrors={errors}
          autoFocus
          currentUser={currentUser}
        />
      </div>
    );
  }
}

EditListingListingDetailsPannel.defaultProps = defaultProps;

EditListingListingDetailsPannel.propTypes = propTypes;

export default EditListingListingDetailsPannel;
