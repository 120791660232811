import React, { useEffect, useState } from 'react';
import { bool, func, object, string } from 'prop-types';
import classNames from 'classnames';

// Import configs and util modules
import { FormattedMessage } from '../../../../util/reactIntl';
import { ensureOwnListing } from '../../../../util/data';
import { LISTING_STATE_DRAFT } from '../../../../util/types';

// Import shared components
import { ListingLink } from '../../../../components';

// Import modules from this directory
import EditListingDetailsForm from './EditListingDetailsForm';
import { Country } from 'country-state-city';
import { types as sdktypes } from '../../../../../src/util/sdkLoader';
import css from './EditListingDetailsPanel.module.css';
import axios from 'axios';
import { apiBaseUrl } from '../../../../util/api';
const { LatLng } = sdktypes;
const EditListingDetailsPanel = (props) => {
  const {
    className,
    rootClassName,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
    currentUser,
    onManageDisableScrolling,
    isNewURI,
  } = props;
  const [verifiedDatas, setVerifiedDatas] = useState([]);

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const { description, title, publicData, privateData } = currentListing?.attributes;

  const { zip, lat, lng, city, address, country } = publicData;

  const { listingVerificableKeys = [] } = privateData;
  const isPublished =
    currentListing?.id && currentListing?.attributes?.state !== LISTING_STATE_DRAFT;
  const panelTitle = isPublished ? (
    <FormattedMessage
      id="EditListingDetailsPanel.title"
      values={{ listingTitle: <ListingLink listing={listing} /> }}
    />
  ) : (
    <FormattedMessage id="EditListingDetailsPanel.createListingTitle" />
  );
  // console.log(369, verifiedDatas);
  useEffect(() => {
    const verifiedDatasFromPD = currentListing?.attributes?.metadata?.verifiedDatas;
    if (verifiedDatasFromPD && verifiedDatasFromPD?.length > 0 && verifiedDatas?.length === 0) {
      setVerifiedDatas(verifiedDatasFromPD);
    }
  });
  return (
    <div className={classes}>
      <h1 className={css.title}>{panelTitle}</h1>
      <EditListingDetailsForm
        className={css.form}
        initialValues={{
          title,
          description,
          zip,
          lat,
          lng,
          city,
          address,
          country,
        }}
        saveActionMsg={submitButtonText}
        listing={listing}
        onSubmit={(values) => {
          const { title, description, zip, lat, lng, city, address, country } = values;
          // console.log(
          //   258,
          //   listingVerificableKeys,
          //   privateData.listingVerificableKeys.includes('location'),
          //   verifiedDatas.includes('location')
          // );
          const stocksMaybe = isNewURI ? { stockUpdate: { oldTotal: null, newTotal: 100 } } : {};
          const updateValues = {
            title: title.trim(),
            description,
            geolocation: !!lat && !!lng ? new LatLng(parseFloat(lat), parseFloat(lng)) : null,
            ...stocksMaybe,
            publicData: {
              zip,
              lat,
              lng,
              city,
              address,
              country,
            },
            privateData: {
              listingVerificableKeys: !privateData?.listingVerificableKeys?.includes('location')
                ? [...listingVerificableKeys, 'location']
                : [...listingVerificableKeys],
            },
          };

          onSubmit(updateValues);
          if (
            // zip !== publicData?.zip ||
            // lat !== publicData?.lat ||
            // lng !== publicData?.lng ||
            // city !== publicData?.city ||
            // address !== publicData?.address ||
            // country !== publicData?.country
            verifiedDatas.includes('location')
          ) {
            // console.log('verified data changed');
            const listingId = currentListing?.id?.uuid;
            // const data = verifiedDatas.includes('location')
            //   ? verifiedDatas.filter((f) => f !== 'location')
            //   : [...verifiedDatas, 'location'];
            const data = verifiedDatas.filter((f) => f !== 'location');

            axios
              .post(`${apiBaseUrl()}/api/toggleVerify`, { verifiedDatas: data, listingId })
              .then((res) => {
                const verifiedDatasFromPD =
                  res?.data?.data?.data?.attributes?.metadata?.verifiedDatas;
                // console.log(321, verifiedDatasFromPD);
                setVerifiedDatas(verifiedDatasFromPD);

                //---- to setVerificationMailSent to flase if the the listing was verified and now the data has been changed---//

                const hasListingVerificationMailSent =
                  res?.data?.data?.data?.attributes?.publicData?.hasListingVerificationMailSent;
                // console.log( 321,hasListingVerificationMailSent);
                if (hasListingVerificationMailSent) {
                  // console.log('verify mail set false');
                  axios.post(`${apiBaseUrl()}/api/setVerificationMailSent`, {
                    id: listingId,
                    hasListingVerificationMailSent: false,
                  });
                }
              })
              .catch((err) => {
                console.log(err);
              });
          }
        }}
        currentUser={currentUser}
        onChange={onChange}
        disabled={disabled}
        ready={ready}
        updated={panelUpdated}
        updateInProgress={updateInProgress}
        fetchErrors={errors}
        autoFocus
        onManageDisableScrolling={onManageDisableScrolling}
      />
    </div>
  );
};

EditListingDetailsPanel.defaultProps = {
  className: null,
  rootClassName: null,
  errors: null,
  listing: null,
};

EditListingDetailsPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingDetailsPanel;
