import React, { useEffect, useRef, useState } from 'react';
import { bool, func, object, string } from 'prop-types';
import classNames from 'classnames';

// Import configs and util modules
import { FormattedMessage } from '../../../../util/reactIntl';
import { ensureOwnListing } from '../../../../util/data';
import { LISTING_STATE_DRAFT } from '../../../../util/types';

// Import shared components
import { ListingLink } from '../../../../components';

// Import modules from this directory
import EditListingQuoteDetailsForm from './EditListingQuoteDetailsForm';
import { Country } from 'country-state-city';
import { types as sdktypes, createInstance } from '../../../../../src/util/sdkLoader';
import css from './EditListingQuoteDetailsPanel.module.css';
import config from '../../../../config';
const { LatLng, UUID } = sdktypes;
const EditListingQuoteDetailsPanel = (props) => {
  const {
    className,
    rootClassName,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
    currentUser,
    onManageDisableScrolling,
    isQuoteType,
    params,
    isNewURI,
  } = props;
  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const [parentListings, setParentListings] = useState(null);

  const { description, title, publicData } = currentListing.attributes;
  const { zip, lat, lng, city, address, country } = publicData;
  const sdkInstance = useRef(createInstance({ clientId: config.sdk.clientId }));

  useEffect(() => {
    if (
      !!currentListing &&
      !!props.params.parentListingId
      // !currentListing.attributes.publicData.isParentImgUploaded
    ) {
      // setIsImageLoadReqested(true);
      sdkInstance.current.listings
        .query({
          ids: [new UUID(props.params.parentListingId)],
          // include: ['curre'],
        })
        .then((res) => {
          setParentListings(res.data.data[0]);
        })
        .catch((err) => {
          console.log(err);
          setParentListings(null);
        });
    }
  }, [props.params.parentListingId]);
  const clientId = params.email || null;
  const isQuoteTitle = parentListings?.attributes?.title;
  const isQuoteDescription = parentListings?.attributes?.description;

  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const panelTitle = isPublished ? (
    <FormattedMessage
      id="EditListingQuoteDetailsPanel.title"
      values={{ listingTitle: <ListingLink listing={listing} /> }}
    />
  ) : (
    <FormattedMessage id="EditListingQuoteDetailsPanel.createListingTitle" />
  );

  return (
    <div className={classes}>
      <h1 className={css.title}>{panelTitle}</h1>
      <EditListingQuoteDetailsForm
        className={css.form}
        initialValues={{
          title: isQuoteTitle ? isQuoteTitle : title,
          description: isQuoteDescription ? isQuoteDescription : description,
          zip,
          lat,
          lng,
          city,
          address,
          country,
        }}
        saveActionMsg={submitButtonText}
        listing={listing}
        onSubmit={(values) => {
          const { title, description, zip, lat, lng, city, address, country } = values;
          const stocksMaybe = isNewURI ? { stockUpdate: { oldTotal: null, newTotal: 10 } } : {};
          const updateValues = {
            title: isQuoteTitle ? isQuoteTitle : title.trim(),
            ...stocksMaybe,
            description: isQuoteDescription ? isQuoteDescription : description,
            geolocation: !!lat && !!lng ? new LatLng(parseFloat(lat), parseFloat(lng)) : null,
            publicData: {
              isQuoteType,
              clientId,
              isSelected: false,
              parentListingId: params.parentListingId,
              zip,
              lat,
              lng,
              city,
              address,
              country,
            },
          };

          onSubmit(updateValues);
        }}
        currentUser={currentUser}
        onChange={onChange}
        disabled={disabled}
        ready={ready}
        updated={panelUpdated}
        updateInProgress={updateInProgress}
        fetchErrors={errors}
        autoFocus
        onManageDisableScrolling={onManageDisableScrolling}
      />
    </div>
  );
};

EditListingQuoteDetailsPanel.defaultProps = {
  className: null,
  rootClassName: null,
  errors: null,
  listing: null,
};

EditListingQuoteDetailsPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingQuoteDetailsPanel;
